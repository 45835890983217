<template>
  <div class="row">
    <div class="col-12 vd-text-align-left">
      <div
        :class="[
          'vd-field vd-margin-bottom-20',
          numberOfCameras !== '' ? 'vd-input-valid' : '',
        ]"
      >
        <label>Number of cameras</label>
        <model-list-select
          v-model="numberOfCameras"
          :list="numberOfCamerasList"
          option-value="id"
          option-text="name"
          placeholder="Select the number of cameras used"
        ></model-list-select>
      </div>
      <div
        v-for="(number, index) in numberOfCameras"
        :key="index"
        :class="[
          'vd-field vd-margin-bottom-20',
          cameraVideoClipsArray[index] ? 'vd-input-valid' : '',
        ]"
      >
        <label class="vd-black"
          >Camera {{ index + 1 }}: number of video clips</label
        >
        <input
          v-model="cameraVideoClipsArray[index]"
          class="vd-margin-bottom-20 vd-border-light-grey"
          type="text"
          name="cameraVideoClipsArray[]"
          placeholder="Enter how many clips were captured"
        />
      </div>
      <div
        :class="[
          'vd-field vd-margin-bottom-20',
          audioSource !== '' ? 'vd-input-valid' : '',
        ]"
      >
        <label>Audio source</label>
        <model-list-select
          v-model="audioSource"
          :list="audioSourceList"
          option-value="id"
          option-text="name"
          placeholder="Select what device recorded the audio"
        ></model-list-select>
      </div>
      <div class="vd-margin-bottom-20">
        <input-component
          v-model="numberOfAudioFiles"
          type="text"
          name="numberOfAudioFiles"
          label="Number of audio files"
          placeholder="Enter how many audio files were captured"
          :container-custom-classes="[
            setInputValidationClass('numberOfAudioFiles'),
          ]"
          @formInput="handleInputUpdate($event, 'numberOfAudioFiles')"
        ></input-component>
      </div>
      <div
        :class="[
          'vd-field vd-margin-bottom-20',
          colorProfile !== '' ? 'vd-input-valid' : '',
        ]"
      >
        <label>Color profile</label>
        <model-list-select
          v-model="colorProfile"
          :list="colorProfileList"
          option-value="id"
          option-text="name"
          placeholder="Select the suitable color profile"
        ></model-list-select>
      </div>
      <div
        :class="[
          'vd-field vd-margin-bottom-20',
          isFootageForStock !== '' ? 'vd-input-valid' : '',
        ]"
      >
        <label>Footage suitable for stock?</label>
        <model-list-select
          v-model="isFootageForStock"
          :list="footageStockList"
          option-value="id"
          option-text="name"
          placeholder="Is any footage useful in another edit?"
        ></model-list-select>
      </div>

      <div class="vd-text-align-right vd-margin-bottom-20">
        <button
          :disabled="eodNotesSaveBtnLoading"
          class="vd-btn-small vd-btn-blue"
          @click.prevent="handleSaveFilmingForm"
        >
          {{ eodNotesSaveBtnLoading ? 'Saving...' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ModelListSelect } from 'vue-search-select'
import { minLength, integer } from 'vuelidate/lib/validators'
import { isEmpty, cloneDeep, size, slice } from 'lodash'
import InputComponent from '@components/form/form-input'
import FormMixin from '@mixins/forms-mixin'

export default {
  components: {
    ModelListSelect,
    InputComponent,
  },

  mixins: [FormMixin],

  props: {
    projectId: {
      type: Number,
      required: true,
      default: 0,
    },

    jobId: {
      type: Number,
      required: true,
      default: 0,
    },

    additionalData: {
      type: [Array, Object],
      required: true,
      default: () => {
        return {}
      },
    },

    colorProfileList: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },

    footageStockList: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },
  },

  data() {
    return {
      numberOfCameras: '',
      audioSource: '',
      numberOfAudioFiles: '',
      colorProfile: '',
      cameraVideoClipsArray: [],
      isFootageForStock: '',
    }
  },

  computed: {
    ...mapGetters({
      eodNotesSaveBtnLoading: 'project/eodNotesSaveBtnLoading',
    }),

    numberOfCamerasList() {
      return [
        { id: 0, name: 0 },
        { id: 1, name: 1 },
        { id: 2, name: 2 },
        { id: 3, name: 3 },
        { id: 4, name: 4 },
        { id: 5, name: 5 },
      ]
    },

    audioSourceList() {
      return [
        { id: 'camera_on_board', name: 'Camera on board' },
        { id: 'external', name: 'External recording device (Zoom)' },
        { id: 'other', name: 'Other' },
      ]
    },
  },

  validations: {
    numberOfCameras: {
      integer,
    },

    audioSource: {
      minLength: minLength(0),
    },

    numberOfAudioFiles: {
      minLength: minLength(0),
    },
  },

  watch: {
    numberOfCameras(val) {
      let currentSize = size(this.cameraVideoClipsArray)
      let currentSizeIndex = size(this.cameraVideoClipsArray) - 1
      let newSizeIndex = val - 1

      if (val === currentSize) {
        return
      } else {
        if (val === 0) {
          this.cameraVideoClipsArray = []
        } else if (val < currentSize) {
          this.cameraVideoClipsArray = slice(
            this.cameraVideoClipsArray,
            newSizeIndex,
            currentSizeIndex
          )
        }
      }
    },
  },

  mounted() {
    if (!isEmpty(this.additionalData)) {
      this.numberOfCameras = cloneDeep(this.additionalData.number_of_cameras)
      this.audioSource = cloneDeep(this.additionalData.audio_source)
      this.numberOfAudioFiles = cloneDeep(
        this.additionalData.number_of_audio_files
      )
      this.colorProfile = cloneDeep(this.additionalData.color_profile)

      if (this.additionalData.camera_number_of_video_clips) {
        this.cameraVideoClipsArray = cloneDeep(
          this.additionalData.camera_number_of_video_clips
        )
      }
      this.isFootageForStock = cloneDeep(
        this.additionalData.is_footage_for_stock
      )
    }
  },

  methods: {
    ...mapActions({
      updateJobAdditionalData: 'project/updateJobAdditionalData',
    }),

    handleSaveFilmingForm() {
      let additionalData = {}

      if (size(this.cameraVideoClipsArray) > 0) {
        additionalData = {
          number_of_cameras: this.numberOfCameras,
          audio_source: this.audioSource,
          number_of_audio_files: this.numberOfAudioFiles,
          color_profile: this.colorProfile,
          camera_number_of_video_clips: this.cameraVideoClipsArray,
          is_footage_for_stock: this.isFootageForStock,
        }
      } else {
        additionalData = {
          number_of_cameras: this.numberOfCameras,
          audio_source: this.audioSource,
          number_of_audio_files: this.numberOfAudioFiles,
          color_profile: this.colorProfile,
          is_footage_for_stock: this.isFootageForStock,
        }
      }

      let payload = {
        projectId: this.projectId,
        jobId: this.jobId,
        additional_data: additionalData,
        include: ['created_by'],
      }

      this.updateJobAdditionalData(payload)
    },
  },
}
</script>
