<template>
  <div class="row no-gutters vd-full-height">
    <div class="row no-gutters">
      <div class="vms-calendar__appointments-title">
        <div>
          <strong>
            <span class="vd-margin-right-extra-small">{{ eventName }}</span>
            <span v-if="isEventOnHold">(On Hold)</span>
          </strong>
        </div>
        <div v-if="!isExternalEvent">
          <span v-if="eventData.state">{{ eventData.state.name }} - </span>
          <span v-if="eventData.event_type">{{
            eventData.event_type.name
          }}</span>
          <span v-if="isPlaceholderEventType"> (Placeholder)</span>
        </div>
        <div v-if="!eventData.isHoliday">
          {{ eventData.StartTime | momentFormat('hh:mm A') }} -
          {{ eventData.EndTime | momentFormat('hh:mm A') }}
        </div>
      </div>
      <div class="vms-calendar__appointments-type vd-text-align-right">
        <div
          :class="[
            'vms-calendar__event-type vd-align-middle',
            isGoogleCalendarEventType ? 'vd-icon-google-calendar' : '',
            isBambooHrEventType ? 'vd-icon-bamboo-hr' : '',
          ]"
        ></div>
      </div>
    </div>

    <div class="vd-full-width vd-margin-top-small">
      <div class="row no-gutters">
        <div
          v-if="eventData.user"
          class="vms-calendar__appointments-avatar--week-view"
        >
          <user-profile
            :user="eventData.user"
            :custom-avatar-font-size="15"
            class="vms-assigned-crew__profile"
          ></user-profile>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from '@mixins/filters-mixin'
import UserProfile from '@components/user-profile/user-profile'

export default {
  components: {
    UserProfile,
  },

  mixins: [FiltersMixin],

  props: {
    eventData: {
      type: Object,
      required: true,
    },

    isGoogleCalendarEventType: {
      type: Boolean,
      required: false,
    },

    isBambooHrEventType: {
      type: Boolean,
      required: false,
    },

    isExternalEvent: {
      type: Boolean,
      required: false,
      default: false,
    },

    isPlaceholderEventType: {
      type: Boolean,
      required: false,
      default: false,
    },

    eventName: {
      type: String,
      required: true,
    },

    isEventOnHold: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
