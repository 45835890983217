<template>
  <div
    v-if="canShowUndoBtn"
    class="vd-padding-block-extra-small vd-padding-inline-small vd-border-radius-4 vd-border-width-1 vd-background-white vd-border-grey vd-display-flex vd-cursor-pointer vd-hover-background-lighter-grey"
    @click="handleUndo"
  >
    <i
      class="vd-icon icon-rotate-left vd-hover-darker-grey vd-text-small vd-align-middle"
    ></i>
    <div class="vd-margin-left-small">Undo</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isEmpty, forEach } from 'lodash'
import ApiCustomIncludes from '@configs/api-custom-includes'
import momentTz from 'moment-timezone'
import AppConfig from '@configs/app-config'

export default {
  computed: {
    ...mapGetters({
      eventActionHistory: 'calendar/eventActionHistory',
    }),

    canShowUndoBtn() {
      return !isEmpty(this.eventActionHistory)
    },
  },

  methods: {
    ...mapActions({
      updateEventDetails: 'calendar/updateEventDetails',
      setEventActionHistory: 'calendar/setEventActionHistory',
      bulkRestoreDeletedEvent: 'calendar/bulkRestoreDeletedEvent',
      bulkDeleteEvents: 'calendar/bulkDeleteEvents',
      bulkUpdateEvents: 'calendar/bulkUpdateEvents',
    }),

    handleUndo() {
      switch (this.eventActionHistory.type) {
        case 'edit':
          momentTz.tz.setDefault(AppConfig.defaultTimezone)

          this.updateEventDetails({
            includes: ApiCustomIncludes.eventsList,
            eventId: this.eventActionHistory.originalPayload.id,
            payload: {
              user_id: this.eventActionHistory.originalPayload.user_id,
              freelancer: this.eventActionHistory.originalPayload.freelancer,
              from: momentTz
                .unix(this.eventActionHistory.originalPayload.from)
                .format('YYYY-MM-DD HH:mm:ss'),
              to: momentTz
                .unix(this.eventActionHistory.originalPayload.to)
                .format('YYYY-MM-DD HH:mm:ss'),
              name: this.eventActionHistory.originalPayload.name,
              is_all_day: this.eventActionHistory.originalPayload.is_all_day,
            },
          })
          break
        case 'delete':
          momentTz.tz.setDefault(AppConfig.defaultTimezone)

          this.bulkRestoreDeletedEvent({
            eventStartDate: momentTz
              .unix(this.eventActionHistory.originalPayload.from)
              .format('D MMMM YYYY'),
            payload: {
              event_ids: [this.eventActionHistory.originalPayload.id],
            },
            getEventsList: true,
          })
          break
        case 'duplicate':
          this.bulkDeleteEvents({
            payload: {
              event_ids: this.eventActionHistory.originalPayload.event_ids,
            },
          })
          break
        case 'bulkEdit': {
          const eventsPayload = []
          momentTz.tz.setDefault(AppConfig.defaultTimezone)

          forEach(this.eventActionHistory.originalPayload, (payload) => {
            eventsPayload.push({
              id: payload.id,
              from: momentTz.unix(payload.from).format('YYYY-MM-DD HH:mm:ss'),
              to: momentTz.unix(payload.to).format('YYYY-MM-DD HH:mm:ss'),
              user_id: payload.user_id,
              freelancer: payload.meta_data
                ? payload.meta_data.freelancer
                : null,
              name: payload.name,
              is_all_day: payload.is_all_day,
            })
          })

          this.bulkUpdateEvents({
            payload: {
              events: eventsPayload,
            },
          })
          break
        }
        case 'bulkDelete':
          this.bulkRestoreDeletedEvent({
            payload: {
              event_ids: this.eventActionHistory.originalPayload.event_ids,
            },
            getEventsList: true,
            isBulkDelete: true,
          })
          break
        default:
          break
      }

      this.setEventActionHistory(null)
    },
  },
}
</script>
