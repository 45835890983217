<template>
  <div class="vd-display-flex vd-align-center">
    <div v-if="isMobileScreen" class="vd-margin-right-small">
      <i
        id="showAgendaBtn"
        class="vd-icon icon-calendar-days vd-dark-grey vd-border-width-1 vd-border-radius-6 vd-border-grey vd-icon-circle vd-cursor-pointer vd-hover-background-lighter-grey"
        @click="handleShowAgenda"
      ></i>
    </div>
    <div v-if="isMobileScreen" class="vd-margin-right-small">
      <i
        id="showFilterBtn"
        :class="[
          'vd-icon vd-dark-grey vd-border-width-1 vd-border-radius-6 vd-border-grey vd-icon-circle vd-cursor-pointer vd-hover-background-lighter-grey',
          showMobileFilterList ? 'icon-xmark' : 'icon-bars-filter',
        ]"
        @click="handleShowFilter"
      ></i>
    </div>
    <search-component
      class="vd-full-width"
      :search-text="searchKeyword"
      @input:search="searchKeyword = $event"
      @enter:search="handleSearchEvents"
    ></search-component>

    <div class="vd-margin-left-small vd-position-relative">
      <i
        id="addEventBtn"
        class="vd-icon icon-plus vd-icon-circle vd-background-light-blue vd-white vd-cursor-pointer"
        @click="handleAddEventClick"
      ></i>
      <events-context-menu ref="calendarContextMenu"></events-context-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import EventsContextMenu from '@components/events/events-context-menu'
import SearchComponent from '@components/search/search'

export default {
  components: {
    EventsContextMenu,
    SearchComponent,
  },

  data() {
    return {
      searchKeyword: '',
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      hasEventsSearchKeyWord: 'calendar/hasEventsSearchKeyWord',
      eventsListPayload: 'calendar/eventsListPayload',
      selectedViewType: 'calendar/selectedViewType',
      selectedViewTypeDetails: 'calendar/selectedViewTypeDetails',
      showMobileFilterList: 'calendar/showMobileFilterList',
      searchEventsFilterPayload: 'calendar/searchEventsFilterPayload',
    }),
  },

  watch: {
    hasEventsSearchKeyWord() {
      if (!this.hasEventsSearchKeyWord) {
        this.searchKeyword = ''
      }
    },
  },

  methods: {
    ...mapActions({
      getEventsList: 'calendar/getEventsList',
      setEventsSearchKeyword: 'calendar/setEventsSearchKeyword',
      setViewType: 'calendar/setViewType',
      setShowMobileFilterList: 'calendar/setShowMobileFilterList',
      setShowMobileAgendaModal: 'calendar/setShowMobileAgendaModal',
    }),

    handleAddEventClick(args) {
      // Open the context menu component
      const top = args.clientY
      const left = args.clientX

      this.$refs.calendarContextMenu.$refs.menuObj.open(top, left)
    },

    handleSearchEvents() {
      this.setEventsSearchKeyword(this.searchKeyword)

      let startDate = this.eventsListPayload.start
      let endDate = this.eventsListPayload.end

      let eventListPayloadFilters = this.eventsListPayload.filters

      // On all search result view, by default it will override existing filters that is applied.
      // This is on purpose. It is to show all events related to the search keyword.
      if (this.selectedViewType === 'Agenda') {
        eventListPayloadFilters = this.searchEventsFilterPayload
      }

      this.getEventsList({
        payload: {
          start: startDate,
          end: endDate,
          filters: eventListPayloadFilters,
          include: this.eventsListPayload.include,
        },
        showLoader: false,
      })
    },

    handleShowFilter() {
      this.setShowMobileFilterList(!this.showMobileFilterList)
    },

    handleShowAgenda() {
      this.setShowMobileAgendaModal(true)
    },
  },
}
</script>
