<template>
  <div>
    <ejs-calendar
      :show-today-button="false"
      :value="defaultCalendarDate"
      @change="onCalendarUpdate"
    ></ejs-calendar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import momentTz from 'moment-timezone'

export default {
  computed: {
    ...mapGetters({
      selectedCalendarDate: 'calendar/selectedCalendarDate',
      schedulerSavedState: 'calendar/schedulerSavedState',
    }),

    defaultCalendarDate() {
      let defaultCalendarDate = null

      if (!this.selectedCalendarDate) {
        // If scheduler saved state is available and no selected calendar date, use the selected date from the saved state
        if (this.schedulerSavedState) {
          defaultCalendarDate = momentTz(this.schedulerSavedState.selectedDate)
        }
      } else {
        defaultCalendarDate = momentTz(this.selectedCalendarDate)
      }

      return defaultCalendarDate
        ? momentTz(defaultCalendarDate).format('YYYY-MM-DD')
        : null
    },
  },

  methods: {
    ...mapActions({
      setSelectedCalendarDate: 'calendar/setSelectedCalendarDate',
    }),

    onCalendarUpdate(args) {
      this.setSelectedCalendarDate(args.value)
    },
  },
}
</script>
