<template>
  <div class="vd-padding-medium">
    <div v-if="isLoading" class="vd-field">
      <label>Event type</label>
      <input placeholder="Loading ..." disabled />
    </div>

    <v-select
      v-else
      :disabled="disabled"
      :value="eventType"
      :items="eventTypesList"
      :hide-selected="true"
      :menu-props="menuProps"
      :class="['vd-field vd-required', eventType ? 'vd-input-valid' : '']"
      label="Event type"
      item-text="name"
      item-value="id"
      no-data-text="No results to display"
      placeholder="Select event type"
      persistent-placeholder
      clearable
      @change="handleEventTypeChange"
    >
    </v-select>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    eventTypesList: {
      type: Array,
      required: true,
      default: () => [],
    },

    eventType: {
      type: Number,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      menuProps: {
        closeOnClick: true,
        offsetY: true,
      },
    }
  },

  methods: {
    handleEventTypeChange(eventType) {
      this.$emit('update:eventType', eventType)
    },
  },
}
</script>
