<template>
  <div class="vms-dropdown__list vd-black vd-padding-small vd-display-flex">
    <input
      :id="filterIdentifier"
      type="checkbox"
      class="vd-margin-right-small"
      :name="filterIdentifier"
      :value="value"
      :checked="isInputSelected ? 'checked' : ''"
      @change="handleFilterChecked"
    />

    <label :for="filterIdentifier" class="vd-margin-0">
      <span class="vd-margin-right-small">{{ label }}</span>
    </label>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { kebabCase, some } from 'lodash'

export default {
  props: {
    label: {
      type: String,
      required: true,
    },

    value: {
      type: String,
      required: true,
    },

    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isInputSelected: false,
    }
  },

  computed: {
    ...mapGetters({
      selectedFilters: 'calendar/selectedFilters',
    }),

    filterIdentifier() {
      return `${kebabCase(this.label)}`
    },

    selectedFilterContent() {
      return {
        id: this.id,
        label: this.label,
        value: this.value,
      }
    },
  },

  watch: {
    selectedFilters() {
      this.setIsInputSelected()
    },
  },

  mounted() {
    this.setIsInputSelected()
  },

  methods: {
    handleFilterChecked() {
      this.$emit('handle:filter-checked', this.selectedFilterContent)
    },

    setIsInputSelected() {
      this.isInputSelected = some(
        this.selectedFilters,
        this.selectedFilterContent
      )
    },
  },
}
</script>
