<template>
  <div>
    <div class="vd-text-small">Attachments</div>
    <div
      v-if="largeFileWarning"
      class="vd-text-small vd-margin-bottom-10 vd-padding-inline-medium vd-padding-block-small vd-display-block vd-border-radius-6 vd-background-light-yellow vd-red"
    >
      Please don't close this popup, large file may take a little longer to
      process.
    </div>

    <div
      class="vd-border-lighter-grey vd-border-radius-6 vd-padding-10 vd-grey vd-text-align-center vd-margin-bottom-20"
    >
      <div
        class="vms-uploader__wrapper vd-border-lighter-grey vd-border-radius-6 vd-padding-top-10 vd-padding-bottom-10"
      >
        <file-upload-component
          :api-url="apiUrl"
          :label-idle="defaultLabel"
          @handle:process-file="handleProcessFile"
          @handle:add-file="handleAddFile"
          @handle:update-files="handleUpdateFiles"
        ></file-upload-component>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isEmpty, isNull } from 'lodash'
import AppConfig from '@configs/app-config'
import FileUploadComponent from '@components/file-upload/file-upload'

export default {
  components: {
    FileUploadComponent,
  },

  mixins: [],

  props: {
    projectId: {
      type: Number,
      required: true,
      default: 0,
    },

    selectedEodNoteFilterId: {
      type: Number,
      required: true,
      default: 0,
    },
  },

  data() {
    return {
      largeFileWarning: false,
      maxFileSizeWarning: 100000000, // 100MB
    }
  },

  computed: {
    ...mapGetters({}),

    apiUrl() {
      if (this.selectedEodNoteFilterId === 0) {
        return `${AppConfig.vmsApiUrl}/api/v2/internal/projects/${this.projectId}/attachments?is_eod_note=1`
      } else {
        return `${AppConfig.vmsApiUrl}/api/v2/internal/projects/${this.projectId}/attachments?is_eod_note=1&order_job_id=${this.selectedEodNoteFilterId}`
      }
    },

    defaultLabel() {
      return `<div class='vd-dark-grey vms-uploader__text'>Drop your files here or click to <u class='vd-light-blue'>browse</u></div><div class='vd-grey'>Supports pdf, doc, xls and ppt documents. Maximum file size 2GB.</div>`
    },
  },

  mounted() {},

  methods: {
    ...mapActions({
      getProjectEodNotes: 'project/getProjectEodNotes',
      getJobEodNotes: 'project/getJobEodNotes',
    }),

    handleProcessFile() {
      this.largeFileWarning = false

      if (this.selectedEodNoteFilterId === 0) {
        const payload = {
          projectId: this.projectId,
          include: ['created_by'],
        }

        this.getProjectEodNotes(payload)
      } else {
        const payload = {
          projectId: this.projectId,
          jobId: this.selectedEodNoteFilterId,
          include: ['created_by'],
        }

        this.getJobEodNotes(payload)
      }
    },

    handleAddFile(error, file) {
      if (isNull(error)) {
        // If file is bigger than 100MB we will show the warning.
        if (file.file.size > this.maxFileSizeWarning) {
          this.largeFileWarning = true
        }
      }
    },

    handleUpdateFiles(files) {
      if (isEmpty(files)) {
        this.largeFileWarning = false
      }
    },
  },
}
</script>
