<template>
  <div
    :class="[
      'vd-darker-grey vd-margin-bottom-medium',
      !isMobileScreen
        ? 'vd-padding-medium vd-border-lighter-grey vd-border-radius-6 vd-border-width-2'
        : '',
    ]"
  >
    <div class="row no-gutters vd-darker-grey">
      <div class="col-lg-6">
        <div
          v-if="showLocation"
          class="vd-padding-small vd-border-radius-6"
          :style="eventColorStyle"
        >
          <div class="vd-text-small vd-text-strong">Location</div>
          <a
            :href="locationLink"
            target="_blank"
            class="vd-h5 vd-blue vd-hover-blue vd-border-blue vd-border-width-0 vd-border-bottom-width-1 vd-text-decoration-none"
            >{{ location }}</a
          >
        </div>

        <div v-if="canShowProjectDetails" class="vd-padding-small">
          <div class="vd-text-small vd-text-strong">Project</div>
          <input-clipboard-copy
            v-if="isMobileDevice"
            :input-value="projectDetailsCopy"
          ></input-clipboard-copy>

          <div v-else class="vd-display-flex vd-align-center">
            <router-link
              target="_blank"
              class="vd-darker-grey vd-text-decoration-none vd-border-dark-grey vd-border-width-0 vd-border-bottom-width-1 vd-hover-light-blue vd-hover-border-light-blue"
              :to="{
                name: 'project.details.summary',
                params: { projectId },
              }"
              >{{ projectId }} - {{ projectName }}</router-link
            >
            <clipboard-copy
              success-message="copied!"
              :copy-content="projectDetailsCopy | cleanString"
            >
              <i
                class="vd-icon icon-copy vd-margin-left-medium vd-cursor-pointer vd-hover-light-blue"
              ></i>
            </clipboard-copy>
          </div>
        </div>

        <div class="vd-padding-small">
          <div class="vd-text-small vd-text-strong">Event type</div>
          <div>
            {{ eventType }}
            <span v-if="isPlaceholderEventType"> (Placeholder)</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClipboardCopy from '@components/clipboard-copy/clipboard-copy'
import InputClipboardCopy from '@components/input-clipboard-copy/input-clipboard-copy'
import VmsConstants from '@configs/vms-constants'
import FiltersMixin from '@mixins/filters-mixin'

import { includes } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  components: {
    ClipboardCopy,
    InputClipboardCopy,
  },
  mixins: [FiltersMixin],

  props: {
    projectName: {
      type: String,
      required: false,
      default: '',
    },

    projectId: {
      type: Number,
      required: false,
      default: null,
    },

    location: {
      type: String,
      required: false,
      default: '',
    },

    eventType: {
      type: String,
      required: false,
      default: '',
    },
    isPlaceholderEventType: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  inject: ['getEventColorStyle'],

  computed: {
    ...mapGetters({
      isMobileDevice: 'common/isMobileDevice',
      isMobileScreen: 'common/isMobileScreen',
      selectedEvent: 'calendar/selectedEvent',
    }),

    locationLink() {
      return `https://maps.google.com/?q=${this.location}`
    },

    eventColorStyle() {
      return this.getEventColorStyle()
    },

    showLocation() {
      let isGoogleOrBambooEvent = includes(
        [
          VmsConstants.eventTypes.EVENT_TYPE_ID_GOOGLE_CALENDAR_EVENT,
          VmsConstants.eventTypes.EVENT_TYPE_ID_BAMBOO_EVENT,
        ],
        this.selectedEvent.event_type_id
      )

      return this.location && !isGoogleOrBambooEvent
    },

    projectDetailsCopy() {
      return `${this.projectId} - ${this.projectName}`
    },

    canShowProjectDetails() {
      return this.projectId && this.projectName
    },
  },
}
</script>
