<template>
  <div
    class="vd-padding-small vd-darker-grey vd-border-radius-6"
    :style="eventColorStyle"
  >
    <div class="vd-text-small vd-text-strong">Google document</div>
    <div v-if="preProductionDetailsLoading">Loading...</div>
    <div v-else>
      <a
        v-if="fileUrl"
        :href="fileUrl"
        class="vd-h5 vd-blue vd-hover-blue vd-border-blue vd-border-width-0 vd-border-bottom-width-1 vd-text-decoration-none"
        >{{ fileUrl | truncateString(50) }}</a
      >
      <span v-else> -- </span>
    </div>
  </div>
</template>

<script>
import FiltersMixin from '@mixins/filters-mixin'
import ApiCustomIncludes from '@configs/api-custom-includes'
import { mapGetters, mapActions } from 'vuex'

export default {
  inject: ['getEventColorStyle'],

  mixins: [FiltersMixin],

  computed: {
    ...mapGetters({
      preProductionDetailsLoading:
        'project/pre-production/preProductionDetailsLoading',
      preProductionDetails: 'project/pre-production/preProductionDetails',
      selectedEvent: 'calendar/selectedEvent',
    }),

    eventColorStyle() {
      return this.getEventColorStyle()
    },

    fileUrl() {
      return this.preProductionDetails?.file_url || ''
    },
  },

  mounted() {
    // fetch pre productions event details
    this.getPreProductionDetails({
      include: ApiCustomIncludes.eventsDetails,
      projectId: this.selectedEvent.project.id,
      orderJobId: this.selectedEvent.order_job_element.order_job.id,
    })
  },

  methods: {
    ...mapActions({
      getPreProductionDetails: 'project/pre-production/getPreProductionDetails',
    }),
  },
}
</script>
