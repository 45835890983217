var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 vd-text-align-left"},[_c('div',{class:[
        'vd-field vd-margin-bottom-20',
        _vm.numberOfCameras !== '' ? 'vd-input-valid' : '' ]},[_c('label',[_vm._v("Number of cameras")]),_c('model-list-select',{attrs:{"list":_vm.numberOfCamerasList,"option-value":"id","option-text":"name","placeholder":"Select the number of cameras used"},model:{value:(_vm.numberOfCameras),callback:function ($$v) {_vm.numberOfCameras=$$v},expression:"numberOfCameras"}})],1),_vm._l((_vm.numberOfCameras),function(number,index){return _c('div',{key:index,class:[
        'vd-field vd-margin-bottom-20',
        _vm.cameraVideoClipsArray[index] ? 'vd-input-valid' : '' ]},[_c('label',{staticClass:"vd-black"},[_vm._v("Camera "+_vm._s(index + 1)+": number of video clips")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cameraVideoClipsArray[index]),expression:"cameraVideoClipsArray[index]"}],staticClass:"vd-margin-bottom-20 vd-border-light-grey",attrs:{"type":"text","name":"cameraVideoClipsArray[]","placeholder":"Enter how many clips were captured"},domProps:{"value":(_vm.cameraVideoClipsArray[index])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.cameraVideoClipsArray, index, $event.target.value)}}})])}),_c('div',{class:[
        'vd-field vd-margin-bottom-20',
        _vm.audioSource !== '' ? 'vd-input-valid' : '' ]},[_c('label',[_vm._v("Audio source")]),_c('model-list-select',{attrs:{"list":_vm.audioSourceList,"option-value":"id","option-text":"name","placeholder":"Select what device recorded the audio"},model:{value:(_vm.audioSource),callback:function ($$v) {_vm.audioSource=$$v},expression:"audioSource"}})],1),_c('div',{staticClass:"vd-margin-bottom-20"},[_c('input-component',{attrs:{"type":"text","name":"numberOfAudioFiles","label":"Number of audio files","placeholder":"Enter how many audio files were captured","container-custom-classes":[
          _vm.setInputValidationClass('numberOfAudioFiles') ]},on:{"formInput":function($event){return _vm.handleInputUpdate($event, 'numberOfAudioFiles')}},model:{value:(_vm.numberOfAudioFiles),callback:function ($$v) {_vm.numberOfAudioFiles=$$v},expression:"numberOfAudioFiles"}})],1),_c('div',{class:[
        'vd-field vd-margin-bottom-20',
        _vm.colorProfile !== '' ? 'vd-input-valid' : '' ]},[_c('label',[_vm._v("Color profile")]),_c('model-list-select',{attrs:{"list":_vm.colorProfileList,"option-value":"id","option-text":"name","placeholder":"Select the suitable color profile"},model:{value:(_vm.colorProfile),callback:function ($$v) {_vm.colorProfile=$$v},expression:"colorProfile"}})],1),_c('div',{class:[
        'vd-field vd-margin-bottom-20',
        _vm.isFootageForStock !== '' ? 'vd-input-valid' : '' ]},[_c('label',[_vm._v("Footage suitable for stock?")]),_c('model-list-select',{attrs:{"list":_vm.footageStockList,"option-value":"id","option-text":"name","placeholder":"Is any footage useful in another edit?"},model:{value:(_vm.isFootageForStock),callback:function ($$v) {_vm.isFootageForStock=$$v},expression:"isFootageForStock"}})],1),_c('div',{staticClass:"vd-text-align-right vd-margin-bottom-20"},[_c('button',{staticClass:"vd-btn-small vd-btn-blue",attrs:{"disabled":_vm.eodNotesSaveBtnLoading},on:{"click":function($event){$event.preventDefault();return _vm.handleSaveFilmingForm($event)}}},[_vm._v(" "+_vm._s(_vm.eodNotesSaveBtnLoading ? 'Saving...' : 'Save')+" ")])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }