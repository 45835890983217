<template>
  <events-modal
    :calendar-color="eventColor"
    :is-save-disabled="$v.$invalid"
    :has-changes="$v.$dirty"
    :is-loading="isEventFormLoading"
    title="New Filming event"
    @click:cancel="closeModal"
    @click:save="saveEventDetails"
  >
    <div
      :class="[
        'vd-darker-grey vd-margin-bottom-medium',
        !isMobileScreen
          ? 'vd-padding-block-small vd-border-lighter-grey vd-border-radius-6 vd-border-width-2'
          : '',
      ]"
    >
      <v-app class="vms-event-modal-fields">
        <events-form-fields-project
          :project-id.sync="projectId"
          :required="true"
          :disabled="isEventFormLoading"
        ></events-form-fields-project>

        <events-form-fields-event-name
          :event-name.sync="eventName"
          :disabled="isEventFormLoading"
        ></events-form-fields-event-name>

        <events-form-fields-address
          :street.sync="street"
          :suburb.sync="suburb"
          :state.sync="state"
          :postcode.sync="postcode"
          :has-suburb="true"
          :has-postcode="true"
          :disabled="isEventFormLoading"
          :is-required="true"
        ></events-form-fields-address>

        <events-form-fields-additional-extras
          :additional-extras.sync="additionalExtras"
          :extras-list="extrasList"
          :disabled="isEventFormLoading"
          :is-loading="availableExtrasLoading"
        ></events-form-fields-additional-extras>
      </v-app>
    </div>

    <events-form-fields
      :assign-to-user.sync="assignTo"
      :assign-to-freelancer.sync="freelancer"
      :start-date.sync="startDate"
      :start-time.sync="startTime"
      :end-time.sync="endTime"
      :duration.sync="duration"
      :general-comments.sync="generalComments"
      :disabled="isEventFormLoading"
      :has-general-comments="true"
    ></events-form-fields>
  </events-modal>
</template>

<script>
import EventsModal from '@components/modal/events/events-modal'
import EventsFormFieldsProject from '@components/modal/events/components/events-form-fields/events-form-fields-project'
import EventsFormFieldsEventName from '@components/modal/events/components/events-form-fields/events-form-fields-event-name'
import EventsFormFieldsAddress from '@components/modal/events/components/events-form-fields/events-form-fields-address'
import EventsFormFieldsAdditionalExtras from '@components/modal/events/components/events-form-fields/events-form-fields-additional-extras'
import EventsFormFields from '@components/modal/events/components/events-form-fields/events-form-fields'
import FormMixin from '@mixins/forms-mixin'
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import { map, join } from 'lodash'
import ApiCustomIncludes from '@configs/api-custom-includes'

export default {
  components: {
    EventsModal,
    EventsFormFieldsProject,
    EventsFormFieldsEventName,
    EventsFormFieldsAddress,
    EventsFormFieldsAdditionalExtras,
    EventsFormFields,
  },

  mixins: [FormMixin],

  inject: ['eventsListIncludes'],

  validations: {
    projectId: {
      required,
    },
    eventName: {
      required,
    },
    street: {
      required,
    },
    suburb: {
      required,
    },
    state: {
      required,
    },
    postcode: {
      required,
    },
    startDate: {
      required,
    },
    startTime: {
      required,
    },
    endTime: {
      required,
    },
    additionalExtras: {},
    assignTo: {},
    generalComments: {},
  },

  data() {
    return {
      menuProps: {
        closeOnClick: true,
        offsetY: true,
      },
      projectId: null,
      eventName: '',
      additionalExtras: [],
      street: '',
      suburb: '',
      state: null,
      postcode: '',
      assignTo: null,
      freelancer: null,
      startDate: null,
      startTime: '',
      endTime: '',
      duration: 0,
      generalComments: '',
      eventColor: '',
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      filmingDetailsExtras: 'project/shoot/filmingDetailsExtras',
      availableExtrasLoading: 'project/shoot/availableExtrasLoading',
      isEventFormLoading: 'calendar/isEventFormLoading',
      selectedSchedules: 'calendar/selectedSchedules',
    }),

    extrasList() {
      if (!this.projectId) {
        return []
      }

      return map(this.filmingDetailsExtras, (extra) => {
        return { id: extra.id, name: extra.product_element_type.name }
      })
    },

    payload() {
      return {
        name: this.eventName,
        event_assign_to: this.assignTo?.id || null,
        event_freelancer: this.freelancer || null,
        street: this.street || null,
        suburb: this.suburb || null,
        state_id: this.state || null,
        postcode: this.postcode || null,
        production_date: this.startDate,
        production_time: this.startTime,
        duration: this.duration,
        additional_extras: join(this.additionalExtras),
        external_note: this.generalComments,
      }
    },
  },

  watch: {
    payload() {
      this.$v.$touch()
    },

    projectId(projectId) {
      if (projectId) {
        this.getShootProductElements({
          project_id: projectId,
          includes: ApiCustomIncludes.filmingDetailsList,
        })
      }
    },
  },

  mounted() {
    this.setInitialData()
  },

  beforeDestroy() {
    this.closeModal()
  },

  methods: {
    ...mapActions({
      getShootProductElements: 'project/shoot/getShootProductElements',
      createNewFilmingEvent: 'calendar/createNewFilmingEvent',
      setSelectedSchedules: 'calendar/setSelectedSchedules',
      setShowEventsModal: 'calendar/setShowEventsModal',
      setShowEventsFilmingDetailsCreateModal:
        'calendar/setShowEventsFilmingDetailsCreateModal',
    }),

    setInitialData() {
      this.startDate = this.selectedSchedules?.startDate
      this.startTime = this.selectedSchedules?.startTime
      this.endTime = this.selectedSchedules?.endTime
      this.freelancer = this.selectedSchedules?.freelancer
      this.assignTo = this.selectedSchedules?.assignTo
      this.projectId = this.selectedSchedules?.projectId
    },

    closeModal() {
      this.setShowEventsFilmingDetailsCreateModal(false)
      this.setShowEventsModal(false)
      this.setSelectedSchedules(null)
    },

    saveEventDetails() {
      this.createNewFilmingEvent({
        projectId: this.projectId,
        includes: ApiCustomIncludes[this.eventsListIncludes],
        name: this.eventName,
        payload: this.payload,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@styles/plugins/vuetify';
</style>
