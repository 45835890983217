<template>
  <main-layout>
    <div
      slot="content-body"
      :class="[
        'vms-calendar row no-gutters',
        isMobileScreen ? 'vms-calendar__mobile' : 'vms-content__container',
      ]"
    >
      <div
        class="vms-calendar__section--left vd-border-light-grey vd-border-width-0 vd-border-right-width-1 vd-padding-inline-medium"
      >
        <div class="vd-margin-bottom-medium vd-padding-top-small">
          <div class="row no-gutters">
            <div class="col-12 vms-breadcrumb">
              <a
                id="viewHomePageLink"
                class="vd-a vd-icon-home"
                :href="homePageUrl"
              ></a>
              <span
                class="vd-light-grey vd-margin-right-extra-small vd-margin-left-extra-small"
              >
                |
              </span>
              <span class="vd-black">Calendar</span>
            </div>
          </div>
        </div>

        <div v-show="!isFiltersLoading">
          <calendar-search-add-events></calendar-search-add-events>

          <calendar-mini-calendar-navigation
            v-if="!isMobileScreen"
            class="vd-margin-top-medium"
          ></calendar-mini-calendar-navigation>

          <hr v-if="!isMobileScreen" />

          <calendar-side-panel-agenda
            v-if="!isMobileScreen"
          ></calendar-side-panel-agenda>
          <calendar-side-panel-agenda-mobile
            v-if="showCalendarAgendaMobile"
          ></calendar-side-panel-agenda-mobile>

          <hr v-if="!isMobileScreen" />

          <calendar-side-panel-filters
            v-show="showCalendarSidePanelFilter"
          ></calendar-side-panel-filters>
        </div>
      </div>

      <div class="vms-calendar__section--right vd-padding-medium">
        <calendar-main></calendar-main>
      </div>
    </div>
  </main-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import MainLayout from '@/layouts/main-layout'
import AppConfig from '@configs/app-config'
import CalendarSidePanelFilters from '@views/calendar/calendar-side-panel/calendar-side-panel-filters/calendar-side-panel-filters'
import CalendarSidePanelAgenda from '@views/calendar/calendar-side-panel/calendar-side-panel-agenda/calendar-side-panel-agenda'
import CalendarSidePanelAgendaMobile from '@views/calendar/calendar-side-panel/calendar-side-panel-agenda/calendar-side-panel-agenda-mobile'
import CalendarMain from '@views/calendar/calendar-main/calendar-main'
import CalendarMiniCalendarNavigation from '@views/calendar/components/calendar-mini-calendar-navigation'
import CalendarSearchAddEvents from '@views/calendar/components/calendar-search-add-events'

export default {
  components: {
    MainLayout,
    CalendarSidePanelFilters,
    CalendarSidePanelAgenda,
    CalendarMain,
    CalendarMiniCalendarNavigation,
    CalendarSearchAddEvents,
    CalendarSidePanelAgendaMobile,
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      isEventTypesLoading: 'calendar/isEventTypesLoading',
      showMobileFilterList: 'calendar/showMobileFilterList',
      showMobileAgendaModal: 'calendar/showMobileAgendaModal',
      isTeamsLoading: 'user/isTeamsLoading',
      isInternalUsersLoading: 'user/isInternalUsersLoading',
    }),

    homePageUrl() {
      return `${AppConfig.vmsApiUrl}`
    },

    isFiltersLoading() {
      return (
        this.isEventTypesLoading ||
        this.isTeamsLoading ||
        this.isInternalUsersLoading
      )
    },

    showCalendarSidePanelFilter() {
      return (
        !this.isMobileScreen ||
        (this.isMobileScreen && this.showMobileFilterList)
      )
    },

    showCalendarAgendaMobile() {
      return this.isMobileScreen && this.showMobileAgendaModal
    },
  },
}
</script>

<style lang="scss">
@import '@styles/v2/views/calendar/calendar';
@import '@styles/v2/plugins/syncfusion';
</style>
