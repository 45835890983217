<template>
  <div>
    <div v-if="!hasAgendas" class="vd-margin-bottom-medium vd-dark-grey">
      No assigned events
    </div>

    <div
      v-for="(agenda, index) in agendas"
      :key="index"
      class="vd-margin-bottom-medium vd-h5 vd-cursor-pointer"
      @click="handleAgendaClick(agenda)"
    >
      <div class="row no-gutters">
        <div class="col-1">
          <user-profile
            v-if="agenda.user"
            :user="agenda.user"
            :custom-avatar-font-size="15"
            :can-use-user-photo="false"
          ></user-profile>
        </div>

        <div class="col-11">
          <div class="vd-dark-grey vd-margin-bottom-extra-small row no-gutters">
            <div>
              {{ agenda.from | unixToFormat('hh:mm A') }} -
              {{ agenda.to | unixToFormat('hh:mm A') }}
            </div>

            <div v-if="agenda.event_type">
              <div
                :class="[
                  'vd-margin-left-extra-small vms-calendar__icon',
                  getEventTypeIcon(agenda.event_type.id),
                ]"
              ></div>
            </div>
          </div>

          <div class="vd-darker-grey vd-margin-top-extra-small">
            <strong>{{ agenda.name }}</strong>
          </div>

          <div
            v-if="agenda.event_type"
            class="vd-dark-grey vd-margin-top-extra-small"
          >
            {{ agenda.event_type.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from '@mixins/filters-mixin'
import UserProfile from '@components/user-profile/user-profile'
import { size } from 'lodash'
import momentTz from 'moment-timezone'
import { mapActions, mapGetters } from 'vuex'
import ApiCustomIncludes from '@configs/api-custom-includes'
import VmsConstants from '@configs/vms-constants'

export default {
  components: {
    UserProfile,
  },

  mixins: [FiltersMixin],

  props: {
    agendas: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      selectedEvent: 'calendar/selectedEvent',
    }),

    hasAgendas() {
      return size(this.agendas) > 0
    },
  },

  methods: {
    ...mapActions({
      setSelectedEvent: 'calendar/setSelectedEvent',
      getEventDetails: 'calendar/getEventDetails',
      setShowEventsModal: 'calendar/setShowEventsModal',
    }),

    getEventTypeIcon(eventTypeId) {
      let iconClass = ''

      switch (eventTypeId) {
        case VmsConstants.eventTypes.EVENT_TYPE_ID_BAMBOO_EVENT:
          iconClass = 'vd-icon-bamboo-hr'
          break
        case VmsConstants.eventTypes.EVENT_TYPE_ID_GOOGLE_CALENDAR_EVENT:
          iconClass = 'vd-icon-google-calendar'
          break
        default:
          iconClass = ''
      }

      return iconClass
    },

    handleAgendaClick(event) {
      // Reset timezone to default before opening event modal
      momentTz.tz.setDefault(this.defaultCalendarTimezone)

      // Fetch event details when requesting event is not the same as selectedEvent
      // to prevent requesting event details that is already available.
      if (this.selectedEvent?.id !== event.id) {
        this.getEventDetails({
          eventId: event.id,
          include: ApiCustomIncludes.eventsDetails,
        })

        this.setSelectedEvent(event)
      }

      this.setShowEventsModal(true)
    },
  },
}
</script>
