<template>
  <div class="vms-calendar__events-card">
    <div
      v-for="(calendarEvent, index) in calendarEvents"
      :key="index"
      :event="calendarEvent"
      class="vd-background-white vd-border-width-1 vd-border-light-grey vd-border-radius-6 vd-box-shadow vd-padding-medium vd-margin-block-medium"
    >
      <div class="row no-gutters">
        <div v-if="calendarEvent.project" class="col-6 vd-margin-bottom-10">
          <div class="vd-darker-grey small">Project ID</div>
          <div>{{ calendarEvent.project.id }}</div>
        </div>
        <div class="col-6 vd-margin-bottom-10">
          <div class="vd-darker-grey small">Event name</div>
          <div>{{ calendarEvent.name }}</div>
        </div>
        <div class="col-6 vd-margin-bottom-10">
          <div class="vd-darker-grey small">Event type</div>
          <div>{{ calendarEvent.event_type.name }}</div>
        </div>
        <div class="col-6 vd-margin-bottom-10">
          <div class="vd-darker-grey small">Event date</div>
          <div>{{ calendarEvent.from | unixToFormat('DD MMM YYYY') }}</div>
        </div>
        <div class="col-6 vd-margin-bottom-10">
          <div class="vd-darker-grey small">Start time</div>
          <div>{{ calendarEvent.from | unixToFormat('hh:mm a') }}</div>
        </div>
        <div class="col-6 vd-margin-bottom-10">
          <div class="vd-darker-grey small">Duration</div>
          <div>
            {{ calendarEvent.duration | getDurationFormat }}
          </div>
        </div>

        <div class="col-12 vd-text-align-right vd-margin-top-small">
          <slot name="action" :event="calendarEvent"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from '@mixins/filters-mixin'

export default {
  mixins: [FiltersMixin],

  props: {
    calendarEvents: {
      type: Array,
      required: true,
    },
  },
}
</script>
