<template>
  <div class="vd-padding-medium">
    <v-autocomplete
      :value="projectId"
      :items="projectsList"
      :hide-selected="true"
      :loading="isProjectsListLoading"
      :disabled="disabled"
      :menu-props="menuProps"
      :class="[
        'vd-field',
        required ? 'vd-required' : '',
        projectId ? 'vd-input-valid' : '',
      ]"
      :filter="customFilter"
      :placeholder="placeholder"
      :no-data-text="noDataText"
      item-text="name"
      item-value="id"
      label="Project"
      clearable
      cache-items
      persistent-placeholder
      @change="handleProjectChange"
      @update:search-input="handleSearchProject($event)"
    >
      <template v-slot:selection="{ item }">
        <span class="vd-light-blue vd-margin-right-small">
          #{{ item.id }}
        </span>
        {{ item.name }}
      </template>
      <template v-slot:item="{ item }">
        <span class="vd-light-blue vd-margin-right-small">
          #{{ item.id }}
        </span>
        {{ item.name }}
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { filter, map, includes } from 'lodash'
import VmsConstants from '@configs/vms-constants'
import Vuetify from 'vuetify'

export default {
  vuetify: new Vuetify(),

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    projectId: {
      type: Number,
      required: false,
      default: null,
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      menuProps: {
        closeOnClick: true,
        offsetY: true,
      },
      projectSearchTimeout: null,
    }
  },

  computed: {
    ...mapGetters({
      projectsList: 'calendar/projectsList',
      isProjectsListLoading: 'calendar/isProjectsListLoading',
    }),

    statusFilter() {
      const statuses = map(
        filter(Object.entries(VmsConstants.projectStatuses), ([, value]) => {
          return !includes(
            [
              VmsConstants.projectStatuses.PROJECT_STATUS_ID_DRAFT,
              VmsConstants.projectStatuses.PROJECT_STATUS_ID_ARCHIVED,
              VmsConstants.projectStatuses.PROJECT_STATUS_ID_CANCELLED,
            ],
            value
          )
        }),
        ([, value]) => value
      )
      return statuses.join(',')
    },

    placeholder() {
      return this.required
        ? 'Search for your existing project here'
        : 'Does a project exist this event will be related to? (You can leave this blank)'
    },

    noDataText() {
      return this.isProjectsListLoading ? 'Loading...' : 'No results to display'
    },
  },

  mounted() {
    this.loadInitialProjectList()
  },

  beforeDestroy() {
    this.setProjectsList([])
  },

  methods: {
    ...mapActions({
      getProjectsList: 'calendar/getProjectsList',
      setProjectsList: 'calendar/setProjectsList',
    }),

    customFilter(item, queryText) {
      const itemIdAndName = `#${item.id} ${item.name}`
      const query = queryText.toLowerCase()
      const text = itemIdAndName.toLowerCase()
      return text.indexOf(query) > -1
    },

    loadInitialProjectList() {
      this.getProjectsList({
        include: [],
        search: this.projectId,
        perPage: 10,
        filter: {
          status: this.statusFilter,
        },
      })
    },

    // Using regular timeout instead of debounce so we can test it properly
    handleSearchProject(searchText) {
      if (this.projectSearchTimeout) {
        clearTimeout(this.projectSearchTimeout)
      }
      this.projectSearchTimeout = setTimeout(() => {
        if (!searchText || searchText.length < 3) {
          return
        }

        this.getProjectsList({
          include: [],
          search: searchText,
          filter: {
            status: this.statusFilter,
          },
        })
      }, 1000)
    },

    handleProjectChange(projectId) {
      this.$emit('update:projectId', projectId)
    },
  },
}
</script>
