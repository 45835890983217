<template>
  <div
    class="vd-background-lightest-grey vd-padding-top-medium vd-padding-bottom-extra-small vd-border-radius-6 vd-margin-bottom-20"
  >
    <eod-notes-comment
      v-for="(note, index) in pinnedEodNotes"
      :key="index"
      :note="note"
      :project-id="projectId"
    ></eod-notes-comment>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EodNotesComment from '@components/eod-notes/eod-notes-comment'

export default {
  components: {
    EodNotesComment,
  },

  props: {
    projectId: {
      type: Number,
      required: true,
      default: 0,
    },
  },

  computed: {
    ...mapGetters({
      pinnedEodNotes: 'project/pinnedEodNotes',
    }),
  },
}
</script>
