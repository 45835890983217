<template>
  <div class="vd-display-flex vd-full-height vd-full-width">
    <div>
      <div class="vd-display-flex">
        <div class="vms-calendar__appointments-title">
          <div class="vms-calendar__appointments-title--nowrap">
            <span class="vd-margin-right-extra-small">
              <strong>{{ eventName }}</strong>
            </span>
            <span v-if="!isExternalEvent">
              <span v-if="showEventState"> - {{ eventData.state.name }}</span>
              <span v-if="eventData.event_type"> - {{ eventTypeLabel }}</span>
              <span v-if="isPlaceholderEventType"> (Placeholder)</span>
              <span v-if="isEventOnHold"> (On Hold)</span>
            </span>
          </div>
          <div>
            {{ eventData.StartTime | momentFormat('hh:mm A') }} -
            {{ eventData.EndTime | momentFormat('hh:mm A') }}
          </div>
        </div>
        <div class="vms-calendar__appointments-type vd-text-align-right">
          <div
            :class="[
              'vms-calendar__event-type vd-align-middle',
              isGoogleCalendarEventType ? 'vd-icon-google-calendar' : '',
              isBambooHrEventType ? 'vd-icon-bamboo-hr' : '',
            ]"
          ></div>
        </div>
      </div>
    </div>

    <div class="vms-calendar__search-results--info vd-margin-left-auto">
      <div class="vd-display-flex">
        <div v-if="showEventProject">
          <div>Project ID: {{ eventData.project.id }}</div>
          <div>
            Project Name: {{ eventData.project.name | truncateString(20) }}
          </div>
        </div>
        <div
          v-if="eventData.user"
          class="vd-margin-top-extra-small vd-margin-left-small"
        >
          <div class="vd-text-align-right">
            <div class="vd-display-flex vd-align-center">
              <user-profile
                :user="eventData.user"
                :custom-avatar-font-size="25"
              ></user-profile>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from '@mixins/filters-mixin'
import UserProfile from '@components/user-profile/user-profile'
import VmsConstants from '@configs/vms-constants'

export default {
  components: {
    UserProfile,
  },

  mixins: [FiltersMixin],

  props: {
    eventData: {
      type: Object,
      required: true,
    },

    isGoogleCalendarEventType: {
      type: Boolean,
      required: false,
    },

    isBambooHrEventType: {
      type: Boolean,
      required: false,
    },

    isExternalEvent: {
      type: Boolean,
      required: false,
    },

    isPlaceholderEventType: {
      type: Boolean,
      required: false,
      default: false,
    },

    isMobileScreen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    isEventOnHold() {
      return (
        this.eventData.event_status_id ===
        VmsConstants.eventStatuses.EVENT_STATUS_ID_ON_HOLD
      )
    },

    eventName() {
      return this.isMobileScreen
        ? `${this.$options.filters.truncateString(this.eventData.name, 15)}`
        : this.eventData.name
    },

    eventTypeLabel() {
      return this.isMobileScreen
        ? `${this.$options.filters.truncateString(
            this.eventData.event_type.name,
            10
          )}`
        : this.eventData.event_type.name
    },

    showEventState() {
      return this.eventData.state && !this.isMobileScreen
    },

    showEventProject() {
      return this.eventData.project && !this.isMobileScreen
    },
  },
}
</script>
