<template>
  <default-modal
    :class="[
      'show vd-text-align-left',
      isMobileScreen ? 'vms-modal--fullscreen' : '',
    ]"
    modal-dialog-class="modal-xl"
    content-class="vd-background-white"
  >
    <div slot="modal-header" class="modal-header">
      <div class="vd-h4 vd-black vd-text-strong">My agenda</div>
      <span
        id="closeAgendaBtn"
        class="vms-modal__header--close close vd-black"
        @click="handleCloseAgendaModal"
      >
        x
      </span>
    </div>

    <div slot="modal-body" class="modal-body">
      <calendar-side-panel-agenda></calendar-side-panel-agenda>
    </div>
  </default-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DefaultModal from '@components/modal/default-modal'
import CalendarSidePanelAgenda from '@views/calendar/calendar-side-panel/calendar-side-panel-agenda/calendar-side-panel-agenda'

export default {
  components: {
    DefaultModal,
    CalendarSidePanelAgenda,
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
    }),
  },

  methods: {
    ...mapActions({
      setShowMobileAgendaModal: 'calendar/setShowMobileAgendaModal',
    }),

    handleCloseAgendaModal() {
      this.setShowMobileAgendaModal(false)
    },
  },
}
</script>
