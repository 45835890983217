<template>
  <div>
    <default-loader
      v-if="isFiltersLoading"
      class="vms-loader__visdom-shield--full"
    ></default-loader>

    <template v-else>
      <div class="row no-gutters vd-flex-wrap vd-align-end">
        <calendar-selected-filters
          class="vd-flex-auto vd-margin-bottom-extra-small vd-margin-right-medium"
        >
        </calendar-selected-filters>

        <div class="col-auto vd-margin-left-auto vd-margin-bottom-small">
          <div class="vms-calendar__settings">
            <div class="vd-display-flex vd-items-center">
              <calendar-refresh
                class="vd-margin-right-small"
              ></calendar-refresh>
              <calendar-undo class="vd-margin-right-small"></calendar-undo>
              <calendar-settings-dropdown></calendar-settings-dropdown>
            </div>
          </div>
        </div>
      </div>

      <div class="vms-calendar__progress">
        <ejs-progressbar
          v-show="canShowCalendarProgressBar"
          id="vms-scheduler-progress"
          ref="indeterminate"
          type="Linear"
          width="auto"
          height="2px"
          :is-indeterminate="true"
          progress-color="#00c5ff"
          :value="20"
        >
        </ejs-progressbar>
      </div>

      <calendar-scheduler></calendar-scheduler>
    </template>

    <events-modal-controller v-if="showEventsModal"></events-modal-controller>
    <transition name="modal">
      <events-bulk-edit-modal
        v-if="showEventsBulkEditModal"
      ></events-bulk-edit-modal>
    </transition>
    <transition name="modal">
      <eod-notes-modal
        v-if="showEodModal"
        :project-id="eodNotesSelectedProject.id"
        :project-name="eodNotesSelectedProject.name"
      >
      </eod-notes-modal>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CalendarRefresh from '@views/calendar/components/calendar-refresh'
import CalendarSettingsDropdown from '@views/calendar/components/calendar-settings-dropdown/calendar-settings-dropdown'
import CalendarSelectedFilters from '@views/calendar/components/calendar-selected-filters/calendar-selected-filters'
import CalendarUndo from '@views/calendar/components/calendar-undo'
import DefaultLoader from '@components/loaders/default-loader'
import CalendarScheduler from '@views/calendar/calendar-main/calendar-scheduler/calendar-scheduler'
import EventsModalController from '@components/events/events-modal-controller'
import EventsBulkEditModal from '@components/modal/events/events-bulk-edit-modal'
import EodNotesModal from '@components/eod-notes/eod-notes-modal'
import { omit, filter, includes, split, forEach, isEmpty } from 'lodash'

export default {
  components: {
    CalendarSettingsDropdown,
    CalendarSelectedFilters,
    CalendarRefresh,
    CalendarUndo,
    DefaultLoader,
    CalendarScheduler,
    EventsModalController,
    EventsBulkEditModal,
    EodNotesModal,
  },

  provide: {
    eventsListIncludes: 'eventsList',
  },

  computed: {
    ...mapGetters({
      isEventTypesLoading: 'calendar/isEventTypesLoading',
      isTeamsLoading: 'user/isTeamsLoading',
      isInternalUsersLoading: 'user/isInternalUsersLoading',
      showEventsModal: 'calendar/showEventsModal',
      showEventsBulkEditModal: 'calendar/showEventsBulkEditModal',
      eventsListPayload: 'calendar/eventsListPayload',
      showEodModal: 'project/showEodModal',
      eodNotesSelectedProject: 'project/eodNotesSelectedProject',
      canShowCalendarProgressBar: 'calendar/canShowCalendarProgressBar',
      selectedFilters: 'calendar/selectedFilters',
      crewFilters: 'calendar/crewFilters',
      eventFilters: 'calendar/eventFilters',
      localStorageSelectedFilters: 'calendar/localStorageSelectedFilters',
      projectOwners: 'user/projectOwners',
    }),

    isFiltersLoading() {
      return (
        this.isEventTypesLoading ||
        this.isTeamsLoading ||
        this.isInternalUsersLoading
      )
    },
  },

  watch: {
    isFiltersLoading() {
      if (!this.isFiltersLoading) {
        this.setSelectedFilters()

        this.getEventsList({
          payload: this.eventsListPayload,
          showLoader: true,
        })
      }
    },
  },

  beforeMount() {
    if (this.$route.query) {
      this.checkSelectedSettingsFiltersInRoute('assigned_to_me', {
        id: 'show-events-assigned-to-me',
        label: 'Show events assigned to me',
        value: 'show-events-assigned-to-me',
      })

      this.checkSelectedSettingsFiltersInRoute('show_freelancers', {
        id: 'show-freelancer-events',
        label: 'Show freelancers',
        value: 'show-freelancer-events',
      })

      this.checkSelectedSettingsFiltersInRoute('unassigned', {
        id: 'show-unassigned-events',
        label: 'Show unassigned events',
        value: 'show-unassigned-events',
      })

      this.checkSelectedSettingsFiltersInRoute('show_external', {
        id: 'show-google-bamboo-events',
        label: 'Show Google/Bamboo events',
        value: 'show-google-bamboo-events',
      })

      this.checkSelectedSettingsFiltersInRoute('show_on_hold_events', {
        id: 'show-on-hold-events',
        label: 'Show on hold events',
        value: 'show-on-hold-events',
      })
    }
  },

  methods: {
    ...mapActions({
      getEventsList: 'calendar/getEventsList',
      addOrRemoveSelectedFilters: 'calendar/addOrRemoveSelectedFilters',
      addSelectedFilter: 'calendar/addSelectedFilter',
      addSelectedOwnedByIdFilters: 'calendar/addSelectedOwnedByIdFilters',
    }),

    setSelectedFilters() {
      const selectedFiltersList = !isEmpty(this.$route.query)
        ? this.$route.query
        : this.localStorageSelectedFilters

      if (selectedFiltersList) {
        if (selectedFiltersList.users) {
          this.checkSelectedFiltersInRoute(
            selectedFiltersList.users,
            this.crewFilters
          )
        }

        if (selectedFiltersList.events) {
          const eventsSubFilters = []
          forEach(this.eventFilters, (eventFilter) => {
            forEach(eventFilter.subFilters, (subFilter) => {
              eventsSubFilters.push(subFilter)
            })
          })

          this.checkSelectedFiltersInRoute(
            selectedFiltersList.events,
            eventsSubFilters
          )
        }

        if (selectedFiltersList.owners) {
          this.checkOwnerFiltersInRoute(
            selectedFiltersList.owners,
            this.projectOwners
          )
        }

        if (
          isEmpty(this.$route.query) &&
          !isEmpty(this.localStorageSelectedFilters)
        ) {
          this.$router
            .replace({ query: this.localStorageSelectedFilters })
            .catch(() => {})
        }
      }
    },

    checkSelectedSettingsFiltersInRoute(filterName, filterDetails) {
      if (!isEmpty(this.$route.query)) {
        if (this.$route.query[filterName] === 'true') {
          this.addSelectedFilter(filterDetails)
        } else {
          this.$router
            .replace({ query: omit(this.$route.query, [filterName]) })
            .catch(() => {})
        }
      } else {
        if (
          !isEmpty(this.localStorageSelectedFilters) &&
          this.localStorageSelectedFilters[filterName]
        ) {
          this.addSelectedFilter(filterDetails)
        }
      }
    },

    checkSelectedFiltersInRoute(selectedFiltersList, filterResource) {
      if (selectedFiltersList) {
        const selectedIds = split(selectedFiltersList, ',').map(Number)

        const selectedFilters = filter(filterResource, (resource) => {
          return includes(selectedIds, resource.id)
        })

        this.addOrRemoveSelectedFilters(selectedFilters)
      }
    },

    checkOwnerFiltersInRoute(selectedFiltersList, projectOwners) {
      if (selectedFiltersList) {
        const selectedIds = split(selectedFiltersList, ',').map(Number)

        const selectedFilters = filter(projectOwners, (owner) => {
          return includes(selectedIds, owner.id)
        })

        forEach(selectedFilters, (filter) => {
          const ownedByIdObject = {
            label: filter.full_name,
            id: filter.id,
            value: filter.id,
            is_project_owner: true,
          }

          this.addSelectedOwnedByIdFilters(ownedByIdObject)
        })
      }
    },
  },
}
</script>
