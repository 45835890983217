<template>
  <events-modal
    :is-save-disabled="$v.$invalid"
    :is-loading="isEventFormLoading"
    :has-changes="$v.$dirty"
    title="New placeholder event"
    @click:cancel="closeModal"
    @click:save="saveEventDetails"
  >
    <events-placeholders-fields
      :project-id.sync="projectId"
      :event-name.sync="eventName"
      :event-type.sync="eventType"
      :street.sync="street"
      :suburb.sync="suburb"
      :state.sync="state"
      :postcode.sync="postcode"
      :disabled="isEventFormLoading"
    ></events-placeholders-fields>

    <events-form-fields
      :assign-to-user.sync="assignTo"
      :assign-to-freelancer.sync="freelancer"
      :start-date.sync="startDate"
      :end-date.sync="endDate"
      :start-time.sync="startTime"
      :end-time.sync="endTime"
      :general-comments.sync="generalComments"
      :is-all-day-event.sync="isAllDayEvent"
      :has-general-comments="true"
      :has-all-day-option="true"
      :disabled="isEventFormLoading"
      @update:isAllDayEvent="handleUpdateIsAllDayEvent"
    ></events-form-fields>
  </events-modal>
</template>

<script>
import EventsModal from '@components/modal/events/events-modal'
import EventsPlaceholdersFields from '@components/modal/events/placeholders/components/events-placeholders-fields'
import EventsFormFields from '@components/modal/events/components/events-form-fields/events-form-fields'
import FormMixin from '@mixins/forms-mixin'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import momentTz from 'moment-timezone'
import ApiCustomIncludes from '@configs/api-custom-includes'

export default {
  components: {
    EventsModal,
    EventsPlaceholdersFields,
    EventsFormFields,
  },

  mixins: [FormMixin],

  inject: ['eventsListIncludes'],

  validations: {
    eventName: {
      required,
    },
    eventType: {
      required,
    },
    startDate: {
      required,
    },
    endDate: {
      required: requiredIf(function () {
        return this.isAllDayEvent
      }),
    },
    startTime: {
      required: requiredIf(function () {
        return !this.isAllDayEvent
      }),
    },
    endTime: {
      required: requiredIf(function () {
        return !this.isAllDayEvent
      }),
    },
    projectId: {},
    isAllDayEvent: {},
    assignTo: {},
    generalComments: {},
    street: {},
    state: {},
  },

  props: {
    placeholderSelectedDates: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      menuProps: {
        closeOnClick: true,
        offsetY: true,
      },
      projectId: null,
      eventName: '',
      eventType: null,
      street: '',
      suburb: '',
      state: null,
      postcode: '',
      assignTo: null,
      freelancer: null,
      startDate: null,
      endDate: null,
      startTime: '',
      endTime: '',
      generalComments: '',
      isAllDayEvent: false,
    }
  },

  computed: {
    ...mapGetters({
      selectedSchedules: 'calendar/selectedSchedules',
      isEventFormLoading: 'calendar/isEventFormLoading',
    }),

    payload() {
      return {
        project_id: this.projectId,
        name: this.eventName,
        event_type_id: this.eventType,
        description: this.generalComments,
        user_id: this.assignTo?.id || null,
        freelancer: this.freelancer || null,
        from: this.placeholderSelectedDates.from,
        to: this.placeholderSelectedDates.to,
        state_id: this.state || null,
        address: this.street || null,
        is_all_day: this.isAllDayEvent,
      }
    },
  },

  watch: {
    payload() {
      this.$v.$touch()
    },

    startDate() {
      if (
        momentTz(this.placeholderSelectedDates.from).format(
          'YYYY-MM-DD HH:mm:ss'
        ) !== this.startDate
      ) {
        this.handleSelectedDatesUpdate()
      }
    },

    endDate() {
      if (
        momentTz(this.placeholderSelectedDates.to).format(
          'YYYY-MM-DD HH:mm:ss'
        ) !== this.endDate
      ) {
        this.handleSelectedDatesUpdate()
      }
    },

    startTime() {
      if (
        momentTz(this.placeholderSelectedDates.from).format('HH:mm:ss') !==
        this.startTime
      ) {
        this.handleSelectedDatesUpdate()
      }
    },

    endTime() {
      if (
        momentTz(this.placeholderSelectedDates.to).format('HH:mm:ss') !==
        this.endTime
      ) {
        this.handleSelectedDatesUpdate()
      }
    },

    isAllDayEvent() {
      this.handleSelectedDatesUpdate()
    },
  },

  mounted() {
    this.setInitialData()
  },

  beforeDestroy() {
    this.closeModal()
  },

  methods: {
    ...mapActions({
      createNewEvent: 'calendar/createNewEvent',
      setSelectedSchedules: 'calendar/setSelectedSchedules',
      setShowEventsModal: 'calendar/setShowEventsModal',
      setShowEventsPlaceholdersCreateModal:
        'calendar/setShowEventsPlaceholdersCreateModal',
    }),

    setInitialData() {
      this.startDate = momentTz(this.placeholderSelectedDates.from).format(
        'YYYY-MM-DD'
      )
      this.endDate = momentTz(this.placeholderSelectedDates.to).format(
        'YYYY-MM-DD'
      )
      this.startTime = momentTz(this.placeholderSelectedDates.from).format(
        'HH:mm:ss'
      )
      this.endTime = momentTz(this.placeholderSelectedDates.to).format(
        'HH:mm:ss'
      )

      if (this.selectedSchedules?.isAllDayEvent) {
        this.isAllDayEvent = this.selectedSchedules?.isAllDayEvent
      } else if (this.startTime === this.endTime) {
        this.handleUpdateIsAllDayEvent(true)
      }

      this.freelancer = this.selectedSchedules?.freelancer
      this.assignTo = this.selectedSchedules?.assignTo
      this.projectId = this.selectedSchedules?.projectId
    },

    handleSelectedDatesUpdate() {
      this.$emit('handle:selected-dates-update', {
        startDate: this.startDate,
        endDate: this.endDate,
        startTime: this.startTime,
        endTime: this.endTime,
        isAllDayEvent: this.isAllDayEvent,
      })
    },

    closeModal() {
      this.$emit('handle:close-placeholder-modal')
    },

    saveEventDetails() {
      this.createNewEvent({
        includes: ApiCustomIncludes[this.eventsListIncludes],
        name: this.eventName,
        payload: this.payload,
      })
      this.$emit('handle:save-placeholder-event')
    },

    handleUpdateIsAllDayEvent(isAllDayEvent) {
      if (isAllDayEvent) {
        // Set the end date to be the same with the start date when changing the all day
        this.endDate = this.startDate
        this.isAllDayEvent = true
      }
    },
  },
}
</script>

<style lang="scss">
@import '@styles/plugins/vuetify';
</style>
