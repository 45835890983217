<template>
  <div>
    <events-placeholders-create-modal
      v-if="showEventsPlaceholdersCreateModal"
      key="placeholders-create-modal"
      :placeholder-selected-dates="selectedDatesFormat"
      @handle:close-placeholder-modal="closeModal"
      @handle:selected-dates-update="handleSelectedDatesUpdate"
      @handle:save-placeholder-event="handleSavePlaceholderEvent"
    />

    <events-placeholders-edit-modal
      v-if="showEventsPlaceholdersEditModal"
      key="events-placeholders-edit-modal"
      :placeholder-selected-dates="selectedDatesFormat"
      @handle:close-placeholder-modal="closeModal"
      @handle:selected-dates-update="handleSelectedDatesUpdate"
      @handle:save-placeholder-event="handleSavePlaceholderEvent"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import momentTz from 'moment-timezone'
import FiltersMixin from '@mixins/filters-mixin'
import EventsPlaceholdersCreateModal from '@components/modal/events/placeholders/events-placeholders-create-modal'
import EventsPlaceholdersEditModal from '@components/modal/events/placeholders/events-placeholders-edit-modal'
import { isNull } from 'lodash'

export default {
  components: {
    EventsPlaceholdersCreateModal,
    EventsPlaceholdersEditModal,
  },

  mixins: [FiltersMixin],

  data() {
    return {
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      isAllDayEvent: false,
      datesUpdated: false,
    }
  },

  computed: {
    ...mapGetters({
      showEventsPlaceholdersCreateModal:
        'calendar/showEventsPlaceholdersCreateModal',
      showEventsPlaceholdersEditModal:
        'calendar/showEventsPlaceholdersEditModal',
      selectedSchedules: 'calendar/selectedSchedules',
      selectedEvent: 'calendar/selectedEvent',
    }),

    isCreatePlaceholderEvent() {
      return (
        this.showEventsPlaceholdersCreateModal &&
        !isNull(this.selectedSchedules)
      )
    },

    isEditPlaceholderEvent() {
      return this.showEventsPlaceholdersEditModal && !isNull(this.selectedEvent)
    },

    isAllDayEventSelected() {
      let isAllDayEvent = this.isAllDayEvent

      if (this.datesUpdated) {
        isAllDayEvent = this.isAllDayEvent
      } else {
        if (this.isCreatePlaceholderEvent) {
          isAllDayEvent = this.selectedSchedules.isAllDayEvent
        }

        if (this.isEditPlaceholderEvent) {
          isAllDayEvent = !!this.selectedEvent.is_all_day
        }
      }

      return isAllDayEvent
    },

    selectedDates() {
      let startDate, endDate, startTime, endTime

      if (this.datesUpdated) {
        startDate = this.startDate
        endDate = this.endDate
        startTime = this.startTime
        endTime = this.endTime
      } else {
        if (this.isCreatePlaceholderEvent) {
          startDate = this.selectedSchedules.startDate
          endDate = this.selectedSchedules.endDate
          startTime = this.selectedSchedules.startTime
          endTime = this.selectedSchedules.endTime
        }

        if (this.isEditPlaceholderEvent) {
          startDate = momentTz
            .unix(this.selectedEvent.from)
            .format('YYYY-MM-DD')
          endDate = momentTz.unix(this.selectedEvent.to).format('YYYY-MM-DD')
          startTime = momentTz.unix(this.selectedEvent.from).format('HH:mm:ss')
          endTime = momentTz.unix(this.selectedEvent.to).format('HH:mm:ss')
        }
      }

      return {
        startDate,
        endDate,
        startTime,
        endTime,
      }
    },

    selectedDatesFormat() {
      const startDateMoment = momentTz(this.selectedDates.startDate)
      const endDateMoment = momentTz(this.selectedDates.endDate)

      // set date from
      const fromDate = this.isAllDayEventSelected
        ? startDateMoment.startOf('day').format('YYYY-MM-DD HH:mm:ss')
        : `${this.selectedDates.startDate} ${this.selectedDates.startTime}`

      // set date to
      let toDate = ''

      if (this.isAllDayEventSelected) {
        toDate = endDateMoment.startOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else if (this.selectedDates.endTime === '00:00:00') {
        // If not all day and end time is 12:00 AM use next day
        toDate = startDateMoment
          .add(1, 'day')
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss')
      } else {
        toDate = `${startDateMoment.format('YYYY-MM-DD')} ${
          this.selectedDates.endTime
        }`
      }

      return {
        from: fromDate,
        to: toDate,
        endDateDisplay: endDateMoment
          .subtract(this.isAllDayEventSelected ? 1 : 0, 'days')
          .format('YYYY-MM-DD HH:mm:ss'),
      }
    },
  },

  methods: {
    ...mapActions({
      setShowEventsPlaceholdersCreateModal:
        'calendar/setShowEventsPlaceholdersCreateModal',
      setShowEventsPlaceholdersEditModal:
        'calendar/setShowEventsPlaceholdersEditModal',
      setShowEventsModal: 'calendar/setShowEventsModal',
      setSelectedSchedules: 'calendar/setSelectedSchedules',
      setSelectedEvent: 'calendar/setSelectedEvent',
    }),

    closeModal() {
      this.setShowEventsPlaceholdersCreateModal(false)
      this.setShowEventsPlaceholdersEditModal(false)
      this.setShowEventsModal(false)
      this.setSelectedSchedules(null)
      this.setSelectedEvent(null)
    },

    handleSelectedDatesUpdate(selectedDates) {
      const selectedStartDate = momentTz(selectedDates.startDate)
      const selectedEndDate = momentTz(selectedDates.endDate).add(
        selectedDates.isAllDayEvent ? 1 : 0,
        'days'
      )

      this.datesUpdated = true

      this.startDate = selectedStartDate.format('YYYY-MM-DD')
      this.endDate = selectedDates.isAllDayEvent
        ? selectedEndDate.format('YYYY-MM-DD')
        : this.startDate
      this.startTime = selectedDates.isAllDayEvent
        ? selectedStartDate.startOf('day').format('HH:mm:ss')
        : selectedDates.startTime
      this.endTime = selectedDates.endTime
      this.isAllDayEvent = selectedDates.isAllDayEvent
    },

    handleSavePlaceholderEvent() {
      this.closeModal()
    },
  },
}
</script>
