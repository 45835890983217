<template>
  <div class="vd-margin-bottom-medium">
    <div
      id="toggleProjectOwnerFiltersListDiv"
      class="vms-video__filters--list-label vd-h4 vd-margin-bottom-small vd-cursor-pointer"
      @click="toggleFilterListExpand"
    >
      <div class="row no-gutters">
        <div class="col-10">
          <div class="vd-h4">
            <strong>{{ filterLabel }}</strong>
          </div>
        </div>

        <div class="col-2 vd-text-align-right">
          <i
            :class="[
              'vd-icon vd-hover-darker-grey vd-text-small',
              isListExpanded ? 'icon-minus' : 'icon-plus',
            ]"
          ></i>
        </div>
      </div>
    </div>

    <div v-show="isListExpanded">
      <div
        v-for="(filter, index) in filtersList"
        :key="`${index}-${filter.id}`"
        class="vd-margin-bottom-medium"
      >
        <div>
          <div class="vd-display-flex vd-align-center vd-margin-bottom-small">
            <div class="row no-gutters">
              <calendar-side-panel-filters-checkbox
                class="vd-display-flex vd-align-center col-12"
                :filter="filter"
                :is-main-filter-selected="isOwnerFilterSelected(filter)"
                @handle:filter-checked="handleMainFilterSelected"
              ></calendar-side-panel-filters-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { includes } from 'lodash'
import CalendarSidePanelFiltersCheckbox from '@views/calendar/calendar-side-panel/calendar-side-panel-filters/components/calendar-side-panel-filters-checkbox'

export default {
  components: {
    CalendarSidePanelFiltersCheckbox,
  },

  props: {
    filterLabel: {
      type: String,
      required: true,
    },

    filtersList: {
      type: Array,
      required: true,
    },

    expandList: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isListExpanded: true,
      expandedMainFilters: [],
      selectedMainFilters: [],
    }
  },

  computed: {
    ...mapGetters({
      eventsListPayload: 'calendar/eventsListPayload',
      selectedOwnedByIdFilters: 'calendar/selectedOwnedByIdFilters',
    }),
  },

  watch: {
    expandList() {
      this.isListExpanded = this.expandList
    },
  },

  mounted() {
    this.isListExpanded = this.expandList
  },

  methods: {
    ...mapActions({
      getEventsList: 'calendar/getEventsList',
      addSelectedOwnedByIdFilters: 'calendar/addSelectedOwnedByIdFilters',
      removeSelectedOwnedByIdFilters: 'calendar/removeSelectedOwnedByIdFilters',
    }),

    toggleFilterListExpand() {
      this.isListExpanded = !this.isListExpanded
    },

    handleMainFilterSelected(selectedFilter, isSelected) {
      if (isSelected) {
        this.addSelectedOwnedByIdFilters(selectedFilter)
      } else {
        this.removeSelectedOwnedByIdFilters(selectedFilter)
      }

      this.getEventsList({
        payload: this.eventsListPayload,
        showLoader: false,
      })
    },

    isOwnerFilterSelected(filter) {
      return includes(this.selectedOwnedByIdFilters, filter)
    },
  },
}
</script>
