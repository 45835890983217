<template>
  <div
    class="vd-padding-small vd-darker-grey vd-border-radius-6"
    :style="eventColorStyle"
  >
    <div class="vd-text-small vd-text-strong">Changes status</div>
    <div>
      <router-link
        v-if="projectId"
        target="_blank"
        class="vd-h5 vd-blue vd-hover-blue vd-border-blue vd-border-width-0 vd-border-bottom-width-1 vd-text-decoration-none"
        :to="{
          name: 'project.details.pre-production',
          params: { projectId },
        }"
      >
        {{ isCompleted ? 'Completed' : 'Not completed' }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['getEventColorStyle'],
  props: {
    isCompleted: {
      type: Boolean,
      required: false,
      default: false,
    },
    projectId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    eventColorStyle() {
      return this.getEventColorStyle()
    },
  },
}
</script>
