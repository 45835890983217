<template>
  <div
    class="vd-padding-small vd-darker-grey vd-border-radius-6"
    :style="eventColorStyle"
  >
    <div class="vd-text-small vd-text-strong">Footage status</div>
    <div>{{ footageStatus || '--' }}</div>
  </div>
</template>

<script>
export default {
  inject: ['getEventColorStyle'],

  props: {
    footageStatus: {
      type: String,
      default: '',
    },
  },

  computed: {
    eventColorStyle() {
      return this.getEventColorStyle()
    },
  },
}
</script>
