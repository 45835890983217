<template>
  <div>
    <hr v-if="isMobileScreen" />

    <calendar-side-panel-filters-search></calendar-side-panel-filters-search>

    <calendar-side-panel-saved-filters
      v-if="canDisplayFilterList('saved-filters')"
      filter-label="My saved filters"
      :filters-list="savedFiltersList"
      :expand-list="isListExpanded"
    ></calendar-side-panel-saved-filters>

    <calendar-side-panel-filters-list
      v-if="canDisplayFilterList('team-filters')"
      filter-label="Teams"
      :filters-list="teamFiltersList"
      :expand-list="isListExpanded"
    ></calendar-side-panel-filters-list>

    <calendar-side-panel-filters-list
      v-if="canDisplayFilterList('event-filters')"
      filter-label="Events"
      :filters-list="eventFiltersList"
      :expand-list="isListExpanded"
    ></calendar-side-panel-filters-list>

    <calendar-side-panel-filters-list
      v-if="canDisplayFilterList('crew-filters')"
      filter-label="Crew"
      :filters-list="crewFiltersList"
      :expand-list="isListExpanded"
    ></calendar-side-panel-filters-list>

    <calendar-side-panel-project-owners
      v-if="canDisplayFilterList('owner-filters')"
      filter-label="Project owners"
      :filters-list="projectOwnerFiltersList"
      :expand-list="isListExpanded"
    ></calendar-side-panel-project-owners>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CalendarSidePanelFiltersList from '@views/calendar/calendar-side-panel/calendar-side-panel-filters/calendar-side-panel-filters-list'
import CalendarSidePanelFiltersSearch from '@views/calendar/calendar-side-panel/calendar-side-panel-filters/components/calendar-side-panel-filters-search'
import { size, isNil, forEach, orderBy, filter } from 'lodash'
import ApiCustomIncludes from '@configs/api-custom-includes'
import CalendarSidePanelSavedFilters from '@views/calendar/calendar-side-panel/calendar-side-panel-filters/components/calendar-side-panel-saved-filters'
import CalendarSidePanelProjectOwners from '@views/calendar/calendar-side-panel/calendar-side-panel-filters/components/calendar-side-panel-project-owners'

export default {
  components: {
    CalendarSidePanelFiltersList,
    CalendarSidePanelFiltersSearch,
    CalendarSidePanelSavedFilters,
    CalendarSidePanelProjectOwners,
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      searchFiltersResult: 'calendar/searchFiltersResult',
      filtersListOrdered: 'calendar/filtersListOrdered',
      teamsList: 'user/teamsList',
      internalUsersList: 'user/internalUsersList',
      projectOwners: 'user/projectOwners',
    }),

    isListExpanded() {
      return !isNil(this.searchFiltersResult)
    },

    eventFiltersList() {
      return !isNil(this.searchFiltersResult)
        ? this.searchFiltersResult.eventFilters
        : this.filtersListOrdered('eventFilters', true)
    },

    teamFiltersList() {
      return !isNil(this.searchFiltersResult)
        ? this.searchFiltersResult.teamFilters
        : this.filtersListOrdered('teamFilters', true)
    },

    crewFiltersList() {
      const crewList = !isNil(this.searchFiltersResult)
        ? this.searchFiltersResult.crewFilters
        : this.filtersListOrdered('crewFilters', false)

      // Hide inactive users from displaying in the crew list
      return filter(crewList, (crew) => {
        return crew.status !== 'Inactive'
      })
    },

    savedFiltersList() {
      return !isNil(this.searchFiltersResult)
        ? this.searchFiltersResult.savedFilters
        : this.filtersListOrdered('savedFilters', false)
    },

    projectOwnerFiltersList() {
      return !isNil(this.searchFiltersResult)
        ? this.searchFiltersResult.projectOwnerFilters
        : this.filtersListOrdered('projectOwnerFilters', false)
    },
  },

  watch: {
    teamsList() {
      const teamFiltersData = []

      forEach(this.teamsList, (team) => {
        const mainFilterData = {
          label: team.name,
          id: team.id,
          value: team.id,
          subFilters: [],
        }

        const subFilters = []

        forEach(team.members, (member) => {
          subFilters.push({
            label: member.full_name,
            id: member.id,
            value: member.id,
          })
        })

        mainFilterData.subFilters = orderBy(subFilters, 'label', 'asc')

        teamFiltersData.push(mainFilterData)
      })

      this.setTeamFilters(orderBy(teamFiltersData, 'label', 'asc'))
    },

    internalUsersList() {
      const crewFiltersData = []

      forEach(this.internalUsersList, (user) => {
        crewFiltersData.push({
          label: user.full_name,
          id: user.id,
          value: user.id,
          status: user.status,
        })
      })

      this.setCrewFilters(orderBy(crewFiltersData, 'label', 'asc'))
    },

    projectOwners() {
      const projectOwnerFiltersData = []

      forEach(this.projectOwners, (user) => {
        projectOwnerFiltersData.push({
          label: user.full_name,
          id: user.id,
          value: user.id,
          is_project_owner: true,
        })
      })

      this.setProjectOwnerFilters(
        orderBy(projectOwnerFiltersData, 'label', 'asc')
      )
    },
  },

  mounted() {
    this.initFiltersList()
  },

  methods: {
    ...mapActions({
      getTeams: 'user/getTeams',
      setTeamFilters: 'calendar/setTeamFilters',
      getInternalUsers: 'user/getInternalUsers',
      setCrewFilters: 'calendar/setCrewFilters',
      setProjectOwnerFilters: 'calendar/setProjectOwnerFilters',
      getEventTypesFilterList: 'calendar/getEventTypesFilterList',
      getSavedFilters: 'calendar/getSavedFilters',
      getProjectOwners: 'user/getProjectOwners',
    }),

    initFiltersList() {
      this.getTeams({
        include: ApiCustomIncludes.teams,
      })

      this.getInternalUsers({
        includes: ApiCustomIncludes.eventsUser,
      })

      this.getProjectOwners()
      this.getEventTypesFilterList()
      this.getSavedFilters()
    },

    canDisplayFilterList(type) {
      if (type === 'saved-filters') {
        return size(this.savedFiltersList) > 0
      } else if (type === 'team-filters') {
        return size(this.teamFiltersList) > 0
      } else if (type === 'event-filters') {
        return size(this.eventFiltersList) > 0
      } else if (type === 'crew-filters') {
        return size(this.crewFiltersList) > 0
      } else if (type === 'owner-filters') {
        return size(this.projectOwnerFiltersList) > 0
      } else {
        return false
      }
    },
  },
}
</script>
