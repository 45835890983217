<template>
  <div class="">
    <div class="vd-blue vd-margin-bottom-10">
      Notes related to: <strong>{{ projectName }}</strong>
    </div>
    <div
      :class="[
        'vd-display-flex vd-margin-bottom-medium',
        !editMode ? 'vd-align-center' : 'vd-align-top',
      ]"
    >
      <user-profile
        :user="vmsUser"
        custom-image-class="vms-eod__avatar"
        custom-avatar-class="vms-eod__avatar"
        :custom-avatar-font-size="30"
      ></user-profile>
      <div
        v-if="!editMode"
        class="vd-display-inline-block vd-border-light-grey vd-border-width-1 vd-full-width vd-margin-left-10 vd-padding-inline-small vd-padding-block-extra-small vd-dark-grey"
        @click="showCommentField"
      >
        Add a response
      </div>
      <div
        v-else
        class="vd-display-inline-block vd-full-width vd-margin-left-10"
      >
        <text-editor
          label="New note"
          :content="newNote"
          custom-label-class="vd-text-small vd-margin-bottom-0 vd-darker-grey"
          @update:text-editor-content="handleNewNoteUpdated"
        ></text-editor>
        <div class="vd-text-align-right vd-margin-top-extra-small">
          <button class="vd-btn-small" @click.prevent="hideEditMode">
            Cancel
          </button>
          <button
            class="vd-btn-small vd-btn-blue"
            @click.prevent="handleSaveNewComment"
          >
            Save
          </button>
        </div>
      </div>
    </div>
    <div v-if="hasNotes">
      <eod-notes-comment
        v-for="(note, index) in projectEodNotes"
        :key="index"
        :note="note"
        :project-id="projectId"
      ></eod-notes-comment>
    </div>
    <empty-notes
      v-if="showEmptyNotes"
      image-src="/images/empty-states/empty-eod-notes.svg"
      description="Be the first to leave a note about this project"
      image-custom-class="vd-padding-20"
    ></empty-notes>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { size } from 'lodash'
import UserProfile from '@components/user-profile/user-profile'
import EodNotesComment from '@components/eod-notes/eod-notes-comment'
import TextEditor from '@components/text-editor/text-editor'
import EmptyNotes from '@components/empty-states/project/project-tab-empty-state'

export default {
  components: {
    UserProfile,
    EodNotesComment,
    TextEditor,
    EmptyNotes,
  },

  props: {
    projectId: {
      type: Number,
      required: true,
      default: 0,
    },

    projectName: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      editMode: false,
      newNote: '',
    }
  },

  computed: {
    ...mapGetters({
      vmsUser: 'auth/getVmsUserData',
      projectEodNotes: 'project/projectEodNotes',
    }),

    hasNotes() {
      return size(this.projectEodNotes) > 0
    },

    showEmptyNotes() {
      return !this.hasNotes && !this.editMode
    },
  },

  methods: {
    ...mapActions({
      createProjectEodNotes: 'project/createProjectEodNotes',
    }),

    hideEditMode() {
      this.editMode = false
    },

    showCommentField() {
      this.editMode = true
    },

    handleNewNoteUpdated(note) {
      this.newNote = note
    },

    handleSaveNewComment() {
      const payload = {
        projectId: this.projectId,
        comment: this.newNote,
        include: ['created_by'],
      }

      this.createProjectEodNotes(payload)
      this.editMode = false
      this.newNote = ''
    },
  },
}
</script>
