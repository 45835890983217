var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vd-margin-bottom-medium"},[_c('div',{staticClass:"vms-video__filters--list-label vd-h4 vd-margin-bottom-small vd-cursor-pointer",attrs:{"id":"toggleFiltersListDiv"},on:{"click":_vm.toggleFilterListExpand}},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-10"},[_c('div',{staticClass:"vd-h4"},[_c('strong',[_vm._v(_vm._s(_vm.filterLabel))])])]),_c('div',{staticClass:"col-2 vd-text-align-right"},[_c('i',{class:[
            'vd-icon vd-hover-darker-grey vd-text-small',
            _vm.isListExpanded ? 'icon-minus' : 'icon-plus' ]})])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isListExpanded),expression:"isListExpanded"}]},_vm._l((_vm.filtersList),function(filter,index){return _c('div',{key:(index + "-" + (filter.id)),staticClass:"vd-margin-bottom-medium"},[_c('div',[_c('div',{staticClass:"vd-display-flex vd-align-center vd-margin-bottom-small"},[_c('div',{staticClass:"row no-gutters"},[_c('calendar-side-panel-filters-checkbox',{class:[
                'vd-display-flex vd-align-center',
                _vm.hasSubFilters(filter.subFilters) ? 'col-10' : 'col-12' ],attrs:{"filter":filter,"label-custom-class":_vm.hasSubFilters(filter.subFilters)
                  ? 'vms-calendar__label--main'
                  : '',"checkbox-custom-class":_vm.hasSubFilters(filter.subFilters)
                  ? ' vms-calendar__checkbox'
                  : '',"is-main-filter-selected":_vm.isMainFilterSelected(filter)},on:{"handle:filter-checked":_vm.handleMainFilterSelected}}),(_vm.hasSubFilters(filter.subFilters))?_c('div',{staticClass:"col-2 vd-text-align-right"},[_c('i',{class:[
                  'vd-icon vd-hover-darker-grey vd-text-small',
                  _vm.canShowSubFilters(filter) ? 'icon-minus' : 'icon-plus' ],on:{"click":function($event){return _vm.handleMainFilterExpandClick(filter)}}})]):_vm._e()],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.canShowSubFilters(filter)),expression:"canShowSubFilters(filter)"}],staticClass:"vd-margin-left-medium"},_vm._l((filter.subFilters),function(subFilter,subFilterIndex){return _c('div',{key:(subFilterIndex + "-" + (subFilter.id) + "}"),staticClass:"vd-margin-bottom-medium"},[(!_vm.isEventsListLoading)?_c('calendar-side-panel-filters-checkbox',{attrs:{"filter":subFilter,"checkbox-custom-class":_vm.hasSubFilters(filter.subFilters)
                  ? ' vms-calendar__checkbox'
                  : '',"is-sub-filter-selected":_vm.isSubFilterSelected(subFilter)},on:{"handle:filter-checked":_vm.handleSubFilterSelected}}):_vm._e()],1)}),0)])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }