<template>
  <div
    :class="[
      'vd-darker-grey vd-margin-bottom-medium',
      !isMobileScreen
        ? 'vd-padding-block-small vd-border-lighter-grey vd-border-radius-6 vd-border-width-2'
        : '',
    ]"
  >
    <v-app class="vms-event-modal-fields">
      <events-form-fields-project
        :project-id="projectId"
        :disabled="isEventFormLoading"
        v-on="$listeners"
      ></events-form-fields-project>

      <div class="row no-gutters">
        <div class="col-md-6">
          <events-form-fields-event-type
            :event-types-list="placeholderEventTypes"
            :event-type="eventType"
            :disabled="isEventFormLoading"
            v-on="$listeners"
          ></events-form-fields-event-type>
        </div>
        <div class="col-md-6">
          <events-form-fields-event-name
            :event-name="eventName"
            :disabled="isEventFormLoading"
            v-on="$listeners"
          ></events-form-fields-event-name>
        </div>
      </div>

      <events-form-fields-address
        :street="street"
        :state="state"
        :disabled="isEventFormLoading"
        v-on="$listeners"
      ></events-form-fields-address>
    </v-app>
  </div>
</template>

<script>
import EventsFormFieldsProject from '@components/modal/events/components/events-form-fields/events-form-fields-project'
import EventsFormFieldsEventName from '@components/modal/events/components/events-form-fields/events-form-fields-event-name'
import EventsFormFieldsAddress from '@components/modal/events/components/events-form-fields/events-form-fields-address'
import EventsFormFieldsEventType from '@components/modal/events/components/events-form-fields/events-form-fields-event-type'
import VmsConstants from '@configs/vms-constants'
import { isEmpty, filter, includes } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  components: {
    EventsFormFieldsProject,
    EventsFormFieldsEventName,
    EventsFormFieldsAddress,
    EventsFormFieldsEventType,
  },

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    projectId: {
      type: Number,
      required: false,
      default: null,
    },
    eventName: {
      type: String,
      required: false,
      default: '',
    },
    eventType: {
      type: Number,
      required: false,
      default: null,
    },
    street: {
      type: String,
      required: false,
      default: '',
    },
    state: {
      type: Number,
      required: false,
      default: null,
    },
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      eventTypesList: 'calendar/eventTypesList',
      isEventFormLoading: 'calendar/isEventFormLoading',
    }),

    placeholderEventTypes() {
      if (isEmpty(this.eventTypesList)) {
        return []
      }

      const eventTypes = filter(this.eventTypesList, (type) => {
        return !includes(
          [
            VmsConstants.eventTypes.EVENT_TYPE_ID_GOOGLE_CALENDAR_EVENT,
            VmsConstants.eventTypes.EVENT_TYPE_ID_BAMBOO_EVENT,
          ],
          type.id
        )
      })

      return eventTypes
    },
  },
}
</script>
