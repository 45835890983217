var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 vd-text-align-left"},[_c('div',{class:[
        'vd-field vd-margin-bottom-20',
        _vm.locationEdited !== '' ? 'vd-input-valid' : '' ]},[_c('label',[_vm._v("Location edited")]),_c('model-list-select',{attrs:{"list":_vm.locationEditedList,"option-value":"id","option-text":"name","placeholder":"Select the edit location"},model:{value:(_vm.locationEdited),callback:function ($$v) {_vm.locationEdited=$$v},expression:"locationEdited"}})],1),_c('div',{class:[
        'vd-field vd-margin-bottom-20',
        _vm.workingFilesLocation !== '' ? 'vd-input-valid' : '' ]},[_c('label',[_vm._v("Where are the working files most up to date?")]),_c('model-list-select',{attrs:{"list":_vm.workingFilesLocationList,"option-value":"id","option-text":"name","placeholder":"Select where the files located"},model:{value:(_vm.workingFilesLocation),callback:function ($$v) {_vm.workingFilesLocation=$$v},expression:"workingFilesLocation"}})],1),_c('div',{class:[
        'vd-field vd-margin-bottom-20',
        _vm.isEditCompleted !== '' ? 'vd-input-valid' : '' ]},[_c('label',[_vm._v("Is edit completed?")]),_c('model-list-select',{attrs:{"list":_vm.isEditCompletedList,"option-value":"id","option-text":"name","placeholder":"Select the answer"},model:{value:(_vm.isEditCompleted),callback:function ($$v) {_vm.isEditCompleted=$$v},expression:"isEditCompleted"}})],1),_c('div',{class:[
        'vd-field vd-margin-bottom-20',
        _vm.colorProfile !== '' ? 'vd-input-valid' : '' ]},[_c('label',[_vm._v("Color profile")]),_c('model-list-select',{attrs:{"list":_vm.colorProfileList,"option-value":"id","option-text":"name","placeholder":"Select the suitable color profile"},model:{value:(_vm.colorProfile),callback:function ($$v) {_vm.colorProfile=$$v},expression:"colorProfile"}})],1),_c('div',{class:[
        'vd-field vd-margin-bottom-20',
        _vm.isFootageForStock !== '' ? 'vd-input-valid' : '' ]},[_c('label',[_vm._v("Footage suitable for stock?")]),_c('model-list-select',{attrs:{"list":_vm.footageStockList,"option-value":"id","option-text":"name","placeholder":"Is any footage useful in another edit?"},model:{value:(_vm.isFootageForStock),callback:function ($$v) {_vm.isFootageForStock=$$v},expression:"isFootageForStock"}})],1),_c('div',{staticClass:"vd-text-align-right vd-margin-bottom-20"},[_c('button',{staticClass:"vd-btn-small vd-btn-blue",attrs:{"disabled":_vm.eodNotesSaveBtnLoading},on:{"click":function($event){$event.preventDefault();return _vm.handleSaveFilmingForm($event)}}},[_vm._v(" "+_vm._s(_vm.eodNotesSaveBtnLoading ? 'Saving...' : 'Save')+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }