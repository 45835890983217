<template>
  <div class="row">
    <div class="col-12 vd-text-align-left">
      <div
        :class="[
          'vd-field vd-margin-bottom-20',
          locationEdited !== '' ? 'vd-input-valid' : '',
        ]"
      >
        <label>Location edited</label>
        <model-list-select
          v-model="locationEdited"
          :list="locationEditedList"
          option-value="id"
          option-text="name"
          placeholder="Select the edit location"
        ></model-list-select>
      </div>
      <div
        :class="[
          'vd-field vd-margin-bottom-20',
          workingFilesLocation !== '' ? 'vd-input-valid' : '',
        ]"
      >
        <label>Where are the working files most up to date?</label>
        <model-list-select
          v-model="workingFilesLocation"
          :list="workingFilesLocationList"
          option-value="id"
          option-text="name"
          placeholder="Select where the files located"
        ></model-list-select>
      </div>
      <div
        :class="[
          'vd-field vd-margin-bottom-20',
          isEditCompleted !== '' ? 'vd-input-valid' : '',
        ]"
      >
        <label>Is edit completed?</label>
        <model-list-select
          v-model="isEditCompleted"
          :list="isEditCompletedList"
          option-value="id"
          option-text="name"
          placeholder="Select the answer"
        ></model-list-select>
      </div>
      <div
        :class="[
          'vd-field vd-margin-bottom-20',
          colorProfile !== '' ? 'vd-input-valid' : '',
        ]"
      >
        <label>Color profile</label>
        <model-list-select
          v-model="colorProfile"
          :list="colorProfileList"
          option-value="id"
          option-text="name"
          placeholder="Select the suitable color profile"
        ></model-list-select>
      </div>
      <div
        :class="[
          'vd-field vd-margin-bottom-20',
          isFootageForStock !== '' ? 'vd-input-valid' : '',
        ]"
      >
        <label>Footage suitable for stock?</label>
        <model-list-select
          v-model="isFootageForStock"
          :list="footageStockList"
          option-value="id"
          option-text="name"
          placeholder="Is any footage useful in another edit?"
        ></model-list-select>
      </div>

      <div class="vd-text-align-right vd-margin-bottom-20">
        <button
          :disabled="eodNotesSaveBtnLoading"
          class="vd-btn-small vd-btn-blue"
          @click.prevent="handleSaveFilmingForm"
        >
          {{ eodNotesSaveBtnLoading ? 'Saving...' : 'Save' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ModelListSelect } from 'vue-search-select'
import { isEmpty, cloneDeep } from 'lodash'

export default {
  components: {
    ModelListSelect,
  },

  props: {
    projectId: {
      type: Number,
      required: true,
      default: 0,
    },

    jobId: {
      type: Number,
      required: true,
      default: 0,
    },

    additionalData: {
      type: [Array, Object],
      required: true,
      default: () => {
        return {}
      },
    },

    colorProfileList: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },

    footageStockList: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },
  },

  data() {
    return {
      locationEdited: '',
      workingFilesLocation: '',
      isEditCompleted: '',
      colorProfile: '',
      isFootageForStock: '',
    }
  },

  computed: {
    ...mapGetters({
      eodNotesSaveBtnLoading: 'project/eodNotesSaveBtnLoading',
    }),

    locationEditedList() {
      return [
        { id: 'melbourne_office', name: 'Melbourne office' },
        { id: 'sydney_office', name: 'Sydney office' },
        { id: 'remote', name: 'Remote' },
      ]
    },

    workingFilesLocationList() {
      return [
        { id: 'media_server', name: 'Media server' },
        { id: 'cloud', name: 'Cloud (OneDrive, Dropbox, etc)' },
        { id: 'remote', name: 'Remote' },
      ]
    },

    isEditCompletedList() {
      return [
        { id: 'no', name: 'No' },
        { id: 'yes', name: 'Yes' },
      ]
    },
  },

  mounted() {
    if (!isEmpty(this.additionalData)) {
      this.locationEdited = cloneDeep(this.additionalData.location_edited)
      this.workingFilesLocation = cloneDeep(
        this.additionalData.working_files_most_up_to_date
      )
      this.isEditCompleted = cloneDeep(this.additionalData.is_edit_completed)
      this.colorProfile = cloneDeep(this.additionalData.color_profile)
      this.isFootageForStock = cloneDeep(
        this.additionalData.is_footage_for_stock
      )
    }
  },

  methods: {
    ...mapActions({
      updateJobAdditionalData: 'project/updateJobAdditionalData',
    }),

    handleSaveFilmingForm() {
      let additionalData = {
        location_edited: this.locationEdited,
        working_files_most_up_to_date: this.workingFilesLocation,
        is_edit_completed: this.isEditCompleted,
        color_profile: this.colorProfile,
        is_footage_for_stock: this.isFootageForStock,
      }

      let payload = {
        projectId: this.projectId,
        jobId: this.jobId,
        additional_data: additionalData,
        include: ['created_by'],
      }

      this.updateJobAdditionalData(payload)
    },
  },
}
</script>
