<template>
  <events-modal
    :calendar-color="eventColor"
    :is-save-disabled="$v.$invalid"
    :is-loading="isEventFormLoading"
    :has-changes="$v.$dirty"
    :title="title"
    @click:backdrop="closeModal"
    @click:cancel="closeModal"
    @click:save="saveEventDetails"
  >
    <events-header-actions
      :show-actions-dropdown="false"
      :can-show-put-on-hold-btn="false"
    ></events-header-actions>

    <hr v-if="isMobileScreen" class="vd-flex-auto vd-margin-inline-40" />

    <div
      :class="[
        'vms-event-modal-content',
        isMobileScreen ? 'vms-event-modal-content--mobile vd-display-flex' : '',
      ]"
    >
      <transition name="slide" mode="out-in">
        <template v-if="!isEditMode">
          <div>
            <div
              v-if="!isExternalEvent"
              :class="[
                isMobileScreen
                  ? 'vd-margin-bottom-medium vd-text-align-right'
                  : 'vd-position-relative',
              ]"
            >
              <button
                :class="[
                  !isMobileScreen
                    ? 'vms-event-modal-content__btn-edit vd-position-absolute'
                    : '',
                ]"
                @click="isEditMode = true"
              >
                <span
                  v-if="isMobileScreen"
                  class="vd-border-dark-grey vd-border-width-0 vd-border-bottom-width-1"
                  >Edit</span
                >
                <i
                  v-else
                  class="vd-icon icon-pencil vd-icon-circle vd-background-white vd-hover-background-lightest-grey"
                ></i>
              </button>
            </div>

            <events-placeholders-info
              v-if="!isExternalEvent"
              :project-name="projectName"
              :location="location"
              :event-type="eventName"
              :project-id="projectId"
              :is-placeholder-event-type="isPlaceholderEventType"
            ></events-placeholders-info>
          </div>
        </template>

        <events-placeholders-fields
          v-else
          :project-id.sync="projectId"
          :event-name.sync="eventName"
          :event-type.sync="eventType"
          :street.sync="street"
          :state.sync="state"
          :disabled="isEventFormLoading"
        ></events-placeholders-fields>
      </transition>

      <hr
        v-if="isMobileScreen"
        class="vd-flex-auto vd-margin-top-large vd-margin-inline-40"
      />

      <events-form-fields
        :assign-to-user.sync="assignTo"
        :assign-to-freelancer.sync="freelancer"
        :start-date.sync="startDate"
        :start-time.sync="startTime"
        :end-date.sync="endDate"
        :end-time.sync="endTime"
        :general-comments.sync="generalComments"
        :is-all-day-event.sync="isAllDayEvent"
        :has-general-comments="true"
        :has-all-day-option="true"
        :disabled="isFormDisabled"
        @update:isAllDayEvent="handleUpdateIsAllDayEvent"
      ></events-form-fields>
    </div>
  </events-modal>
</template>

<script>
import EventsModal from '@components/modal/events/events-modal'
import EventsPlaceholdersFields from '@components/modal/events/placeholders/components/events-placeholders-fields'
import EventsPlaceholdersInfo from '@components/modal/events/placeholders/components/events-placeholders-info'
import EventsFormFields from '@components/modal/events/components/events-form-fields/events-form-fields'
import EventsHeaderActions from '@components/modal/events/components/events-header-actions/events-header-actions'
import FormMixin from '@mixins/forms-mixin'
import FiltersMixin from '@mixins/filters-mixin'
import Colours from '@configs/colours'
import momentTz from 'moment-timezone'
import VmsConstants from '@configs/vms-constants'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import { includes } from 'lodash'
import ApiCustomIncludes from '@configs/api-custom-includes'

export default {
  components: {
    EventsModal,
    EventsPlaceholdersFields,
    EventsPlaceholdersInfo,
    EventsFormFields,
    EventsHeaderActions,
  },

  mixins: [FormMixin, FiltersMixin],

  inject: ['eventsListIncludes'],

  props: {
    placeholderSelectedDates: {
      type: Object,
      required: true,
    },
  },

  validations: {
    eventName: {
      required,
    },
    eventType: {
      required,
    },
    startDate: {
      required,
    },
    endDate: {
      required: requiredIf(function () {
        return this.isAllDayEvent
      }),
    },
    startTime: {
      required: requiredIf(function () {
        return !this.isAllDayEvent
      }),
    },
    endTime: {
      required: requiredIf(function () {
        return !this.isAllDayEvent
      }),
    },
    projectId: {},
    isAllDayEvent: {},
    assignTo: {},
    generalComments: {},
    street: {},
    state: {},
  },

  data() {
    return {
      title: '',
      isEditMode: false,
      projectId: null,
      eventName: '',
      eventType: null,
      street: '',
      state: null,
      assignTo: null,
      freelancer: null,
      startDate: null,
      endDate: null,
      startTime: '',
      endTime: '',
      generalComments: '',
      isAllDayEvent: false,
      eventColor: Colours.eventColours.unassigned,
      office: '',
      projectName: '',
      jobName: '',
      location: '',
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      selectedEvent: 'calendar/selectedEvent',
      isEventFormLoading: 'calendar/isEventFormLoading',
    }),

    payload() {
      return {
        project_id: this.projectId,
        name: this.eventName,
        event_type_id: this.eventType,
        description: this.generalComments,
        user_id: this.assignTo?.id || null,
        freelancer: this.freelancer || null,
        from: this.placeholderSelectedDates.from,
        to: this.placeholderSelectedDates.to,
        state_id: this.state || null,
        address: this.street || null,
        is_all_day: this.isAllDayEvent,
      }
    },

    isExternalEvent() {
      return includes(
        [
          VmsConstants.eventTypes.EVENT_TYPE_ID_GOOGLE_CALENDAR_EVENT,
          VmsConstants.eventTypes.EVENT_TYPE_ID_BAMBOO_EVENT,
        ],
        this.selectedEvent?.event_type_id
      )
    },

    isBambooEvent() {
      return (
        this.selectedEvent?.event_type_id ===
        VmsConstants.eventTypes.EVENT_TYPE_ID_BAMBOO_EVENT
      )
    },

    isFormDisabled() {
      return this.isEventFormLoading || this.isExternalEvent
    },

    isPlaceholderEventType() {
      const event = this.selectedEvent
      return !event.order_job_element_id && !this.isExternalEvent
    },
  },

  watch: {
    payload() {
      this.$v.$touch()
    },

    startDate() {
      if (
        momentTz(this.placeholderSelectedDates.from).format(
          'YYYY-MM-DD HH:mm:ss'
        ) !== this.startDate
      ) {
        this.handleSelectedDatesUpdate()
      }
    },

    endDate() {
      if (
        momentTz(this.placeholderSelectedDates.to).format(
          'YYYY-MM-DD HH:mm:ss'
        ) !== this.endDate
      ) {
        this.handleSelectedDatesUpdate()
      }
    },

    startTime() {
      if (
        momentTz(this.placeholderSelectedDates.from).format('HH:mm:ss') !==
        this.startTime
      ) {
        this.handleSelectedDatesUpdate()
      }
    },

    endTime() {
      if (
        momentTz(this.placeholderSelectedDates.to).format('HH:mm:ss') !==
        this.endTime
      ) {
        this.handleSelectedDatesUpdate()
      }
    },

    isAllDayEvent() {
      this.endTime = null
    },

    selectedEvent() {
      this.setInitialData()
    },
  },

  mounted() {
    this.setInitialData()
  },

  beforeDestroy() {
    this.closeModal()
  },

  methods: {
    ...mapActions({
      updateEventDetails: 'calendar/updateEventDetails',
      setSelectedEvent: 'calendar/setSelectedEvent',
      setShowEventsModal: 'calendar/setShowEventsModal',
      setShowEventsPlaceholdersEditModal:
        'calendar/setShowEventsPlaceholdersEditModal',
    }),

    setInitialData() {
      if (this.isBambooEvent) {
        this.title = 'Bamboo Leave'
      } else {
        this.title = `${this.selectedEvent.event_type?.name} - ${this.selectedEvent.name}`

        if (!this.isExternalEvent) {
          this.title += ' (Placeholder)'
        }
      }

      this.jobName = this.selectedEvent.name
      this.office = this.selectedEvent.office?.name
      this.projectId = this.selectedEvent.meta_data?.project_id
      this.projectName = this.selectedEvent.meta_data?.project_name
      this.street = this.selectedEvent.address
      this.state = this.selectedEvent.state?.id
      this.eventName = this.selectedEvent.name
      this.eventType = this.selectedEvent.event_type?.id

      if (this.selectedEvent.address || this.selectedEvent.state?.name) {
        this.location = `${this.selectedEvent.address} ${this.selectedEvent.state?.name}`
      }

      if (this.selectedEvent.meta_data?.freelancer) {
        this.freelancer = this.selectedEvent.meta_data.freelancer
        this.eventColor = Colours.eventColours.freelancer
      } else if (this.selectedEvent.user?.calendar_color) {
        this.assignTo = this.selectedEvent.user
        this.eventColor = this.selectedEvent.user.calendar_color
      }

      this.startDate = this.placeholderSelectedDates.from
      this.endDate = this.placeholderSelectedDates.endDateDisplay
      this.startTime = momentTz(this.placeholderSelectedDates.from).format(
        'HH:mm:ss'
      )
      this.endTime = momentTz(this.placeholderSelectedDates.to).format(
        'HH:mm:ss'
      )

      this.isAllDayEvent = !!this.selectedEvent.is_all_day
      this.generalComments = this.selectedEvent.description
    },

    handleSelectedDatesUpdate() {
      this.$emit('handle:selected-dates-update', {
        startDate: this.startDate,
        endDate: this.endDate,
        startTime: this.startTime,
        endTime: this.endTime,
        isAllDayEvent: this.isAllDayEvent,
      })
    },

    closeModal() {
      this.$emit('handle:close-placeholder-modal')
    },

    saveEventDetails() {
      this.updateEventDetails({
        includes: ApiCustomIncludes[this.eventsListIncludes],
        eventId: this.selectedEvent.id,
        payload: this.payload,
      })

      this.$emit('handle:save-placeholder-event')
    },

    handleUpdateIsAllDayEvent(isAllDayEvent) {
      if (isAllDayEvent) {
        // Set the end date to be the same with the start date when changing the all day
        this.endDate = this.startDate
        this.isAllDayEvent = true
      }
    },
  },
}
</script>

<style lang="scss">
@import '@styles/plugins/vuetify';
</style>
