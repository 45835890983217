<template>
  <events-modal
    :calendar-color="eventColor"
    :is-save-disabled="$v.$invalid"
    :is-loading="isEventFormLoading"
    :has-changes="$v.$dirty"
    title="New pre-production event"
    @click:cancel="closeModal"
    @click:save="saveEventDetails"
  >
    <div
      :class="[
        'vd-darker-grey vd-margin-bottom-medium',
        !isMobileScreen
          ? 'vd-padding-block-small vd-border-lighter-grey vd-border-radius-6 vd-border-width-2'
          : '',
      ]"
    >
      <v-app class="vms-event-modal-fields">
        <events-form-fields-project
          :project-id.sync="projectId"
          :required="true"
          :disabled="isEventFormLoading"
        ></events-form-fields-project>

        <div class="row no-gutters">
          <div class="col-md-6">
            <events-form-fields-event-type
              :event-types-list="preProductionTypesList"
              :event-type.sync="eventType"
              :disabled="isEventFormLoading"
            ></events-form-fields-event-type>
          </div>
          <div class="col-md-6">
            <events-form-fields-event-name
              :event-name.sync="eventName"
              :disabled="isEventFormLoading"
            ></events-form-fields-event-name>
          </div>
        </div>
      </v-app>
    </div>

    <events-form-fields
      :assign-to-user.sync="assignTo"
      :assign-to-freelancer.sync="freelancer"
      :start-date.sync="startDate"
      :start-time.sync="startTime"
      :end-time.sync="endTime"
      :duration.sync="duration"
      :general-comments.sync="generalComments"
      :has-general-comments="true"
      :disabled="isEventFormLoading"
    ></events-form-fields>
  </events-modal>
</template>

<script>
import EventsModal from '@components/modal/events/events-modal'
import EventsFormFields from '@components/modal/events/components/events-form-fields/events-form-fields'
import EventsFormFieldsProject from '@components/modal/events/components/events-form-fields/events-form-fields-project'
import EventsFormFieldsEventName from '@components/modal/events/components/events-form-fields/events-form-fields-event-name'
import EventsFormFieldsEventType from '@components/modal/events/components/events-form-fields/events-form-fields-event-type'
import FormMixin from '@mixins/forms-mixin'
import { map, find } from 'lodash'
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import ApiCustomIncludes from '@configs/api-custom-includes'

export default {
  components: {
    EventsModal,
    EventsFormFields,
    EventsFormFieldsProject,
    EventsFormFieldsEventName,
    EventsFormFieldsEventType,
  },

  mixins: [FormMixin],

  inject: ['eventsListIncludes'],

  validations: {
    projectId: {
      required,
    },
    eventName: {
      required,
    },
    eventType: {
      required,
    },
    startDate: {
      required,
    },
    startTime: {
      required,
    },
    endTime: {
      required,
    },
    assignTo: {},
    generalComments: {},
  },

  data() {
    return {
      projectId: null,
      eventType: null,
      eventName: '',
      assignTo: null,
      freelancer: null,
      startDate: null,
      startTime: '',
      endTime: '',
      duration: 0,
      generalComments: '',
      eventColor: '',
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      selectedSchedules: 'calendar/selectedSchedules',
      isEventFormLoading: 'calendar/isEventFormLoading',
      preProductionTypes: 'project/pre-production/preProductionTypes',
    }),

    preProductionTypesList() {
      return map(this.preProductionTypes, (type) => {
        return {
          id: type.id,
          name: type.product_element_type.name,
        }
      })
    },

    productId() {
      return find(this.preProductionTypes, { id: this.eventType })?.product?.id
    },

    payload() {
      return {
        project_id: this.projectId,
        product_element_id: this.eventType,
        product_id: this.productId,
        name: this.eventName,
        external_note: this.generalComments,
        event_assign_to: this.assignTo?.id || null,
        event_freelancer: this.freelancer || null,
        production_date: this.startDate,
        production_time: this.startTime,
        duration: this.duration,
      }
    },
  },

  watch: {
    payload() {
      this.$v.$touch()
    },

    projectId(projectId) {
      if (projectId) {
        this.updatePreProductionTypesList({
          projectId: projectId,
          include: ['product_element_type'],
        })
      }
    },
  },

  mounted() {
    this.setInitialData()
  },

  beforeDestroy() {
    this.closeModal()
  },

  methods: {
    ...mapActions({
      updatePreProductionTypesList:
        'project/pre-production/updatePreProductionTypesList',
      createNewPreProductionEvent: 'calendar/createNewPreProductionEvent',
      setSelectedSchedules: 'calendar/setSelectedSchedules',
      setShowEventsModal: 'calendar/setShowEventsModal',
      setShowEventsPreProductionsCreateModal:
        'calendar/setShowEventsPreProductionsCreateModal',
    }),

    setInitialData() {
      this.startDate = this.selectedSchedules?.startDate
      this.startTime = this.selectedSchedules?.startTime
      this.endTime = this.selectedSchedules?.endTime
      this.freelancer = this.selectedSchedules?.freelancer
      this.assignTo = this.selectedSchedules?.assignTo
      this.projectId = this.selectedSchedules?.projectId
    },

    closeModal() {
      this.setShowEventsPreProductionsCreateModal(false)
      this.setShowEventsModal(false)
      this.setSelectedSchedules(null)
    },

    saveEventDetails() {
      this.createNewPreProductionEvent({
        projectId: this.projectId,
        includes: ApiCustomIncludes[this.eventsListIncludes],
        name: this.eventName,
        payload: this.payload,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@styles/plugins/vuetify';
</style>
