var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vd-padding-medium"},[_c('v-autocomplete',{class:[
      'vd-field',
      _vm.required ? 'vd-required' : '',
      _vm.projectId ? 'vd-input-valid' : '' ],attrs:{"value":_vm.projectId,"items":_vm.projectsList,"hide-selected":true,"loading":_vm.isProjectsListLoading,"disabled":_vm.disabled,"menu-props":_vm.menuProps,"filter":_vm.customFilter,"placeholder":_vm.placeholder,"no-data-text":_vm.noDataText,"item-text":"name","item-value":"id","label":"Project","clearable":"","cache-items":"","persistent-placeholder":""},on:{"change":_vm.handleProjectChange,"update:search-input":function($event){return _vm.handleSearchProject($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"vd-light-blue vd-margin-right-small"},[_vm._v(" #"+_vm._s(item.id)+" ")]),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"vd-light-blue vd-margin-right-small"},[_vm._v(" #"+_vm._s(item.id)+" ")]),_vm._v(" "+_vm._s(item.name)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }