<template>
  <div v-click-outside="closeSettingsDropdown">
    <div
      id="toggleSettingsDropdownDiv"
      class="vd-padding-block-extra-small vd-padding-inline-small vd-border-radius-4 vd-border-width-1 vd-background-white vd-border-grey vd-display-flex vd-cursor-pointer vd-hover-background-lighter-grey"
      @click="toggleSettingsDropdown"
    >
      <div class="vd-margin-right-small">Settings</div>
      <i
        class="vd-icon icon-chevron-down vd-hover-darker-grey vd-text-small vd-align-middle"
      ></i>
    </div>

    <div
      v-show="canDisplaySettingsDropdown"
      class="vms-dropdown vms-calendar__settings--dropdown"
    >
      <div class="vd-box-shadow vd-background-white vd-border-radius-6">
        <div
          v-if="!loggedInUser.has_google_calendar_integration"
          class="vms-dropdown__list vd-black vd-padding-small"
          @click="handleAuthenticateGoogleCalendar"
        >
          Sync my Google Calendar
        </div>
        <div
          v-if="canDisplaySaveCurrentFilters"
          class="vms-dropdown__list vd-black vd-padding-small"
          @click="handleSaveCurrentFilters"
        >
          Save current filters
        </div>

        <div
          class="vms-dropdown__list vd-black vd-padding-small"
          @click="handleOpenOnHoldEvents"
        >
          Open on hold events
        </div>

        <hr class="vd-margin-block-0 vd-border-grey" />

        <calendar-settings-dropdown-filters
          id="show-google-bamboo-events"
          label="Show Google/Bamboo events"
          value="show-google-bamboo-events"
          @handle:filter-checked="handleShowGoogleBambooEvents"
        ></calendar-settings-dropdown-filters>

        <calendar-settings-dropdown-filters
          id="show-events-assigned-to-me"
          label="Show events assigned to me"
          value="show-events-assigned-to-me"
          @handle:filter-checked="handleShowEventsAssignedToMe"
        ></calendar-settings-dropdown-filters>

        <calendar-settings-dropdown-filters
          v-if="canDisplayShowEventsIOwnFilter"
          id="show-events-i-own"
          label="Show events I own"
          value="show-events-i-own"
          @handle:filter-checked="handleShowEventsIOwn"
        ></calendar-settings-dropdown-filters>

        <calendar-settings-dropdown-filters
          id="show-on-hold-events"
          label="Show on hold events"
          value="show-on-hold-events"
          @handle:filter-checked="handleShowOnHoldEvents"
        ></calendar-settings-dropdown-filters>

        <calendar-settings-dropdown-filters
          id="show-freelancer-events"
          label="Show freelancers"
          value="show-freelancer-events"
          @handle:filter-checked="handleShowFreelancerEvents"
        ></calendar-settings-dropdown-filters>

        <calendar-settings-dropdown-filters
          id="show-unassigned-events"
          label="Show unassigned events"
          value="show-unassigned-events"
          @handle:filter-checked="handleShowUnassignedEvents"
        ></calendar-settings-dropdown-filters>
      </div>
    </div>

    <calendar-save-filters-modal
      v-if="canShowSaveFiltersModal"
    ></calendar-save-filters-modal>

    <on-hold-events-modal
      v-if="canShowOnHoldEventsModal"
    ></on-hold-events-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CalendarSettingsDropdownFilters from '@views/calendar/components/calendar-settings-dropdown/calendar-settings-dropdown-filters'
import { some, size } from 'lodash'
// import { some, isEmpty, find, size } from 'lodash'
// import VmsConstants from '@configs/vms-constants'
import CalendarSaveFiltersModal from '@views/calendar/components/calendar-save-filters-modal'
import OnHoldEventsModal from '@components/modal/events/events-on-hold-modal'
import ClickOutside from 'vue-click-outside'

export default {
  components: {
    CalendarSettingsDropdownFilters,
    CalendarSaveFiltersModal,
    OnHoldEventsModal,
  },

  directives: {
    ClickOutside,
  },

  data() {
    return {
      canDisplaySettingsDropdown: false,
    }
  },

  computed: {
    ...mapGetters({
      selectedFilters: 'calendar/selectedFilters',
      userRoles: 'auth/userRoles',
      canShowSaveFiltersModal: 'calendar/canShowSaveFiltersModal',
      canShowOnHoldEventsModal: 'calendar/canShowOnHoldEventsModal',
      isShowEventsAssignedToMeFilterSelected:
        'calendar/isShowEventsAssignedToMeFilterSelected',
      isShowEventsIOwnFilterSelected: 'calendar/isShowEventsIOwnFilterSelected',
      loggedInUser: 'auth/getVmsUserData',
      eventsListPayload: 'calendar/eventsListPayload',
      selectedOwnedByIdFilters: 'calendar/selectedOwnedByIdFilters',
    }),

    canDisplaySaveCurrentFilters() {
      return (
        size(this.selectedFilters) > 0 ||
        size(this.selectedOwnedByIdFilters) > 0
      )
    },

    canDisplayShowEventsIOwnFilter() {
      return false

      // Temporary commenting below code to
      // return !isEmpty(
      //   find(this.userRoles, (userRole) => {
      //     return (
      //       userRole.id ===
      //         VmsConstants.userRoles.USER_ROLE_ID_ACCOUNT_MANAGER ||
      //       userRole.id === VmsConstants.userRoles.USER_ROLE_ID_PRODUCER
      //     )
      //   })
      // )
    },
  },

  methods: {
    ...mapActions({
      addSelectedFilter: 'calendar/addSelectedFilter',
      removeSelectedFilter: 'calendar/removeSelectedFilter',
      clearAllFilters: 'calendar/clearAllFilters',
      setShowSaveFiltersModal: 'calendar/setShowSaveFiltersModal',
      setShowOnHoldEventsModal: 'calendar/setShowOnHoldEventsModal',
      getEventsList: 'calendar/getEventsList',
      authoriseGoogleCalendar: 'user/authoriseGoogleCalendar',
    }),

    toggleSettingsDropdown() {
      this.canDisplaySettingsDropdown = !this.canDisplaySettingsDropdown
    },

    addOrRemoveFilter(filter) {
      if (some(this.selectedFilters, filter)) {
        this.removeSelectedFilter(filter)
      } else {
        this.addSelectedFilter(filter)
      }

      this.canDisplaySettingsDropdown = false
    },

    handleDefaultGetEventsList() {
      this.$nextTick(() => {
        this.getEventsList({
          payload: this.eventsListPayload,
          showLoader: false,
        })
      })
    },

    handleShowGoogleBambooEvents(filter) {
      this.addOrRemoveFilter(filter)
      this.handleDefaultGetEventsList()
    },

    handleShowEventsAssignedToMe(filter) {
      if (!this.isShowEventsAssignedToMeFilterSelected) {
        this.clearAllFilters(false)
      }

      this.addOrRemoveFilter(filter)
      this.handleDefaultGetEventsList()
    },

    handleShowEventsIOwn(filter) {
      if (!this.isShowEventsIOwnFilterSelected) {
        this.clearAllFilters(false)
      }

      this.addOrRemoveFilter(filter)
      this.handleDefaultGetEventsList()
    },

    handleShowFreelancerEvents(filter) {
      this.addOrRemoveFilter(filter)
      this.handleDefaultGetEventsList()
    },

    handleShowUnassignedEvents(filter) {
      this.addOrRemoveFilter(filter)
      this.handleDefaultGetEventsList()
    },

    handleAuthenticateGoogleCalendar() {
      this.canDisplaySettingsDropdown = false
      this.authoriseGoogleCalendar(this.loggedInUser.id)
    },

    handleSaveCurrentFilters() {
      this.setShowSaveFiltersModal(true)
      this.canDisplaySettingsDropdown = false
    },

    handleOpenOnHoldEvents() {
      this.canDisplaySettingsDropdown = false
      this.setShowOnHoldEventsModal(true)
    },

    closeSettingsDropdown() {
      this.canDisplaySettingsDropdown = false
    },

    handleShowOnHoldEvents(filter) {
      this.addOrRemoveFilter(filter)
      this.handleDefaultGetEventsList()
    },
  },
}
</script>
