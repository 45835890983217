<template>
  <transition-group name="modal" appear>
    <events-placeholders-modal
      v-if="
        showEventsPlaceholdersCreateModal || showEventsPlaceholdersEditModal
      "
      key="placeholders-modal"
    />

    <events-deliveries-create-modal
      v-if="showEventsDeliveriesCreateModal"
      key="deliveries-create-modal"
    />

    <events-filming-details-create-modal
      v-if="showEventsFilmingDetailsCreateModal"
      key="filming-details-create-modal"
    />

    <events-pre-productions-create-modal
      v-if="showEventsPreProductionsCreateModal"
      key="pre-productions-create-modal"
    />

    <events-deliveries-edit-modal
      v-if="showEventsDeliveriesEditModal"
      key="deliveries-edit-modal"
    />

    <events-filming-details-edit-modal
      v-if="showEventsFilmingDetailsEditModal"
      key="filming-details-edit-modal"
    />

    <events-job-changes-edit-modal
      v-if="showEventsJobChangesEditModal"
      key="job-changes-edit-modal"
    />

    <events-pre-production-changes-edit-modal
      v-if="showEventsPreProductionChangesEditModal"
      key="pre-production-changes-edit-modal"
    ></events-pre-production-changes-edit-modal>

    <events-pre-productions-edit-modal
      v-if="showEventsPreProductionsEditModal"
      key="pre-productions-edit-modal"
    />
  </transition-group>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isEmpty } from 'lodash'
import EventTypes from '@configs/event-types'
import EventTypesMixin from '@mixins/event-types-mixin'
// create event modals
import EventsDeliveriesCreateModal from '@components/modal/events/deliveries/events-deliveries-create-modal'
import EventsFilmingDetailsCreateModal from '@components/modal/events/filming-details/events-filming-details-create-modal'
import EventsPreProductionsCreateModal from '@components/modal/events/pre-productions/events-pre-productions-create-modal'
// edit event modals
import EventsDeliveriesEditModal from '@components/modal/events/deliveries/events-deliveries-edit-modal'
import EventsFilmingDetailsEditModal from '@components/modal/events/filming-details/events-filming-details-edit-modal'
import EventsJobChangesEditModal from '@components/modal/events/job-changes/events-job-changes-edit-modal'
import EventsPreProductionChangesEditModal from '@components/modal/events/pre-production-changes/events-pre-production-changes-edit-modal'
import EventsPreProductionsEditModal from '@components/modal/events/pre-productions/events-pre-productions-edit-modal'
import EventsPlaceholdersModal from '@components/modal/events/placeholders/events-placeholders-modal'

export default {
  components: {
    EventsDeliveriesCreateModal,
    EventsFilmingDetailsCreateModal,
    EventsPreProductionsCreateModal,
    EventsDeliveriesEditModal,
    EventsFilmingDetailsEditModal,
    EventsJobChangesEditModal,
    EventsPreProductionChangesEditModal,
    EventsPreProductionsEditModal,
    EventsPlaceholdersModal,
  },

  mixins: [EventTypesMixin],

  data() {
    return {
      eventJobTypes: EventTypes.jobTypes,
    }
  },

  computed: {
    ...mapGetters({
      selectedEvent: 'calendar/selectedEvent',
      // getters to show create event modals
      showEventsPlaceholdersCreateModal:
        'calendar/showEventsPlaceholdersCreateModal',
      showEventsDeliveriesCreateModal:
        'calendar/showEventsDeliveriesCreateModal',
      showEventsFilmingDetailsCreateModal:
        'calendar/showEventsFilmingDetailsCreateModal',
      showEventsPreProductionsCreateModal:
        'calendar/showEventsPreProductionsCreateModal',
      // getters to show edit event modals
      showEventsPlaceholdersEditModal:
        'calendar/showEventsPlaceholdersEditModal',
      showEventsDeliveriesEditModal: 'calendar/showEventsDeliveriesEditModal',
      showEventsFilmingDetailsEditModal:
        'calendar/showEventsFilmingDetailsEditModal',
      showEventsJobChangesEditModal: 'calendar/showEventsJobChangesEditModal',
      showEventsPreProductionsEditModal:
        'calendar/showEventsPreProductionsEditModal',
      showEventsPreProductionChangesEditModal:
        'calendar/showEventsPreProductionChangesEditModal',
    }),
  },

  mounted() {
    if (!isEmpty(this.selectedEvent)) {
      this.initializeModal()
    }
  },

  methods: {
    ...mapActions({
      // actions to toggle edit event modals
      setShowEventsDeliveriesEditModal:
        'calendar/setShowEventsDeliveriesEditModal',
      setShowEventsFilmingDetailsEditModal:
        'calendar/setShowEventsFilmingDetailsEditModal',
      setShowEventsJobChangesEditModal:
        'calendar/setShowEventsJobChangesEditModal',
      setShowEventsPreProductionsEditModal:
        'calendar/setShowEventsPreProductionsEditModal',
      setShowEventsPreProductionChangesEditModal:
        'calendar/setShowEventsPreProductionChangesEditModal',
      setShowEventsPlaceholdersEditModal:
        'calendar/setShowEventsPlaceholdersEditModal',
    }),

    initializeModal() {
      // get event job type.
      const eventJobType = this.getJobTypeByEventTypeId(
        this.selectedEvent?.event_type?.id
      )

      // if order_job_element_id exists, then show specific job type modal, else show placeholder modal
      if (this.selectedEvent.order_job_element_id) {
        switch (eventJobType) {
          case this.eventJobTypes.DELIVERIES:
            this.setShowEventsDeliveriesEditModal(true)
            break
          case this.eventJobTypes.FILMING_DETAILS:
            this.setShowEventsFilmingDetailsEditModal(true)
            break
          case this.eventJobTypes.JOB_CHANGES:
            this.setShowEventsJobChangesEditModal(true)
            break
          case this.eventJobTypes.PRE_PRODUCTIONS:
            this.setShowEventsPreProductionsEditModal(true)
            break
          case this.eventJobTypes.PRE_PRODUCTION_CHANGES:
            this.setShowEventsPreProductionChangesEditModal(true)
            break
          default:
            break
        }
      } else {
        this.setShowEventsPlaceholdersEditModal(true)
      }
    },
  },
}
</script>
