<template>
  <default-modal
    :class="[
      'show vd-text-align-left',
      isMobileScreen ? 'vms-modal--fullscreen' : '',
    ]"
    modal-dialog-class="modal-lg"
    content-class="vd-background-white"
  >
    <div slot="modal-header" class="modal-header">
      <div class="vd-h4 vd-black">Calendar filters</div>
      <span
        id="closeCalendarFilters"
        class="vms-modal__header--close close vd-black"
        @click="handleCancel"
      >
        <span id="closeCalendarFilters">x</span>
      </span>
    </div>

    <div slot="modal-body" class="modal-body">
      <div
        v-if="hasEventsSearchKeyWord"
        class="vd-margin-right-small vd-display-inline"
        @click="removeSearchKeyword"
      >
        <div
          class="vd-display-inline-block vd-cursor-pointer vd-background-aqua vd-margin-bottom-10 vd-padding-top-extra-small vd-padding-bottom-extra-small vd-padding-left-10 vd-padding-right-10"
        >
          <span class="vd-margin-right-10"
            >Search: {{ eventsSearchKeyWord }}</span
          ><span>X</span>
        </div>
      </div>

      <div
        v-for="(selectedFilter, index) in selectedFilters"
        :key="index"
        class="vd-margin-right-small vd-display-inline"
        @click="handleFilterClick(selectedFilter)"
      >
        <div
          class="vd-display-inline-block vd-cursor-pointer vd-background-aqua vd-margin-bottom-10 vd-padding-top-extra-small vd-padding-bottom-extra-small vd-padding-left-10 vd-padding-right-10"
        >
          <span class="vd-margin-right-10">{{ selectedFilter.label }}</span
          ><span>X</span>
        </div>
      </div>

      <div
        v-for="(ownerFilter, index) in selectedOwnedByIdFilters"
        :key="`owner-${index}`"
        class="vd-margin-right-small vd-display-inline"
        @click="handleOwnerFilterClick(ownerFilter)"
      >
        <div
          class="vd-display-inline-block vd-cursor-pointer vd-background-aqua vd-margin-bottom-10 vd-padding-top-extra-small vd-padding-bottom-extra-small vd-padding-left-10 vd-padding-right-10"
        >
          <span class="vd-margin-right-10"
            >Owned by: {{ ownerFilter.label }}</span
          ><span>X</span>
        </div>
      </div>
    </div>

    <div slot="modal-footer" class="modal-footer">
      <div class="vd-text-align-right">
        <button
          class="vd-btn-small vd-btn-blue vd-margin-right-small"
          @click="clearAllFilters(true)"
        >
          Clear all filters
        </button>

        <button class="vd-btn-small vd-btn-grey" @click="handleCancel">
          Close
        </button>
      </div>
    </div>
  </default-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DefaultModal from '@components/modal/default-modal'
import { size } from 'lodash'

export default {
  components: {
    DefaultModal,
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      selectedFilters: 'calendar/selectedFilters',
      hasEventsSearchKeyWord: 'calendar/hasEventsSearchKeyWord',
      eventsSearchKeyWord: 'calendar/eventsSearchKeyWord',
      eventsListPayload: 'calendar/eventsListPayload',
      selectedOwnedByIdFilters: 'calendar/selectedOwnedByIdFilters',
    }),
  },

  watch: {
    selectedFilters() {
      this.checkShowAllFilterModal()
      this.handleGetEventsList()
    },

    selectedOwnedByIdFilters() {
      this.checkShowAllFilterModal()
      this.handleGetEventsList()
    },
  },

  methods: {
    ...mapActions({
      removeSelectedFilter: 'calendar/removeSelectedFilter',
      clearAllFilters: 'calendar/clearAllFilters',
      setShowAllFiltersModal: 'calendar/setShowAllFiltersModal',
      setEventsSearchKeyword: 'calendar/setEventsSearchKeyword',
      getEventsList: 'calendar/getEventsList',
      removeSelectedOwnedByIdFilters: 'calendar/removeSelectedOwnedByIdFilters',
    }),

    checkShowAllFilterModal() {
      if (
        size(this.selectedFilters) === 0 &&
        size(this.selectedOwnedByIdFilters) === 0
      ) {
        this.setShowAllFiltersModal(false)
      }
    },

    handleCancel() {
      this.setShowAllFiltersModal(false)
    },

    handleFilterClick(filter) {
      this.removeSelectedFilter(filter)
    },

    handleOwnerFilterClick(filter) {
      this.removeSelectedOwnedByIdFilters(filter)
    },

    removeSearchKeyword() {
      this.setEventsSearchKeyword('')
      this.handleGetEventsList()
    },

    handleGetEventsList() {
      this.getEventsList({
        payload: this.eventsListPayload,
        showLoader: false,
      })
    },
  },
}
</script>
