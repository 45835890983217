<template>
  <div class="vms-video__filters--list-label vd-h4 vd-margin-block-medium">
    <div
      v-if="!isMobileScreen"
      class="row no-gutters vd-cursor-pointer"
      @click="toggleAgendaListExpand"
    >
      <div class="col-10">
        <div class="vd-h4">
          <strong>My agenda</strong>
        </div>
      </div>

      <div class="col-2 vd-text-align-right">
        <i
          :class="[
            'vd-icon vd-hover-darker-grey vd-text-small',
            isListExpanded ? 'icon-minus' : 'icon-plus',
          ]"
        ></i>
      </div>
    </div>

    <div v-if="isListExpanded" class="vd-margin-top-medium">
      <div v-if="isAgendaListLoading" class="vd-text-align-center vd-dark-grey">
        Loading...
      </div>

      <div v-else>
        <div class="vd-margin-bottom-small">
          <span class="vd-blue vms-calendar__label--main">TODAY</span>
          <span class="vd-blue vd-margin-left-extra-small">{{
            dateToday | momentFormat('DD/MM/YYYY')
          }}</span>
        </div>

        <calendar-side-panel-agenda-list
          :agendas="currentDateAgendaList"
        ></calendar-side-panel-agenda-list>

        <div class="vd-margin-bottom-small">
          <span class="vms-calendar__label--main">TOMORROW</span>
          <span class="vd-margin-left-extra-small">{{
            dateTomorrow | momentFormat('DD/MM/YYYY')
          }}</span>
        </div>

        <calendar-side-panel-agenda-list
          :agendas="dateTomorrowAgendaList"
        ></calendar-side-panel-agenda-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CalendarSidePanelAgendaList from '@views/calendar/calendar-side-panel/calendar-side-panel-agenda/calendar-side-panel-agenda-list'
import momentTz from 'moment-timezone'
import ApiCustomIncludes from '@configs/api-custom-includes'
import { filter } from 'lodash'
import FiltersMixin from '@mixins/filters-mixin'

export default {
  components: {
    CalendarSidePanelAgendaList,
  },

  mixins: [FiltersMixin],

  data() {
    return {
      isListExpanded: true,
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      agendaList: 'calendar/agendaList',
      isAgendaListLoading: 'calendar/isAgendaListLoading',
    }),

    dateToday() {
      return momentTz()
    },

    dateTomorrow() {
      return momentTz().add(1, 'day')
    },

    currentDateAgendaList() {
      return filter(this.agendaList, (agenda) => {
        const agendaStartDate = momentTz.unix(agenda.from).startOf('day')

        return agendaStartDate.isSame(this.dateToday.startOf('day'))
      })
    },

    dateTomorrowAgendaList() {
      return filter(this.agendaList, (agenda) => {
        const agendaStartDate = momentTz.unix(agenda.from).startOf('day')

        return agendaStartDate.isSame(this.dateTomorrow.startOf('day'))
      })
    },
  },

  mounted() {
    this.getAgendaList({
      start: this.dateToday.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      end: this.dateTomorrow.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      filters: {
        assigned_to_me: 1,
        include_google_events: 1,
        include_bamboo_events: 1,
      },
      include: ApiCustomIncludes.eventsList,
      notCancelable: true,
    })
  },

  methods: {
    ...mapActions({
      getAgendaList: 'calendar/getAgendaList',
    }),

    toggleAgendaListExpand() {
      this.isListExpanded = !this.isListExpanded
    },
  },
}
</script>
