<template>
  <default-modal
    :class="[
      'show vd-text-align-left',
      isMobileScreen ? 'vms-modal--fullscreen' : '',
    ]"
    modal-dialog-class="modal-xl"
    content-class="vd-background-white"
  >
    <div slot="modal-header" class="modal-header">
      <div class="vd-h4 vd-black">On hold events</div>
      <span
        v-if="!isOnHoldEventsListLoading"
        id="closeEventsOnHoldModal"
        class="vms-modal__header--close close vd-black"
        @click="handleCancel"
      >
        <span id="closeEventsOnHoldModal">x</span>
      </span>
    </div>

    <div slot="modal-body" class="modal-body">
      <div class="row no-gutters">
        <div class="col-5">
          <search-component
            v-if="!isOnHoldEventsListLoading"
            class="vd-full-width"
            :search-text="searchKeyword"
            @input:search="searchKeyword = $event"
            @enter:search="handleSearchOnHoldEvents"
          ></search-component>
        </div>

        <div class="col-7 vd-margin-top-small vd-margin-bottom-small">
          <pagination-component
            v-show="canShowPagination"
            :total-rows="onHoldEvents.meta.total"
            :per-page="onHoldEvents.meta.per_page"
            :last-page="onHoldEvents.meta.last_page"
            :show-page-in-route="false"
            :page="page"
            :can-update-pagination-limit="false"
            @update:page="currentPageUpdate"
          ></pagination-component>
        </div>
      </div>

      <div>
        <default-loader v-if="isOnHoldEventsListLoading"></default-loader>

        <div
          v-else
          :class="[
            'vd-margin-top-medium vd-padding-top-small',
            canShowBorder
              ? 'vd-border-light-grey vd-border-bottom-width-0'
              : '',
          ]"
        >
          <template v-if="hasOnHoldEvents">
            <events-card-content
              v-if="isMobileScreen"
              :calendar-events="onHoldEvents.data"
            >
              <template v-slot:action="{ event }">
                <button
                  class="vd-btn-blue vd-btn-small"
                  @click="handleUnHoldEvent(event)"
                >
                  <span class="vd-display-flex">
                    <i
                      class="vd-icon icon-circle-pause vd-margin-right-extra-small"
                    ></i>
                    Take off hold
                  </span>
                </button>
              </template>
            </events-card-content>
            <events-table-content v-else :calendar-events="onHoldEvents.data">
              <template v-slot:action="{ event }">
                <i
                  class="vd-icon icon-circle-pause vd-hover-black vd-cursor-pointer"
                  @click="handleUnHoldEvent(event)"
                ></i>
              </template>
            </events-table-content>
          </template>
          <div v-else class="vd-margin-bottom-medium vd-text-align-center">
            <span>No hold events found.</span>
          </div>
        </div>
      </div>

      <pagination-component
        v-show="canShowPagination"
        :total-rows="onHoldEvents.meta.total"
        :per-page="onHoldEvents.meta.per_page"
        :last-page="onHoldEvents.meta.last_page"
        :show-page-in-route="false"
        :page="page"
        :can-update-pagination-limit="false"
        @update:page="currentPageUpdate"
      ></pagination-component>
    </div>
  </default-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DefaultModal from '@components/modal/default-modal'
import SearchComponent from '@components/search/search'
import PaginationComponent from '@components/pagination/pagination'
import EventsTableContent from '@components/modal/events/components/events-table-content/events-table-content'
import EventsCardContent from '@components/modal/events/components/events-card-content'
import ApiCustomIncludes from '@configs/api-custom-includes'
import DefaultLoader from '@components/loaders/default-loader'
import VmsConstants from '@configs/vms-constants'
import EventTypesMixin from '@mixins/event-types-mixin'
import FiltersMixin from '@mixins/filters-mixin'
import { size } from 'lodash'

export default {
  components: {
    DefaultModal,
    SearchComponent,
    PaginationComponent,
    EventsTableContent,
    DefaultLoader,
    EventsCardContent,
  },

  mixins: [FiltersMixin, EventTypesMixin],

  props: {
    projectId: {
      type: Number,
      default: null,
      required: false,
    },
  },

  data() {
    return {
      perPage: 5,
      searchKeyword: '',
      page: 1,
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      onHoldEvents: 'calendar/onHoldEvents',
      isOnHoldEventsListLoading: 'calendar/isOnHoldEventsListLoading',
      eventsListPayload: 'calendar/eventsListPayload',
      isUnHoldEventLoading: 'calendar/isUnHoldEventLoading',
      getOnHoldEventsLastPayload: 'calendar/getOnHoldEventsLastPayload',
    }),

    hasOnHoldEvents() {
      return size(this.onHoldEvents.data) > 0
    },

    canShowPagination() {
      return !this.isOnHoldEventsListLoading && this.hasOnHoldEvents
    },

    canShowBorder() {
      return this.hasOnHoldEvents && !this.isMobileScreen
    },

    projectIds() {
      return this.projectId ? [this.projectId] : undefined
    },
  },

  watch: {
    isUnHoldEventLoading() {
      if (!this.isUnHoldEventLoading && this.page > 1) {
        this.page = 1
      }
    },
  },

  mounted() {
    this.getOnHoldEvents({
      payload: {
        all: 0,
        per_page: this.perPage,
        project_ids: this.projectIds,
        filters: {
          event_status_ids: [
            VmsConstants.eventStatuses.EVENT_STATUS_ID_ON_HOLD,
          ],
          unassigned: 1,
        },
        include: ApiCustomIncludes.onHoldEventsList,
      },
    })
  },

  methods: {
    ...mapActions({
      setShowOnHoldEventsModal: 'calendar/setShowOnHoldEventsModal',
      getOnHoldEvents: 'calendar/getOnHoldEvents',
      getEventsList: 'calendar/getEventsList',
      unholdJobEvent: 'calendar/unholdJobEvent',
    }),

    handleCancel() {
      this.setShowOnHoldEventsModal(false)

      // Reload event list
      this.getEventsList({
        payload: this.eventsListPayload,
        showLoader: true,
      })
    },

    handleSearchOnHoldEvents(searchKeyword) {
      this.searchKeyword = searchKeyword

      this.getOnHoldEvents({
        payload: {
          all: 0,
          page: 1,
          per_page: this.perPage,
          project_ids: this.projectIds,
          filters: {
            search_term: this.searchKeyword,
            event_status_ids: [
              VmsConstants.eventStatuses.EVENT_STATUS_ID_ON_HOLD,
            ],
            unassigned: 1,
          },
          include: ApiCustomIncludes.onHoldEventsList,
        },
      })
    },

    currentPageUpdate(page) {
      // check if the selected page is not more than the meta last page
      if (page <= this.onHoldEvents.meta.last_page) {
        this.page = page
      } else {
        this.page = this.onHoldEvents.meta.last_page
      }

      this.getOnHoldEvents({
        payload: {
          all: 0,
          page: this.page,
          per_page: this.perPage,
          project_ids: this.projectIds,
          filters: {
            search_term: this.searchKeyword,
            event_status_ids: [
              VmsConstants.eventStatuses.EVENT_STATUS_ID_ON_HOLD,
            ],
            unassigned: 1,
          },
          include: ApiCustomIncludes.onHoldEventsList,
        },
      })
    },

    handleUnHoldEvent(event) {
      const eventJobType = this.getJobTypeByEventTypeId(event.event_type_id)

      this.unholdJobEvent({
        loadOnHoldEventsList: true,
        event_job_type: eventJobType,
        payload: {
          ...this.getOnHoldEventsLastPayload,
          page: 1,
          notCancelable: true,
        },
        project_id: event.project.id,
        order_job_id: event.order_job_element.order_job_id,
      })
    },
  },
}
</script>
