<template>
  <default-modal
    :class="['show', showModalFullScreen ? 'vms-modal--fullscreen' : '']"
    modal-dialog-class="modal-xl"
    content-class="vd-background-white "
  >
    <div slot="modal-header" class="modal-header">
      <div class="vd-h4 vd-light-blue">Notes</div>
      <div class="vms-modal__header--close close vd-black">
        <span @click="toggleEodNotesModal(false)">x</span>
      </div>
    </div>
    <div slot="modal-body" class="modal-body">
      <div v-if="eodNotesJobListLoading" class="vd-margin-block-large">
        <default-loader></default-loader>
      </div>
      <div v-else class="vms-eod__modal">
        <div class="row">
          <div class="col-12">
            <div
              class="vd-border-width-1 vd-border-radius-10 vd-border-light-grey vd-box-shadow vd-text-align-left vd-padding-10"
            >
              <div class="vd-field">
                <label>Filter notes</label>
                <model-list-select
                  v-model="selectedEodNoteFilterId"
                  :list="eodNotesFilterTypes"
                  option-value="id"
                  option-text="name"
                  placeholder="Select project or job notes"
                ></model-list-select>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showContentLoader" class="vd-margin-block-large">
          <default-loader></default-loader>
        </div>
        <div v-else class="row">
          <div :class="[isMobileScreen ? 'col-12' : 'col-7']">
            <div class="vms-eod__modal-content-left">
              <eod-notes-pinned
                v-if="showPinnedNotes"
                :project-id="projectId"
              ></eod-notes-pinned>
              <eod-notes-project
                v-if="showProjectNotes"
                :project-id="projectId"
                :project-name="projectName"
              ></eod-notes-project>
              <hr
                v-if="showBreakLine"
                class="vd-border-light-grey vd-full-width vd-border-width-0 vd-border-top-width-1 vd-margin-block-20"
              />
              <eod-notes-job
                v-for="(jobNotes, index) in jobsEodNotes"
                :key="index"
                :job-notes="jobNotes"
                :project-id="projectId"
                @handle:link-click="setDropdown(jobNotes.id)"
              ></eod-notes-job>
              <div
                v-if="eodNotesNextPageLink"
                class="vd-text-align-center vd-cursor-pointer vd-a"
                @click="handleEodNotesNextPageClick"
              >
                Load more
              </div>
            </div>
          </div>
          <div :class="[isMobileScreen ? 'col-12' : 'col-5']">
            <div class="vms-eod__modal-content-right">
              <eod-notes-form-filming
                v-if="showFilmingForm"
                :project-id="projectId"
                :job-id="selectedEodNoteFilterId"
                :additional-data="selectedJob.additional_meta_data"
                :color-profile-list="colorProfileList"
                :footage-stock-list="footageStockList"
              ></eod-notes-form-filming>
              <eod-notes-form-deliveries
                v-if="showDeliveriesForm"
                :project-id="projectId"
                :job-id="selectedEodNoteFilterId"
                :additional-data="selectedJob.additional_meta_data"
                :color-profile-list="colorProfileList"
                :footage-stock-list="footageStockList"
              ></eod-notes-form-deliveries>
              <eod-notes-attachments
                :project-id="projectId"
                :selected-eod-note-filter-id="selectedEodNoteFilterId"
              ></eod-notes-attachments>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      slot="modal-footer"
      class="modal-footer vd-text-align-right vd-margin-top-20"
    >
      <div class="vd-display-inline-block">
        <button
          class="vd-btn-small vd-btn-grey"
          @click.prevent="toggleEodNotesModal(false)"
        >
          Close
        </button>
      </div>
    </div>
  </default-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ModelListSelect } from 'vue-search-select'
import { size, split, forEach } from 'lodash'
import DefaultModal from '@components/modal/default-modal'
import FormMixin from '@mixins/forms-mixin'
import EodNotesProject from '@components/eod-notes/project/eod-notes-project'
import EodNotesJob from '@components/eod-notes/job/eod-notes-job'
import EodNotesPinned from '@components/eod-notes/pinned/eod-notes-pinned'
import EodNotesAttachments from '@components/eod-notes/eod-notes-attachments'
import EodNotesFormFilming from '@components/eod-notes/forms/eod-notes-form-filming'
import EodNotesFormDeliveries from '@components/eod-notes/forms/eod-notes-form-deliveries'
import DefaultLoader from '@components/loaders/default-loader'

export default {
  components: {
    DefaultModal,
    ModelListSelect,
    EodNotesProject,
    EodNotesJob,
    EodNotesPinned,
    EodNotesAttachments,
    EodNotesFormFilming,
    EodNotesFormDeliveries,
    DefaultLoader,
  },

  mixins: [FormMixin],

  props: {
    projectId: {
      type: Number,
      required: true,
      default: 0,
    },

    projectName: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      selectedEodNoteFilterId: 0,
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      projectJobs: 'project/projectJobs',
      selectedEodNoteTypeId: 'project/selectedEodNoteTypeId',
      jobsEodNotes: 'project/jobsEodNotes',
      pinnedEodNotes: 'project/pinnedEodNotes',
      eodNotesProjectLoading: 'project/eodNotesProjectLoading',
      eodNotesJobLoading: 'project/eodNotesJobLoading',
      eodNotesPinnedLoading: 'project/eodNotesPinnedLoading',
      eodNotesJobListLoading: 'project/eodNotesJobListLoading',
      eodNotesNextPageLink: 'project/eodNotesNextPageLink',
      windowHeight: 'common/windowHeight',
    }),

    eodNotesFilterTypes() {
      let list = [{ id: 0, name: 'Show all notes (including project notes)' }]

      forEach(this.projectJobs, (projectJob) => {
        let jobObject = {
          id: projectJob.id,
          name: `${projectJob.name} (${projectJob.order_job_type})`,
        }

        list.push(jobObject)
      })

      return list
    },

    showBreakLine() {
      return this.showProjectNotes && this.hasJobNotes
    },

    showPinnedNotes() {
      return size(this.pinnedEodNotes) > 0
    },

    showProjectNotes() {
      return this.selectedEodNoteFilterId === 0
    },

    hasJobNotes() {
      return size(this.jobsEodNotes) > 0
    },

    showContentLoader() {
      return (
        this.eodNotesProjectLoading ||
        this.eodNotesJobLoading ||
        this.eodNotesPinnedLoading
      )
    },

    selectedJob() {
      let selectedJob = this.projectJobs.find(
        (job) => job.id === this.selectedEodNoteFilterId
      )

      return selectedJob ? selectedJob : null
    },

    showFilmingForm() {
      return (
        this.selectedEodNoteFilterId !== 0 &&
        this.selectedJob?.order_job_type === 'Shoot'
      )
    },

    showDeliveriesForm() {
      return (
        this.selectedEodNoteFilterId !== 0 &&
        this.selectedJob?.order_job_type === 'Video'
      )
    },

    showModalFullScreen() {
      return this.isMobileScreen || this.windowHeight < 850
    },

    colorProfileList() {
      return [
        { id: '00_S&D', name: '00 S&D' },
        {
          id: '01_Blackmagic_Gen_5_film_to_extended_video',
          name: '01 Blackmagic Gen 5 film to extended video',
        },
        {
          id: 'other_LUT_-_please_specify_in_notes',
          name: 'Other LUT - Please specify in notes',
        },
      ]
    },

    footageStockList() {
      return [
        { id: 'no', name: 'No' },
        { id: 'yes', name: 'Yes (Add details in your notes)' },
      ]
    },
  },

  watch: {
    selectedEodNoteFilterId(val) {
      if (val === 0) {
        this.getProjectLevelEodNotes()
      } else {
        this.getJobLevelEodNotes(this.selectedEodNoteFilterId)
      }
    },
  },

  beforeMount() {
    const payload = {
      projectId: this.projectId,
      include: ['created_by'],
    }

    this.getProjectJobsList(payload)

    this.selectedEodNoteFilterId = this.selectedEodNoteTypeId

    // ID 0 means project level notes.
    if (this.selectedEodNoteFilterId === 0) {
      this.getProjectLevelEodNotes()
    } else {
      this.getJobLevelEodNotes(this.selectedEodNoteFilterId)
    }
  },

  beforeDestroy() {
    this.setSelectedEodNoteTypeId(0)
    this.getProjectFilesList({
      projectId: this.projectId,
      page: 1,
      include: ['created_by'],
    })
  },

  methods: {
    ...mapActions({
      toggleEodNotesModal: 'project/toggleEodNotesModal',
      getProjectJobsList: 'project/getProjectJobsList',
      getProjectEodNotes: 'project/getProjectEodNotes',
      getProjectJobsEodNotes: 'project/getProjectJobsEodNotes',
      getPinnedEodNotes: 'project/getPinnedEodNotes',
      setSelectedEodNoteTypeId: 'project/setSelectedEodNoteTypeId',
      setProjectEodNotes: 'project/setProjectEodNotes',
      getJobEodNotes: 'project/getJobEodNotes',
      getProjectFilesList: 'project/attachment/getProjectFilesList',
      getProjectJobsNextPageEodNotes: 'project/getProjectJobsNextPageEodNotes',
    }),

    getProjectLevelEodNotes() {
      const payload = {
        projectId: this.projectId,
        include: ['created_by'],
      }

      this.getProjectEodNotes(payload)
      this.getProjectJobsEodNotes(payload)
      this.getPinnedEodNotes(payload)
    },

    getJobLevelEodNotes(jobId) {
      const payload = {
        projectId: this.projectId,
        jobId: jobId,
        include: ['created_by'],
      }

      this.getJobEodNotes(payload)
      this.getPinnedEodNotes(payload)
      this.setProjectEodNotes([])
    },

    handleEodNotesNextPageClick() {
      const stringSplit = split(this.eodNotesNextPageLink, 'page=', 2)

      // Split again using `&` just incase there are more than 1 parameters in the link.
      const parameterSplit = split(stringSplit[1], '&', 1)

      const payload = {
        projectId: this.projectId,
        include: ['created_by'],
        page: parameterSplit[0],
      }

      this.getProjectJobsNextPageEodNotes(payload)
    },

    setDropdown(id) {
      this.selectedEodNoteFilterId = id
    },
  },
}
</script>

<style lang="scss">
@import '@styles/components/eod-notes';
@import '@styles/plugins/vue-search-select';
</style>
