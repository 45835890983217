<template>
  <events-modal
    :calendar-color="eventColor"
    :is-save-disabled="$v.$invalid"
    :is-loading="false"
    :has-changes="$v.$dirty"
    :title="title"
    @click:backdrop="closeModal"
    @click:cancel="closeModal"
    @click:save="saveEventDetails"
  >
    <events-header-actions
      :can-show-upload-video-btn="true"
      :can-show-changes-btn="true"
    ></events-header-actions>

    <div
      :class="[
        'vms-event-modal-content',
        isMobileScreen ? 'vms-event-modal-content--mobile vd-display-flex' : '',
      ]"
    >
      <events-info
        :relationship-manager="relationshipManager"
        :project-manager="projectManager"
        :project-team="projectTeam"
        :office="office"
        :project-id="projectId"
        :project-name="projectName"
        :job-name="jobName"
      >
        <events-job-changes-info
          slot="event-specific-content"
          :project-id="projectId"
          :is-completed="isCompleted"
        ></events-job-changes-info>
      </events-info>

      <events-form-fields
        :assign-to-user.sync="assignTo"
        :assign-to-freelancer.sync="freelancer"
        :start-date.sync="startDate"
        :start-time.sync="startTime"
        :end-time.sync="endTime"
        :disabled="isEventFormLoading"
      ></events-form-fields>
    </div>
  </events-modal>
</template>

<script>
import EventsModal from '@components/modal/events/events-modal'
import EventsInfo from '@components/modal/events/components/events-info'
import EventsJobChangesInfo from '@components/modal/events/job-changes/components/events-job-changes-info'
import EventsFormFields from '@components/modal/events/components/events-form-fields/events-form-fields'
import FormMixin from '@mixins/forms-mixin'
import FiltersMixin from '@mixins/filters-mixin'
import Colours from '@configs/colours'
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import { find } from 'lodash'
import EventsHeaderActions from '@components/modal/events/components/events-header-actions/events-header-actions'
import ApiCustomIncludes from '@configs/api-custom-includes'

export default {
  components: {
    EventsModal,
    EventsInfo,
    EventsJobChangesInfo,
    EventsFormFields,
    EventsHeaderActions,
  },

  mixins: [FormMixin, FiltersMixin],

  inject: ['eventsListIncludes'],

  validations: {
    startDate: {
      required,
    },
    startTime: {
      required,
    },
    endTime: {
      required,
    },
    assignTo: {},
    generalComments: {},
  },

  data() {
    return {
      title: '',
      assignTo: null,
      freelancer: null,
      startDate: null,
      startTime: '',
      endTime: '',
      eventColor: Colours.eventColours.unassigned,
      office: '',
      projectId: null,
      projectName: '',
      jobName: '',
      relationshipManager: null,
      projectManager: null,
      projectTeam: [],
      isCompleted: false,
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      selectedEvent: 'calendar/selectedEvent',
      isEventFormLoading: 'calendar/isEventFormLoading',
      isEventDetailsLoading: 'calendar/isEventDetailsLoading',
    }),

    payload() {
      return {
        user_id: this.assignTo?.id || null,
        event_freelancer: this.freelancer || null,
        from: `${this.startDate} ${this.startTime}`,
        to: `${this.startDate} ${this.endTime}`,
      }
    },
  },

  watch: {
    payload() {
      this.$v.$touch()
    },

    isEventDetailsLoading(isLoading) {
      if (!isLoading) {
        this.setInitialData()
      }
    },
  },

  mounted() {
    this.setInitialData()
  },

  beforeDestroy() {
    this.closeModal()
  },

  methods: {
    ...mapActions({
      updateEventDetails: 'calendar/updateEventDetails',
      setShowEventsJobChangesEditModal:
        'calendar/setShowEventsJobChangesEditModal',
      setSelectedEvent: 'calendar/setSelectedEvent',
      setShowEventsModal: 'calendar/setShowEventsModal',
    }),

    // eslint-disable-next-line complexity
    setInitialData() {
      this.title = `${this.selectedEvent.event_type?.name} - ${this.selectedEvent.name}`
      this.jobName = this.selectedEvent.name || ''
      this.office = this.selectedEvent.office?.name || ''
      this.projectId = this.selectedEvent.project?.id || null
      this.projectName = this.selectedEvent.project?.name || ''
      this.relationshipManager =
        this.selectedEvent.project?.account_manager || null
      this.projectManager = this.selectedEvent.project?.producer || null
      this.projectTeam = this.selectedEvent.project?.production_team || []

      if (this.selectedEvent.meta_data?.freelancer) {
        this.freelancer = this.selectedEvent.meta_data.freelancer
        this.eventColor = Colours.eventColours.freelancer
      } else if (this.selectedEvent.user) {
        this.assignTo = this.selectedEvent.user
        this.eventColor = this.selectedEvent.user.calendar_color
      }

      this.startDate = this.$options.filters.unixToFormat(
        this.selectedEvent.from,
        'YYYY-MM-DD'
      )
      this.startTime = this.$options.filters.unixToFormat(
        this.selectedEvent.from,
        'HH:mm:ss'
      )
      this.endTime = this.$options.filters.unixToFormat(
        this.selectedEvent.to,
        'HH:mm:ss'
      )

      if (this.selectedEvent.order_job_element?.order_job?.order_job_changes) {
        this.isCompleted = find(
          this.selectedEvent.order_job_element.order_job.order_job_changes,
          (changes) => {
            return changes.event_id === this.selectedEvent.id
          }
        )?.is_done
      }
    },

    closeModal() {
      this.setShowEventsJobChangesEditModal(false)
      this.setShowEventsModal(false)
      this.setSelectedEvent(null)
    },

    saveEventDetails() {
      this.updateEventDetails({
        includes: ApiCustomIncludes[this.eventsListIncludes],
        eventId: this.selectedEvent.id,
        payload: this.payload,
      })
    },
  },
}
</script>
