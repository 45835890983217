<template>
  <tr>
    <td class="vd-border-light-grey">
      <span v-if="event.project">{{ event.project.id }}</span>
    </td>
    <td class="vd-border-light-grey">
      {{ event.name }}
    </td>
    <td class="vd-border-light-grey">
      <span v-if="event.event_type">{{ event.event_type.name }}</span>
    </td>
    <td class="vd-border-light-grey">
      {{ event.from | unixToFormat('DD MMM YYYY') }}
    </td>
    <td class="vd-border-light-grey">
      {{ event.from | unixToFormat('hh:mm a') }}
    </td>
    <td class="vd-border-light-grey vd-text-align-center">
      {{ event.duration | getDurationFormat }}
    </td>
    <td class="vd-border-light-grey vd-text-align-center">
      <slot name="action"></slot>
    </td>
  </tr>
</template>

<script>
import FiltersMixin from '@mixins/filters-mixin'

export default {
  mixins: [FiltersMixin],

  props: {
    event: {
      type: Object,
      required: true,
    },
  },
}
</script>
