<template>
  <div
    v-if="showRefresh"
    class="vd-display-flex vd-align-center vd-position-relative"
  >
    <em
      v-if="!isMediumScreen"
      class="vd-margin-right-small vd-blue vd-text-small vd-display-flex vd-flex-wrap vd-justify-end"
    >
      <span>Calendar updates occurred,</span
      ><span class="vd-margin-left-extra-small">refresh to see changes</span>
    </em>
    <button
      id="calendarRefreshButton"
      class="vd-padding-block-extra-small vd-padding-inline-small vd-border-radius-4 vd-border-width-1 vd-background-white vd-border-grey vd-display-flex vd-cursor-pointer vd-hover-background-lighter-grey"
      @click="refresh"
    >
      <span class="vd-icon icon-arrows-rotate"></span>
      <span class="ml-1">Refresh</span>
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    showRefreshDelay: {
      type: Number,
      default: 600000, // 10 minutes in seconds
    },
  },

  data() {
    return {
      refreshTimeout: null,
      showRefresh: false,
    }
  },

  computed: {
    ...mapGetters({
      eventsListPayload: 'calendar/eventsListPayload',
      canShowCalendarProgressBar: 'calendar/canShowCalendarProgressBar',
      isMediumScreen: 'common/isMediumScreen',
    }),
  },

  watch: {
    // reset refresh timeout when data is newly fetched
    canShowCalendarProgressBar(showCalendarProgressBar) {
      if (!showCalendarProgressBar) {
        this.setRefreshTimeout()
      }
    },
  },

  methods: {
    ...mapActions({
      getEventsList: 'calendar/getEventsList',
    }),

    refresh() {
      this.showRefresh = false

      this.getEventsList({
        payload: this.eventsListPayload,
        showLoader: true,
      })
    },

    setRefreshTimeout() {
      this.showRefresh = false
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = setTimeout(() => {
        this.showRefresh = true
      }, this.showRefreshDelay)
    },
  },
}
</script>
