var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ejs-schedule',{ref:"vmsScheduler",attrs:{"id":"VmsCalendar","height":"calc(100vh - 220px)","current-view":_vm.selectedViewType,"time-scale":_vm.timeScale,"selected-date":_vm.selectedCalendarDate,"event-settings":_vm.eventSettings,"work-hours":_vm.workHours,"event-rendered":_vm.onEventRendered,"allow-multi-drag":true,"drag-stop":_vm.handleDragStop,"timezone":_vm.defaultCalendarTimezone,"enable-persistence":true,"allow-multi-row-selection":true,"quick-info-templates":_vm.quickInfoTemplates,"date-header-template":_vm.dateHeaderTemplate,"resource-header-template":_vm.timelineResourceHeaderTemplate,"agenda-days-count":365},on:{"resizeStop":_vm.handleResizeStop,"navigating":_vm.handleSchedulerNavigation,"select":_vm.handleSelect,"eventDoubleClick":_vm.handleEventDoubleClick,"cellDoubleClick":_vm.cancelEvent,"cellClick":_vm.cancelEvent,"popupOpen":_vm.handlePopupOpen},nativeOn:{"contextmenu":function($event){$event.preventDefault();return _vm.handleEventRightClicked($event)}},scopedSlots:_vm._u([{key:"popupHeaderTemplate",fn:function(ref){
var data = ref.data;
return [_c('events-popover-header',{style:(_vm.getPopoverBodyStyle(data)),attrs:{"event-data":data,"selected-event":_vm.selectedEvent,"is-on-hold":_vm.isEventOnHold(data.event_status_id),"event-name":_vm.eventName(data)},on:{"click:edit":_vm.openEditModal,"click:delete":_vm.handleDeleteEvent}})]}},{key:"popupContentTemplate",fn:function(ref){
var data = ref.data;
return [_c('events-popover-content',{attrs:{"event-data":data,"selected-event":_vm.selectedEvent,"is-event-details-loading":_vm.isEventDetailsLoading,"get-event-details":_vm.getEventDetails,"is-placeholder-event-type":_vm.isPlaceholderEventType(data),"is-external-event":_vm.isExternalEvent(data.event_type_id)}})]}},{key:"dateHeaderAgendaTemplate",fn:function(ref){
var data = ref.data;
return [_c('calendar-agenda-header-template',{attrs:{"event-data":data}})]}},{key:"dateHeaderTimelineTemplate",fn:function(ref){
var data = ref.data;
return [_c('events-timeline-header',{attrs:{"event-data":data,"holidays":_vm.getDateHolidays(data)}})]}},{key:"timelineResourceHeaderTemplate",fn:function(ref){
var data = ref.data;
return [_c('events-timeline-resource-header',{attrs:{"event-data":data}})]}}])},[(_vm.isTimelineView)?_c('e-resources',[_c('e-resource',{attrs:{"data-source":_vm.teamsResourceGroup,"field":"TeamGroupId","title":"Teams","name":"teams","text-field":"teamName","id-field":"teamId"}}),_c('e-resource',{attrs:{"data-source":_vm.usersResourceGroup,"field":"UserGroupId","title":"Users","name":"users","text-field":"userName","id-field":"userId","group-i-d-field":"teamId"}})],1):_vm._e(),_c('e-views',[_c('e-view',{attrs:{"option":"Day","event-template":'dayViewTemplate'},scopedSlots:_vm._u([{key:"dayViewTemplate",fn:function(ref){
var data = ref.data;
return [_c('calendar-scheduler-day-view',{attrs:{"event-data":data,"is-google-calendar-event-type":_vm.isGoogleCalendarEventType(data.event_type_id),"is-bamboo-hr-event-type":_vm.isBambooHrEventType(data.event_type_id),"is-external-event":_vm.isExternalEvent(data.event_type_id),"is-placeholder-event-type":_vm.isPlaceholderEventType(data),"event-name":_vm.eventName(data),"is-event-on-hold":_vm.isEventOnHold(data.event_status_id)}})]}}])}),_c('e-view',{attrs:{"option":"Week","event-template":'weekViewTemplate'},scopedSlots:_vm._u([{key:"weekViewTemplate",fn:function(ref){
var data = ref.data;
return [_c('calendar-scheduler-week-view',{attrs:{"event-data":data,"is-google-calendar-event-type":_vm.isGoogleCalendarEventType(data.event_type_id),"is-bamboo-hr-event-type":_vm.isBambooHrEventType(data.event_type_id),"is-external-event":_vm.isExternalEvent(data.event_type_id),"is-placeholder-event-type":_vm.isPlaceholderEventType(data),"event-name":_vm.eventName(data),"is-event-on-hold":_vm.isEventOnHold(data.event_status_id)}})]}}])}),_c('e-view',{attrs:{"option":"Month","event-template":'monthViewTemplate'},scopedSlots:_vm._u([{key:"monthViewTemplate",fn:function(ref){
var data = ref.data;
return [_c('calendar-scheduler-month-view',{attrs:{"event-data":data,"is-google-calendar-event-type":_vm.isGoogleCalendarEventType(data.event_type_id),"is-bamboo-hr-event-type":_vm.isBambooHrEventType(data.event_type_id),"is-external-event":_vm.isExternalEvent(data.event_type_id),"is-placeholder-event-type":_vm.isPlaceholderEventType(data),"event-name":_vm.eventName(data),"is-event-on-hold":_vm.isEventOnHold(data.event_status_id)}})]}}])}),_c('e-view',{attrs:{"option":"TimelineMonth","display-name":"Timeline","group":_vm.resourceGroup,"event-template":'timelineMonthViewTemplate'},scopedSlots:_vm._u([{key:"timelineMonthViewTemplate",fn:function(ref){
var data = ref.data;
return [_c('events-timeline-view',{attrs:{"event-data":data,"is-google-calendar-event-type":_vm.isGoogleCalendarEventType(data.event_type_id),"is-bamboo-hr-event-type":_vm.isBambooHrEventType(data.event_type_id),"is-external-event":_vm.isExternalEvent(data.event_type_id),"is-placeholder-event-type":_vm.isPlaceholderEventType(data),"event-name":_vm.eventName(data),"is-event-on-hold":_vm.isEventOnHold(data.event_status_id)}})]}}])}),(_vm.hasEventsSearchKeyWord)?_c('e-view',{attrs:{"option":"Agenda","allow-virtual-scrolling":true,"display-name":"All Search Results","event-template":'agendaViewTemplate'},scopedSlots:_vm._u([{key:"agendaViewTemplate",fn:function(ref){
var data = ref.data;
return [_c('calendar-scheduler-agenda-view',{attrs:{"event-data":data,"is-google-calendar-event-type":_vm.isGoogleCalendarEventType(data.event_type_id),"is-bamboo-hr-event-type":_vm.isBambooHrEventType(data.event_type_id),"is-external-event":_vm.isExternalEvent(data.event_type_id),"is-placeholder-event-type":_vm.isPlaceholderEventType(data),"is-mobile-screen":_vm.isMobileScreen,"is-event-on-hold":_vm.isEventOnHold(data.event_status_id)}})]}}],null,false,1663238778)}):_vm._e()],1)],1),_c('events-context-menu',{ref:"calendarContextMenu",on:{"handle:context-menu-close":_vm.clearSelectedCells}}),_c('events-context-menu',{ref:"calendarBulkActionContextMenu",attrs:{"menu-items":_vm.calendarBulkMenuItems,"before-open":_vm.onBulkContextMenuBeforeOpen},on:{"handle:context-menu-close":_vm.clearSelectedEvents,"handle:content-menu-select":_vm.handleBulkActionContextMenuSelect}}),_c('events-context-menu',{ref:"calendarActionContextMenu",attrs:{"menu-items":_vm.calendarMenuItems,"before-open":_vm.onContextMenuBeforeOpen},on:{"handle:context-menu-close":_vm.clearSelectedEvents,"handle:content-menu-select":_vm.handleActionContextMenuSelect}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }