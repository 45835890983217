<template>
  <div class="vd-text-align-center">
    <div class="vd-h5">
      {{ eventData.date | momentFormat('D MMM') }}
    </div>
    <div class="vd-h5">
      {{ eventData.date | momentFormat('YYYY') }}
    </div>
  </div>
</template>

<script>
import FiltersMixin from '@mixins/filters-mixin'
export default {
  mixins: [FiltersMixin],

  props: {
    eventData: {
      type: Object,
      required: true,
    },
  },
}
</script>
