<template>
  <events-modal
    :calendar-color="eventColor"
    :is-save-disabled="$v.$invalid"
    :is-loading="isEventFormLoading"
    :has-changes="$v.$dirty"
    title="New edit event"
    @click:cancel="closeModal"
    @click:save="saveEventDetails"
  >
    <div
      :class="[
        'vd-darker-grey vd-margin-bottom-medium',
        !isMobileScreen
          ? 'vd-padding-block-small vd-border-lighter-grey vd-border-radius-6 vd-border-width-2'
          : '',
      ]"
    >
      <v-app class="vms-event-modal-fields">
        <events-form-fields-project
          :project-id.sync="projectId"
          :required="true"
          :disabled="isEventFormLoading"
        ></events-form-fields-project>

        <div class="row no-gutters">
          <div class="col-md-6">
            <events-form-fields-event-type
              :event-types-list="videoProductsList"
              :event-type.sync="productId"
              :disabled="isEventFormLoading"
              :is-loading="videoProductsLoading"
            ></events-form-fields-event-type>
          </div>
          <div class="col-md-6">
            <events-form-fields-event-name
              :event-name.sync="eventName"
              :disabled="isEventFormLoading"
            ></events-form-fields-event-name>
          </div>
        </div>

        <div class="row no-gutters">
          <div class="col-md-6">
            <v-app class="vd-padding-medium">
              <v-select
                :disabled="isEventFormLoading"
                :value="revisions"
                :items="revisionRounds"
                :menu-props="menuProps"
                :class="[
                  'vd-field vd-required',
                  revisionRounds ? 'vd-input-valid' : '',
                ]"
                label="Rounds of revisions"
                no-data-text="No results to display"
                clearable
                @change="$emit('update:revisions', $event)"
              >
              </v-select>
            </v-app>
          </div>
          <div class="col-md-6">
            <events-form-fields-additional-extras
              :additional-extras.sync="additionalExtras"
              :extras-list="productElementTypes"
              :disabled="isEventFormLoading"
              :is-loading="videoProductElementsLoading"
            ></events-form-fields-additional-extras>
          </div>
        </div>
      </v-app>
    </div>

    <events-form-fields
      :assign-to-user.sync="assignTo"
      :assign-to-freelancer.sync="freelancer"
      :start-date.sync="startDate"
      :start-time.sync="startTime"
      :end-time.sync="endTime"
      :duration.sync="duration"
      :general-comments.sync="generalComments"
      :has-general-comments="true"
      :disabled="isEventFormLoading"
    ></events-form-fields>
  </events-modal>
</template>

<script>
import EventsModal from '@components/modal/events/events-modal'
import EventsFormFields from '@components/modal/events/components/events-form-fields/events-form-fields'
import EventsFormFieldsProject from '@components/modal/events/components/events-form-fields/events-form-fields-project'
import EventsFormFieldsEventName from '@components/modal/events/components/events-form-fields/events-form-fields-event-name'
import EventsFormFieldsEventType from '@components/modal/events/components/events-form-fields/events-form-fields-event-type'
import EventsFormFieldsAdditionalExtras from '@components/modal/events/components/events-form-fields/events-form-fields-additional-extras'
import FormMixin from '@mixins/forms-mixin'
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import { join, map } from 'lodash'
import Vuetify from 'vuetify'
import ApiCustomIncludes from '@configs/api-custom-includes'

export default {
  vuetify: new Vuetify(),

  components: {
    EventsModal,
    EventsFormFields,
    EventsFormFieldsProject,
    EventsFormFieldsEventName,
    EventsFormFieldsEventType,
    EventsFormFieldsAdditionalExtras,
  },

  mixins: [FormMixin],

  inject: ['eventsListIncludes'],

  validations: {
    projectId: {
      required,
    },
    eventName: {
      required,
    },
    productId: {
      required,
    },
    startDate: {
      required,
    },
    startTime: {
      required,
    },
    endTime: {
      required,
    },
    duration: {
      required,
    },
    assignTo: {},
    generalComments: {},
  },

  data() {
    return {
      menuProps: {
        auto: true,
        closeOnClick: true,
        offsetY: true,
      },
      projectId: null,
      productId: null,
      eventName: '',
      additionalExtras: [],
      revisions: 2,
      assignTo: null,
      freelancer: null,
      startDate: null,
      startTime: '',
      endTime: '',
      duration: 0,
      generalComments: '',
      eventColor: '',
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      isEventFormLoading: 'calendar/isEventFormLoading',
      videoProductsLoading: 'project/delivery/videoProductsLoading',
      videoProducts: 'project/delivery/videoProducts',
      videoProductElementsLoading:
        'project/delivery/videoProductElementsLoading',
      videoProductElements: 'project/delivery/videoProductElements',
      selectedSchedules: 'calendar/selectedSchedules',
    }),

    revisionRounds() {
      // 5 max rounds of revisions
      return map([...Array(5).keys()], (revisionCount) => revisionCount + 1)
    },

    videoProductsList() {
      return map(this.videoProducts, (product) => {
        return {
          id: product.product_id,
          name: product.name,
        }
      })
    },

    productElementTypes() {
      return map(this.videoProductElements, (element) => {
        return {
          id: element.id,
          name: element.product_element_type?.name,
        }
      })
    },

    payload() {
      return {
        product_id: this.productId,
        name: this.eventName,
        additional_extras: join(this.additionalExtras),
        external_note: this.generalComments,
        event_assign_to: this.assignTo?.id || null,
        event_freelancer: this.freelancer || null,
        production_date: this.startDate,
        production_time: this.startTime,
        duration: this.duration,
        allocated_revisions: this.revisions, // default
      }
    },
  },

  watch: {
    payload() {
      this.$v.$touch()
    },

    projectId(projectId) {
      // reset product id and clear video products list
      this.productId = null
      this.setProjectVideoProducts([])

      // fetch video products
      if (projectId) {
        this.getProjectVideoProducts({
          projectId: projectId,
          include: null,
        })
      }
    },

    productId(productId) {
      // reset additional extras and clear video product elements list
      this.additionalExtras = []
      this.setVideoProductElements([])

      // fetch video product elements
      if (productId) {
        this.getVideoProductElements({
          product_id: productId,
          includes: ['product_element_type'],
        })
      }
    },
  },

  mounted() {
    this.setInitialData()
  },

  beforeDestroy() {
    // clear video products and elements list
    this.setVideoProductElements([])
    this.setProjectVideoProducts([])
    this.closeModal()
  },

  methods: {
    ...mapActions({
      createNewDeliveryEvent: 'calendar/createNewDeliveryEvent',
      setProjectVideoProducts: 'project/delivery/setProjectVideoProducts',
      setVideoProductElements: 'project/delivery/setVideoProductElements',
      getProjectVideoProducts: 'project/delivery/getProjectVideoProducts',
      getVideoProductElements: 'project/delivery/getVideoProductElements',
      setSelectedSchedules: 'calendar/setSelectedSchedules',
      setShowEventsModal: 'calendar/setShowEventsModal',
      setShowEventsDeliveriesCreateModal:
        'calendar/setShowEventsDeliveriesCreateModal',
    }),

    setInitialData() {
      this.startDate = this.selectedSchedules?.startDate
      this.startTime = this.selectedSchedules?.startTime
      this.endTime = this.selectedSchedules?.endTime
      this.freelancer = this.selectedSchedules?.freelancer
      this.assignTo = this.selectedSchedules?.assignTo
      this.projectId = this.selectedSchedules?.projectId
    },

    closeModal() {
      this.setShowEventsDeliveriesCreateModal(false)
      this.setShowEventsModal(false)
      this.setSelectedSchedules(null)
    },

    saveEventDetails() {
      this.createNewDeliveryEvent({
        projectId: this.projectId,
        includes: ApiCustomIncludes[this.eventsListIncludes],
        name: this.eventName,
        payload: this.payload,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@styles/plugins/vuetify';
</style>
