<template>
  <events-modal
    :calendar-color="eventColor"
    :is-save-disabled="$v.$invalid"
    :is-loading="isEventFormLoading"
    :has-changes="true"
    :title="bulkEditModalTitle"
    @click:cancel="handleCancel"
    @click:save="handleSave"
  >
    <events-header-actions
      :is-bulk-action="true"
      :show-actions-dropdown="false"
    >
    </events-header-actions>

    <events-form-fields
      :is-bulk-action="true"
      :assign-to-user.sync="assignTo"
      :assign-to-freelancer.sync="freelancer"
      :start-date.sync="startDate"
      :start-time.sync="startTime"
      :end-time.sync="endTime"
      :is-schedule-required="hasDateTimeSelected"
      :disabled="isEventFormLoading"
    ></events-form-fields>
  </events-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { size, forEach, includes, isEmpty } from 'lodash'
import EventsModal from '@components/modal/events/events-modal'
import EventsHeaderActions from '@components/modal/events/components/events-header-actions/events-header-actions'
import EventsFormFields from '@components/modal/events/components/events-form-fields/events-form-fields'
import FormMixin from '@mixins/forms-mixin'
import { requiredIf } from 'vuelidate/lib/validators'
import FiltersMixin from '@mixins/filters-mixin'
import Colours from '@configs/colours'
import VmsConstants from '@configs/vms-constants'

export default {
  components: {
    EventsModal,
    EventsHeaderActions,
    EventsFormFields,
  },

  provide: {
    eventColor: () => this.eventColor,
  },

  mixins: [FormMixin, FiltersMixin],

  validations: {
    startDate: {
      required: requiredIf(function () {
        return this.startTime || this.endTime
      }),
    },
    startTime: {
      required: requiredIf(function () {
        return this.startDate || this.endTime
      }),
    },
    endTime: {
      required: requiredIf(function () {
        return this.startDate || this.startTime
      }),
    },
    assignTo: {},
  },

  data() {
    return {
      assignTo: null,
      freelancer: null,
      startDate: null,
      startTime: null,
      endTime: null,
      eventColor: Colours.eventColours.unassigned,
    }
  },

  computed: {
    ...mapGetters({
      bulkActionSelectedEvents: 'calendar/bulkActionSelectedEvents',
      canBulkReassignUser: 'calendar/canBulkReassignUser',
      isEventFormLoading: 'calendar/isEventFormLoading',
    }),

    hasDateTimeSelected() {
      return (
        !isEmpty(this.startDate) ||
        !isEmpty(this.startTime) ||
        !isEmpty(this.endTime)
      )
    },

    bulkEditModalTitle() {
      return `Bulk update: ${size(
        this.bulkActionSelectedEvents
      )} events selected`
    },

    payload() {
      const updateEventsPayloadArray = []

      forEach(this.bulkActionSelectedEvents, (selectedEvent) => {
        if (!this.isExternalEvent(selectedEvent.event_type_id)) {
          const selectedEventData = {
            id: selectedEvent.id,
          }

          if (this.startDate && this.startTime) {
            selectedEventData['from'] = `${this.startDate} ${this.startTime}`
          }

          if (this.startDate && this.endTime) {
            selectedEventData['to'] = `${this.startDate} ${this.endTime}`
          }

          if (this.canBulkReassignUser) {
            selectedEventData['user_id'] = this.assignTo?.id || null
            selectedEventData['freelancer'] = this.freelancer || null
          }

          updateEventsPayloadArray.push(selectedEventData)
        }
      })

      return {
        events: updateEventsPayloadArray,
      }
    },

    dropdownItemClass() {
      return 'vd-display-block vd-text-align-left vd-text-nowrap vd-padding-inline-medium vd-padding-block-small vd-hover-background-lightest-grey vd-full-width vd-black vd-hover-black vd-text-decoration-none'
    },
  },

  watch: {
    payload() {
      this.$v.$touch()
    },

    assignTo() {
      if (this.assignTo?.calendar_color) {
        this.eventColor = this.assignTo.calendar_color
      } else {
        this.eventColor = Colours.eventColours.unassigned
      }
    },

    freelancer() {
      if (this.freelancer) {
        this.eventColor = Colours.eventColours.freelancer
      }
    },

    canBulkReassignUser() {
      if (!this.canBulkReassignUser) {
        this.freelancer = null
        this.assignTo = null
        this.eventColor = Colours.eventColours.unassigned
      }
    },
  },

  beforeDestroy() {
    this.setCanBulkReassignUser(false)
  },

  methods: {
    ...mapActions({
      setShowEventsBulkEditModal: 'calendar/setShowEventsBulkEditModal',
      setBulkActionSelectedEvents: 'calendar/setBulkActionSelectedEvents',
      bulkUpdateEvents: 'calendar/bulkUpdateEvents',
      setCanBulkReassignUser: 'calendar/setCanBulkReassignUser',
      displayToastAlert: 'common/displayToastAlert',
    }),

    handleCancel() {
      this.setShowEventsBulkEditModal(false)
      this.setBulkActionSelectedEvents([])
    },

    handleSave() {
      if (!isEmpty(this.payload.events)) {
        this.bulkUpdateEvents({
          payload: this.payload,
        })
      } else {
        this.displayToastAlert({
          title: 'Events were updated successfully!',
        })
        this.setShowEventsBulkEditModal(false)
      }
    },

    handleViewVideo() {
      this.$emit('click:view-video')
    },

    isExternalEvent(eventTypeId) {
      return includes(
        [
          VmsConstants.eventTypes.EVENT_TYPE_ID_GOOGLE_CALENDAR_EVENT,
          VmsConstants.eventTypes.EVENT_TYPE_ID_BAMBOO_EVENT,
        ],
        eventTypeId
      )
    },
  },
}
</script>
