<template>
  <div>
    <div
      class="vd-display-flex vd-margin-left-medium vd-align-center vd-margin-bottom-10 vd-margin-right-10"
    >
      <i
        :class="[
          'vd-icon vd-cursor-pointer icon-thumbtack vd-margin-right-10 vd-hover-blue',
          note.is_pinned ? 'vd-blue' : 'vd-light-grey',
        ]"
        @click="handlePinNote"
      ></i>
      <user-profile
        :user="note.created_by"
        custom-image-class="vms-eod__avatar"
        custom-avatar-class="vms-eod__avatar"
        :custom-avatar-font-size="30"
      ></user-profile>
      <div class="vd-margin-left-10">
        {{ note.created_by.full_name }}
      </div>
      <div class="vd-margin-left-10 vd-dark-grey">
        {{ note.created_at | unixToFormat('Do MMM YYYY @ hh:mm a') }}
      </div>
    </div>
    <div class="vd-margin-left-80 vd-margin-bottom-10 vd-margin-right-10">
      <!-- eslint-disable vue/no-v-html -->
      <div v-html="note.comment"></div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UserProfile from '@components/user-profile/user-profile'
import FiltersMixin from '@mixins/filters-mixin'

export default {
  components: {
    UserProfile,
  },

  mixins: [FiltersMixin],

  props: {
    projectId: {
      type: Number,
      required: true,
      default: 0,
    },

    note: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },

  methods: {
    ...mapActions({
      pinEodNotes: 'project/pinEodNotes',
      unpinEodNotes: 'project/unpinEodNotes',
    }),

    handlePinNote() {
      const payload = {
        projectId: this.projectId,
        note: this.note,
      }

      if (this.note.is_pinned) {
        this.unpinEodNotes(payload)
      } else {
        this.pinEodNotes(payload)
      }
    },
  },
}
</script>
