<template>
  <div class="vd-margin-block-medium">
    <search-component
      class="vd-full-width"
      placeholder="Search all filters"
      @enter:search="handleCalendarFilterSearch"
    ></search-component>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import SearchComponent from '@components/search/search'

export default {
  components: {
    SearchComponent,
  },

  methods: {
    ...mapActions({
      filterSearch: 'calendar/filterSearch',
    }),

    handleCalendarFilterSearch(searchKeyword) {
      this.filterSearch(searchKeyword)
    },
  },
}
</script>
