<template>
  <div class="vd-padding-medium">
    <div v-if="isLoading" class="vd-field">
      <label>Additional extras</label>
      <input placeholder="Loading ..." disabled />
    </div>

    <v-select
      v-else
      :value="additionalExtras"
      :items="extrasList"
      :disabled="disabled"
      :deletable-chips="true"
      :hide-selected="true"
      :menu-props="menuProps"
      :class="['vd-field', hasAdditionalExtras ? 'vd-input-valid' : '']"
      label="Additional extras"
      item-text="name"
      item-value="id"
      placeholder="Select additional extras"
      persistent-placeholder
      multiple
      chips
      small-chips
      clearable
      no-data-text="No results to display"
      @change="$emit('update:additionalExtras', $event)"
    >
    </v-select>
  </div>
</template>

<script>
import Vuetify from 'vuetify'
import { isEmpty } from 'lodash'

export default {
  vuetify: new Vuetify(),

  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
    },

    additionalExtras: {
      type: Array,
      required: false,
      default: () => [],
    },

    extrasList: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      menuProps: {
        closeOnClick: true,
        offsetY: true,
      },
    }
  },

  computed: {
    hasAdditionalExtras() {
      return !isEmpty(this.additionalExtras)
    },
  },
}
</script>
