<template>
  <div class="vd-display-flex vd-align-center">
    <input
      :id="filterIdentifier"
      type="checkbox"
      :class="['vd-margin-right-small', checkboxCustomClass]"
      :name="filterIdentifier"
      :value="filter.value"
      :checked="isInputSelected ? 'checked' : ''"
      @change="handleFilterChecked($event.target.checked)"
    />

    <label :for="filterIdentifier" class="vd-margin-0 vd-display-flex">
      <span :class="['vd-margin-right-small', labelCustomClass]">{{
        filter.label
      }}</span>
    </label>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { kebabCase, some, omit } from 'lodash'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },

    labelCustomClass: {
      type: String,
      required: false,
      default: '',
    },

    checkboxCustomClass: {
      type: String,
      required: false,
      default: '',
    },

    isSubFilterSelected: {
      type: Boolean,
      required: false,
      default: false,
    },

    isMainFilterSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      selectedFilters: 'calendar/selectedFilters',
      selectedOwnedByIdFilters: 'calendar/selectedOwnedByIdFilters',
    }),

    filterIdentifier() {
      if (this.filter.is_project_owner) {
        return `${kebabCase(this.filter.label)}-${this.filter.id}-owner`
      } else {
        return `${kebabCase(this.filter.label)}-${this.filter.id}`
      }
    },

    isInputSelected() {
      return (
        this.isMainFilterSelected ||
        this.isSubFilterSelected ||
        (some(this.selectedFilters, omit(this.filter, ['status'])) &&
          !this.filter.is_project_owner) ||
        (some(this.selectedOwnedByIdFilters, omit(this.filter, ['status'])) &&
          this.filter.is_project_owner)
      )
    },
  },

  methods: {
    handleFilterChecked(isInputSelected) {
      this.$emit('handle:filter-checked', this.filter, isInputSelected)
    },
  },
}
</script>
