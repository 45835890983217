<template>
  <div class="vd-margin-bottom-20">
    <div
      class="vd-a vd-margin-bottom-10 vd-cursor-pointer"
      @click="handleLinkClick"
    >
      <i
        class="vd-text-small vd-margin-left-extra-small vd-icon icon-rearrange"
      ></i>
      - Notes related to:
      <strong>{{ jobNotes.name }} ({{ jobNotes.order_job_type }})</strong>
    </div>
    <div
      :class="[
        'vd-display-flex vd-margin-bottom-medium',
        !editMode ? 'vd-align-center' : 'vd-align-top',
      ]"
    >
      <user-profile
        :user="vmsUser"
        custom-image-class="vms-eod__avatar"
        custom-avatar-class="vms-eod__avatar"
        :custom-avatar-font-size="30"
      ></user-profile>
      <div
        v-if="!editMode"
        class="vd-display-inline-block vd-border-light-grey vd-border-width-1 vd-full-width vd-margin-left-10 vd-padding-inline-small vd-padding-block-extra-small vd-dark-grey"
        @click="showCommentField"
      >
        Add a response
      </div>
      <div
        v-else
        class="vd-display-inline-block vd-full-width vd-margin-left-10"
      >
        <text-editor
          label="New note"
          :content="newNote"
          custom-label-class="vd-text-small vd-margin-bottom-0 vd-darker-grey"
          @update:text-editor-content="handleNewNoteUpdated"
        ></text-editor>
        <div class="vd-text-align-right vd-margin-top-extra-small">
          <button class="vd-btn-small" @click.prevent="hideEditMode">
            Cancel
          </button>
          <button
            class="vd-btn-small vd-btn-blue"
            @click.prevent="handleSaveNewComment"
          >
            Save
          </button>
        </div>
      </div>
    </div>
    <div v-if="hasNotes">
      <eod-notes-comment
        v-for="(note, index) in jobNotes.eod_notes"
        :key="index"
        :note="note"
        :project-id="projectId"
      ></eod-notes-comment>
    </div>
    <div v-if="showEmptyNotes">
      <empty-notes
        image-src="/images/empty-states/empty-eod-notes.svg"
        :description="emptyNotesDescription"
        image-custom-class="vd-padding-20"
      ></empty-notes>
      <div
        class="vms-eod__empty-notes vd-text-align-center vd-padding-inline-20 vd-text-small"
      >
        *Leave any notes to help keep everyone up to date with this task.
        Anything that's still needed to be completed? Anything missing? Any
        issues? Note anything you can that may help someone work on this task if
        you can’t.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { size } from 'lodash'
import UserProfile from '@components/user-profile/user-profile'
import EodNotesComment from '@components/eod-notes/eod-notes-comment'
import TextEditor from '@components/text-editor/text-editor'
import EmptyNotes from '@components/empty-states/project/project-tab-empty-state'

export default {
  components: {
    UserProfile,
    EodNotesComment,
    TextEditor,
    EmptyNotes,
  },

  props: {
    projectId: {
      type: Number,
      required: true,
      default: 0,
    },

    jobNotes: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      editMode: false,
      newNote: '',
    }
  },

  computed: {
    ...mapGetters({
      vmsUser: 'auth/getVmsUserData',
    }),

    hasNotes() {
      return size(this.jobNotes.eod_notes) > 0
    },

    showEmptyNotes() {
      return !this.hasNotes && !this.editMode
    },

    emptyNotesDescription() {
      return `Be the first to leave a note about this ${this.jobNotes.order_job_type.toLowerCase()}`
    },
  },

  methods: {
    ...mapActions({
      createJobEodNotes: 'project/createJobEodNotes',
    }),

    hideEditMode() {
      this.editMode = false
    },

    showCommentField() {
      this.editMode = true
    },

    handleNewNoteUpdated(note) {
      this.newNote = note
    },

    handleLinkClick() {
      this.$emit('handle:link-click')
    },

    handleSaveNewComment() {
      const payload = {
        projectId: this.projectId,
        jobId: this.jobNotes.id,
        comment: this.newNote,
        include: ['created_by'],
      }

      this.createJobEodNotes(payload)
      this.editMode = false
      this.newNote = ''
    },
  },
}
</script>
