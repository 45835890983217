<template>
  <div class="row no-gutters vd-full-height">
    <div class="vd-display-flex vd-full-width">
      <div>
        <span
          v-if="!eventData.IsHoliday"
          class="vms-calendar__appointments-time"
        >
          {{ eventData.StartTime | momentFormat('hh:mm A') }} -
        </span>
        <span>
          <span>{{ eventName }}</span>
          <span v-if="!isExternalEvent" class="vd-margin-right-extra-small">
            <span v-if="eventData.state"> - {{ eventData.state.name }}</span>
            <span v-if="eventData.event_type">
              - {{ eventData.event_type.name }}
            </span>
            <span v-if="isPlaceholderEventType"> (Placeholder)</span>
          </span>
          <span v-if="isEventOnHold" class="vd-margin-left-extra-small"
            >(On Hold)</span
          >
        </span>
      </div>

      <div v-if="isExternalEvent" class="vd-margin-inline-extra-small">
        <div
          :class="[
            'vms-calendar__event-type vd-align-middle',
            isGoogleCalendarEventType ? 'vd-icon-google-calendar' : '',
            isBambooHrEventType ? 'vd-icon-bamboo-hr' : '',
          ]"
        ></div>
      </div>

      <div
        v-if="eventData.user"
        class="vms-calendar__appointments-avatar--month-view"
      >
        <user-profile
          :user="eventData.user"
          :custom-avatar-font-size="15"
          class="vms-assigned-crew__profile"
        ></user-profile>
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from '@mixins/filters-mixin'
import UserProfile from '@components/user-profile/user-profile'

export default {
  components: {
    UserProfile,
  },

  mixins: [FiltersMixin],

  props: {
    eventData: {
      type: Object,
      required: true,
    },

    isGoogleCalendarEventType: {
      type: Boolean,
      required: false,
    },

    isBambooHrEventType: {
      type: Boolean,
      required: false,
    },

    isExternalEvent: {
      type: Boolean,
      required: false,
      default: false,
    },

    isPlaceholderEventType: {
      type: Boolean,
      required: false,
      default: false,
    },

    eventName: {
      type: String,
      required: true,
    },

    isEventOnHold: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
