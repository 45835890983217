<template>
  <default-modal
    :class="[
      'show vd-text-align-left',
      isMobileScreen ? 'vms-modal--fullscreen' : '',
    ]"
    modal-dialog-class="modal-lg"
    content-class="vd-background-white"
  >
    <div slot="modal-header" class="modal-header">
      <div class="vd-h4 vd-black">Save filters</div>
      <span
        id="closeSaveFiltersSpan"
        class="vms-modal__header--close close vd-black"
        @click="handleCancel"
      >
        <span id="closeSaveFilters">x</span>
      </span>
    </div>

    <div slot="modal-body" class="modal-body">
      <input-component
        type="text"
        name="filterName"
        label="Filter name"
        :required="true"
        placeholder="Type a name for the filter"
        :container-custom-classes="[setInputValidationClass('filterName')]"
        @formInput="handleInputUpdate($event, 'filterName')"
      ></input-component>
    </div>

    <div slot="modal-footer" class="modal-footer">
      <div class="vd-text-align-right">
        <button
          id="closeSaveFiltersBtn"
          class="vd-btn-small vd-btn-grey vd-margin-right-small"
          @click="handleCancel"
        >
          Close
        </button>

        <button
          id="saveFiltersBtn"
          class="vd-btn-small vd-btn-blue"
          :disabled="$v.$invalid"
          @click="handleSave"
        >
          Save
        </button>
      </div>
    </div>
  </default-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DefaultModal from '@components/modal/default-modal'
import FormMixin from '@mixins/forms-mixin'
import InputComponent from '@components/form/form-input'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  components: {
    DefaultModal,
    InputComponent,
  },

  mixins: [FormMixin],

  data() {
    return {
      filterName: '',
    }
  },

  validations: {
    filterName: {
      required,
      minLength: minLength(1),
    },
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      isShowEventsAssignedToMeFilterSelected:
        'calendar/isShowEventsAssignedToMeFilterSelected',
      isShowUnassignedFilterSelected: 'calendar/isShowUnassignedFilterSelected',
      isShowFreelancerFilterSelected: 'calendar/isShowFreelancerFilterSelected',
      isShowEventsIOwnFilterSelected: 'calendar/isShowEventsIOwnFilterSelected',
      selectedEventTypeIdFilters: 'calendar/selectedEventTypeIdFilters',
      selectedAssignedToIdFilters: 'calendar/selectedAssignedToIdFilters',
      selectedOwnedByIdFiltersArray: 'calendar/selectedOwnedByIdFiltersArray',
      isShowGoogleBambooEventsSelected:
        'calendar/isShowGoogleBambooEventsSelected',
      isShowOnHoldEventsSelected: 'calendar/isShowOnHoldEventsSelected',
    }),
  },

  methods: {
    ...mapActions({
      setShowSaveFiltersModal: 'calendar/setShowSaveFiltersModal',
      createSavedFilter: 'calendar/createSavedFilter',
    }),

    handleCancel() {
      this.setShowSaveFiltersModal(false)
    },

    handleSave() {
      this.createSavedFilter({
        payload: {
          name: this.filterName,
          assigned_to_me: this.isShowEventsAssignedToMeFilterSelected ? 1 : 0,
          unassigned: this.isShowUnassignedFilterSelected ? 1 : 0,
          include_freelancer_events: this.isShowFreelancerFilterSelected
            ? 1
            : 0,
          i_own: this.isShowEventsIOwnFilterSelected ? 1 : 0,
          event_type_ids: this.selectedEventTypeIdFilters,
          assigned_to_ids: this.selectedAssignedToIdFilters,
          owned_by_ids: this.selectedOwnedByIdFiltersArray,
          include_google_events: this.isShowGoogleBambooEventsSelected ? 1 : 0,
          include_bamboo_events: this.isShowGoogleBambooEventsSelected ? 1 : 0,
          include_on_hold_events: this.isShowOnHoldEventsSelected ? 1 : 0,
        },
        include: null,
      })

      this.setShowSaveFiltersModal(false)
    },
  },
}
</script>
