<template>
  <div class="vd-padding-medium">
    <input-component
      :required="true"
      :disabled="disabled"
      :value="eventName"
      :container-custom-classes="[
        'vd-field vd-required',
        eventName ? 'vd-input-valid' : '',
      ]"
      type="text"
      name="eventName"
      label="Name"
      placeholder="Choose a memorable name for the calendar event and job"
      autocomplete="off"
      @formInput="handleEventNameInput"
    ></input-component>
  </div>
</template>

<script>
import InputComponent from '@components/form/form-input'

export default {
  components: {
    InputComponent,
  },

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    eventName: {
      type: String,
      required: false,
      default: '',
    },
  },

  methods: {
    handleEventNameInput(eventName) {
      this.$emit('update:eventName', eventName)
    },
  },
}
</script>
