var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'vd-darker-grey vd-margin-bottom-medium',
    !_vm.isMobileScreen
      ? 'vd-padding-medium vd-border-lighter-grey vd-border-radius-6 vd-border-width-2'
      : '' ]},[_c('div',{staticClass:"row no-gutters vd-darker-grey"},[_c('div',{staticClass:"col-lg-6"},[(_vm.showLocation)?_c('div',{staticClass:"vd-padding-small vd-border-radius-6",style:(_vm.eventColorStyle)},[_c('div',{staticClass:"vd-text-small vd-text-strong"},[_vm._v("Location")]),_c('a',{staticClass:"vd-h5 vd-blue vd-hover-blue vd-border-blue vd-border-width-0 vd-border-bottom-width-1 vd-text-decoration-none",attrs:{"href":_vm.locationLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.location))])]):_vm._e(),(_vm.canShowProjectDetails)?_c('div',{staticClass:"vd-padding-small"},[_c('div',{staticClass:"vd-text-small vd-text-strong"},[_vm._v("Project")]),(_vm.isMobileDevice)?_c('input-clipboard-copy',{attrs:{"input-value":_vm.projectDetailsCopy}}):_c('div',{staticClass:"vd-display-flex vd-align-center"},[_c('router-link',{staticClass:"vd-darker-grey vd-text-decoration-none vd-border-dark-grey vd-border-width-0 vd-border-bottom-width-1 vd-hover-light-blue vd-hover-border-light-blue",attrs:{"target":"_blank","to":{
              name: 'project.details.summary',
              params: { projectId: _vm.projectId },
            }}},[_vm._v(_vm._s(_vm.projectId)+" - "+_vm._s(_vm.projectName))]),_c('clipboard-copy',{attrs:{"success-message":"copied!","copy-content":_vm._f("cleanString")(_vm.projectDetailsCopy)}},[_c('i',{staticClass:"vd-icon icon-copy vd-margin-left-medium vd-cursor-pointer vd-hover-light-blue"})])],1)],1):_vm._e(),_c('div',{staticClass:"vd-padding-small"},[_c('div',{staticClass:"vd-text-small vd-text-strong"},[_vm._v("Event type")]),_c('div',[_vm._v(" "+_vm._s(_vm.eventType)+" "),(_vm.isPlaceholderEventType)?_c('span',[_vm._v(" (Placeholder)")]):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }