<template>
  <div class="vd-margin-bottom-medium">
    <div
      id="toggleFiltersListDiv"
      class="vms-video__filters--list-label vd-h4 vd-margin-bottom-small vd-cursor-pointer"
      @click="toggleFilterListExpand"
    >
      <div class="row no-gutters">
        <div class="col-10">
          <div class="vd-h4">
            <strong>{{ filterLabel }}</strong>
          </div>
        </div>

        <div class="col-2 vd-text-align-right">
          <i
            :class="[
              'vd-icon vd-hover-darker-grey vd-text-small',
              isListExpanded ? 'icon-minus' : 'icon-plus',
            ]"
          ></i>
        </div>
      </div>
    </div>

    <div v-show="isListExpanded">
      <div
        v-for="(filter, index) in filtersList"
        :key="`${index}-${filter.id}`"
        class="vd-margin-bottom-medium"
      >
        <div>
          <div class="vd-display-flex vd-align-center vd-margin-bottom-small">
            <div class="row no-gutters">
              <calendar-side-panel-filters-checkbox
                :filter="filter"
                :class="[
                  'vd-display-flex vd-align-center',
                  hasSubFilters(filter.subFilters) ? 'col-10' : 'col-12',
                ]"
                :label-custom-class="
                  hasSubFilters(filter.subFilters)
                    ? 'vms-calendar__label--main'
                    : ''
                "
                :checkbox-custom-class="
                  hasSubFilters(filter.subFilters)
                    ? ' vms-calendar__checkbox'
                    : ''
                "
                :is-main-filter-selected="isMainFilterSelected(filter)"
                @handle:filter-checked="handleMainFilterSelected"
              ></calendar-side-panel-filters-checkbox>

              <div
                v-if="hasSubFilters(filter.subFilters)"
                class="col-2 vd-text-align-right"
              >
                <i
                  :class="[
                    'vd-icon vd-hover-darker-grey vd-text-small',
                    canShowSubFilters(filter) ? 'icon-minus' : 'icon-plus',
                  ]"
                  @click="handleMainFilterExpandClick(filter)"
                ></i>
              </div>
            </div>
          </div>

          <div v-show="canShowSubFilters(filter)" class="vd-margin-left-medium">
            <div
              v-for="(subFilter, subFilterIndex) in filter.subFilters"
              :key="`${subFilterIndex}-${subFilter.id}}`"
              class="vd-margin-bottom-medium"
            >
              <calendar-side-panel-filters-checkbox
                v-if="!isEventsListLoading"
                :filter="subFilter"
                :checkbox-custom-class="
                  hasSubFilters(filter.subFilters)
                    ? ' vms-calendar__checkbox'
                    : ''
                "
                :is-sub-filter-selected="isSubFilterSelected(subFilter)"
                @handle:filter-checked="handleSubFilterSelected"
              ></calendar-side-panel-filters-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isNil, size, includes, pull, forEach, some, every } from 'lodash'
import CalendarSidePanelFiltersCheckbox from '@views/calendar/calendar-side-panel/calendar-side-panel-filters/components/calendar-side-panel-filters-checkbox'

export default {
  components: {
    CalendarSidePanelFiltersCheckbox,
  },

  props: {
    filterLabel: {
      type: String,
      required: true,
    },

    filtersList: {
      type: Array,
      required: true,
    },

    expandList: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isListExpanded: true,
      expandedMainFilters: [],
      selectedMainFilters: [],
    }
  },

  computed: {
    ...mapGetters({
      selectedFilters: 'calendar/selectedFilters',
      isEventsListLoading: 'calendar/isEventsListLoading',
      eventsListPayload: 'calendar/eventsListPayload',
    }),
  },

  watch: {
    selectedFilters() {
      // Check if all sub filters are selected
      this.checkAllSubFiltersSelected()
    },

    expandList() {
      this.isListExpanded = this.expandList
    },
  },

  mounted() {
    this.isListExpanded = this.expandList
    this.checkAllSubFiltersSelected()
  },

  methods: {
    ...mapActions({
      addSelectedFilter: 'calendar/addSelectedFilter',
      removeSelectedFilter: 'calendar/removeSelectedFilter',
      getEventsList: 'calendar/getEventsList',
    }),

    toggleFilterListExpand() {
      this.isListExpanded = !this.isListExpanded
    },

    handleMainFilterExpandClick(filter) {
      this.updateSelectedMainFilters(filter, 'expandedMainFilters')
    },

    handleGetEventsList() {
      this.getEventsList({
        payload: this.eventsListPayload,
        showLoader: false,
      })
    },

    updateSelectedMainFilters(filter, resourceType) {
      if (this.canShowSubFilters(filter)) {
        if (resourceType === 'selectedMainFilters') {
          pull(this.selectedMainFilters, filter.id)
        } else {
          pull(this.expandedMainFilters, filter.id)
        }

        this.$forceUpdate()
      } else {
        if (resourceType === 'selectedMainFilters') {
          this.selectedMainFilters.push(filter.id)
        } else {
          this.expandedMainFilters.push(filter.id)
        }
      }
    },

    handleMainFilterSelected(selectedFilter, isSelected) {
      // This handle main filter is for both individual user in "Crew"
      // As well as category filter e.g "Animators"

      // Add selected main filter in expanded main filters list
      if (!includes(this.expandedMainFilters, selectedFilter.id)) {
        this.expandedMainFilters.push(selectedFilter.id)
      }

      // Add/remove selected main filter
      if (!includes(this.selectedMainFilters, selectedFilter.id)) {
        this.selectedMainFilters.push(selectedFilter.id)
      } else {
        pull(this.selectedMainFilters, selectedFilter.id)
        this.$forceUpdate()
      }

      if (!isNil(selectedFilter.subFilters)) {
        if (isSelected) {
          forEach(selectedFilter.subFilters, (filter) => {
            this.addSelectedFilter(filter)
          })
        } else {
          forEach(selectedFilter.subFilters, (filter) => {
            this.removeSelectedFilter(filter)
          })
        }
      } else {
        // If the main filter has no sub filters, add/remove main filter in selected filters list
        this.handleSubFilterSelected(selectedFilter)

        // return to prevent calling handleGetEventsList() again
        return
      }

      // Get events list
      this.handleGetEventsList()
    },

    handleSubFilterSelected(selectedFilter) {
      // Add/remove sub filter in selected filters list
      if (some(this.selectedFilters, selectedFilter)) {
        this.removeSelectedFilter(selectedFilter)
        this.$forceUpdate()
      } else {
        this.addSelectedFilter(selectedFilter)
      }

      // Check if all sub filters are selected
      this.checkAllSubFiltersSelected()

      this.handleGetEventsList()
    },

    checkAllSubFiltersSelected() {
      forEach(this.filtersList, (filter) => {
        if (!isNil(filter.subFilters)) {
          every(filter.subFilters, (subFilter) => {
            if (some(this.selectedFilters, subFilter)) {
              if (!includes(this.selectedMainFilters, filter.id)) {
                this.selectedMainFilters.push(filter.id)
              }
            } else {
              if (includes(this.selectedMainFilters, filter.id)) {
                pull(this.selectedMainFilters, filter.id)
                this.$forceUpdate()
              }
            }
          })
        }
      })
    },

    hasSubFilters(subFilters) {
      return !isNil(subFilters) && size(subFilters) > 0
    },

    canShowSubFilters(filter) {
      return (
        (this.hasSubFilters(filter.subFilters) &&
          includes(this.expandedMainFilters, filter.id)) ||
        this.expandList
      )
    },

    isSubFilterSelected(subFilter) {
      return includes(this.selectedFilters, subFilter)
    },

    isMainFilterSelected(mainFilter) {
      return (
        this.hasSubFilters(mainFilter.subFilters) &&
        includes(this.selectedMainFilters, mainFilter.id)
      )
    },
  },
}
</script>
