<template>
  <div>
    <div ref="filterListContainer">
      <div v-show="canDisplaySelectedFiltersList">
        <strong v-if="!isMobileScreen">Filtering by:</strong>

        <div
          ref="selectedFilters"
          :class="[
            'vd-margin-top-small vd-display-inline',
            isMobileScreen ? '' : 'vd-margin-left-small',
          ]"
        >
          <div
            v-if="hasEventsSearchKeyWord"
            class="vd-margin-right-small vd-display-inline vd-text-small"
            @click="removeSearchKeyword"
          >
            <div
              class="vd-display-inline-block vd-cursor-pointer vd-background-aqua vd-margin-bottom-10 vd-padding-top-extra-small vd-padding-bottom-extra-small vd-padding-left-10 vd-padding-right-10"
            >
              <span class="vd-margin-right-10"
                >Search: {{ eventsSearchKeyWord | truncateString(20) }}</span
              ><span>X</span>
            </div>
          </div>

          <div
            v-for="(selectedFilter, index) in sortedDisplayedFilters"
            :key="index"
            class="vd-margin-right-small vd-display-inline vd-text-small"
            @click="handleFilterClick(selectedFilter.filter)"
          >
            <div
              class="vd-display-inline-block vd-cursor-pointer vd-background-aqua vd-margin-bottom-10 vd-padding-top-extra-small vd-padding-bottom-extra-small vd-padding-left-10 vd-padding-right-10"
            >
              <span
                v-if="selectedFilter.filter.is_project_owner"
                class="vd-margin-right-10"
                >Owned by: {{ selectedFilter.filter.label }}</span
              >
              <span v-else class="vd-margin-right-10">{{
                selectedFilter.filter.label
              }}</span
              ><span>X</span>
            </div>
          </div>
        </div>

        <div
          v-if="canDisplayHiddenFiltersCount"
          class="vd-display-inline vd-margin-right-small vd-text-small vd-cursor-pointer vd-text-nowrap"
          @click="setShowAllFiltersModal(true)"
        >
          +{{ hiddenFiltersCount }} more
        </div>
        <div
          class="vd-display-inline vd-a vd-text-small"
          @click="clearAllFilters(true)"
        >
          Clear
        </div>
      </div>
    </div>

    <calendar-selected-filters-modal
      v-if="canShowAllFiltersModal"
    ></calendar-selected-filters-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { size, forEach, orderBy, slice, join, isEmpty, omit, map } from 'lodash'
import CalendarSelectedFiltersModal from '@views/calendar/components/calendar-selected-filters/calendar-selected-filters-modal'
import FiltersMixin from '@mixins/filters-mixin'

export default {
  components: {
    CalendarSelectedFiltersModal,
  },

  mixins: [FiltersMixin],

  data() {
    return {
      canDisplayFilterCount: 0,
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      selectedFilters: 'calendar/selectedFilters',
      canShowAllFiltersModal: 'calendar/canShowAllFiltersModal',
      eventsSearchKeyWord: 'calendar/eventsSearchKeyWord',
      hasEventsSearchKeyWord: 'calendar/hasEventsSearchKeyWord',
      eventsListPayload: 'calendar/eventsListPayload',
      isShowEventsAssignedToMeFilterSelected:
        'calendar/isShowEventsAssignedToMeFilterSelected',
      isShowFreelancerFilterSelected: 'calendar/isShowFreelancerFilterSelected',
      isShowUnassignedFilterSelected: 'calendar/isShowUnassignedFilterSelected',
      isShowGoogleBambooEventsSelected:
        'calendar/isShowGoogleBambooEventsSelected',
      selectedAssignedToIdFilters: 'calendar/selectedAssignedToIdFilters',
      selectedEventTypeIdFilters: 'calendar/selectedEventTypeIdFilters',
      isShowOnHoldEventsSelected: 'calendar/isShowOnHoldEventsSelected',
      selectedOwnedByIdFilters: 'calendar/selectedOwnedByIdFilters',
    }),

    hiddenFiltersCount() {
      return (
        size(this.selectedFilters) +
        size(this.selectedOwnedByIdFilters) -
        this.canDisplayFilterCount
      )
    },

    canDisplayHiddenFiltersCount() {
      return this.hiddenFiltersCount > 0
    },

    canDisplaySelectedFiltersList() {
      return (
        size(this.selectedFilters) > 0 ||
        this.hasEventsSearchKeyWord ||
        size(this.selectedOwnedByIdFilters) > 0
      )
    },

    sortedDisplayedFilters() {
      const sortedFilters = orderBy(
        this.selectedFiltersWithOrder,
        'order',
        'asc'
      )

      return slice(sortedFilters, 0, this.canDisplayFilterCount)
    },

    selectedFiltersWithOrder() {
      let selectedFiltersWithOrder = []
      let settingsFiltersOrder = 0

      if (size(this.selectedFilters) > 0) {
        forEach(this.selectedFilters, (selectedFilter) => {
          // Check if the selected filter is any of the settings filter type
          const isSettingsFilter =
            selectedFilter.id === 'show-google-bamboo-events' ||
            selectedFilter.id === 'show-events-assigned-to-me' ||
            selectedFilter.id === 'show-events-i-own' ||
            selectedFilter.id === 'show-freelancer-events' ||
            selectedFilter.id === 'show-unassigned-events' ||
            selectedFilter.id === 'show-on-hold-events'

          const selectedFilterContent = {
            filter: selectedFilter,
          }

          // If the selected filter is any of the settings filter type, set the order
          if (isSettingsFilter) {
            settingsFiltersOrder += 1
            selectedFilterContent.order = settingsFiltersOrder
          }

          selectedFiltersWithOrder.push(selectedFilterContent)
        })
      }

      if (size(this.selectedOwnedByIdFilters) > 0) {
        forEach(this.selectedOwnedByIdFilters, (ownerFilter) => {
          const selectedFilterContent = {
            filter: ownerFilter,
          }

          selectedFiltersWithOrder.push(selectedFilterContent)
        })
      }

      return selectedFiltersWithOrder
    },
  },

  watch: {
    selectedFilters() {
      this.updateSelectedFiltersList()
      this.updateCalendarRouteQuery()
    },

    selectedOwnedByIdFilters() {
      this.updateSelectedFiltersList()
      this.updateCalendarRouteQuery()
    },
  },

  mounted() {
    this.updateSelectedFiltersList()
  },

  methods: {
    ...mapActions({
      removeSelectedFilter: 'calendar/removeSelectedFilter',
      clearAllFilters: 'calendar/clearAllFilters',
      setShowAllFiltersModal: 'calendar/setShowAllFiltersModal',
      getEventsList: 'calendar/getEventsList',
      setEventsSearchKeyword: 'calendar/setEventsSearchKeyword',
      setLocalStorageSelectedFilters: 'calendar/setLocalStorageSelectedFilters',
      removeSelectedOwnedByIdFilters: 'calendar/removeSelectedOwnedByIdFilters',
    }),

    updateSelectedFiltersList() {
      let displayFilterCount =
        this.canDisplayFilterCount === 0 ? 0 : this.canDisplayFilterCount

      if (
        size(this.selectedFilters) === 0 &&
        size(this.selectedOwnedByIdFilters) === 0
      ) {
        this.canDisplayFilterCount = 0
      } else {
        forEach(this.selectedFilters, () => {
          const canAddSelectedFilter =
            this.$refs.selectedFilters.offsetWidth <
            this.$refs.filterListContainer.offsetWidth * 0.5

          if (canAddSelectedFilter) {
            displayFilterCount += 1
          }
        })

        forEach(this.selectedOwnedByIdFilters, () => {
          const canAddSelectedFilter =
            this.$refs.selectedFilters.offsetWidth <
            this.$refs.filterListContainer.offsetWidth * 0.5

          if (canAddSelectedFilter) {
            displayFilterCount += 1
          }
        })

        const maxDisplayFilterCount = this.hasEventsSearchKeyWord ? 3 : 4

        this.canDisplayFilterCount =
          displayFilterCount > maxDisplayFilterCount
            ? maxDisplayFilterCount
            : displayFilterCount
      }
    },

    handleGetEventsList(showLoader) {
      this.getEventsList({
        payload: this.eventsListPayload,
        showLoader: showLoader,
      })
    },

    handleFilterClick(filter) {
      if (filter.is_project_owner) {
        this.removeSelectedOwnedByIdFilters(filter)
      } else {
        this.removeSelectedFilter(filter)
      }

      this.$nextTick(() => {
        this.handleGetEventsList()
      })

      this.updateSelectedFiltersList()
    },

    removeSearchKeyword() {
      this.setEventsSearchKeyword('')
      this.handleGetEventsList(true)
    },

    updateCalendarRouteQuery() {
      let queryParam = {}

      if (isEmpty(this.selectedAssignedToIdFilters)) {
        queryParam = omit(queryParam, ['users'])
      } else {
        queryParam['users'] = join(this.selectedAssignedToIdFilters, ',')
      }

      if (isEmpty(this.selectedOwnedByIdFilters)) {
        queryParam = omit(queryParam, ['owners'])
      } else {
        const ownerIds = map(this.selectedOwnedByIdFilters, 'id')
        queryParam['owners'] = join(ownerIds, ',')
      }

      if (isEmpty(this.selectedEventTypeIdFilters)) {
        queryParam = omit(queryParam, ['events'])
      } else {
        queryParam['events'] = join(this.selectedEventTypeIdFilters, ',')
      }

      if (!this.isShowGoogleBambooEventsSelected) {
        queryParam = omit(queryParam, ['show_external'])
      } else {
        queryParam['show_external'] = true
      }

      if (!this.isShowEventsAssignedToMeFilterSelected) {
        queryParam = omit(queryParam, ['assigned_to_me'])
      } else {
        queryParam['assigned_to_me'] = true
      }

      if (!this.isShowFreelancerFilterSelected) {
        queryParam = omit(queryParam, ['show_freelancers'])
      } else {
        queryParam['show_freelancers'] = true
      }

      if (!this.isShowUnassignedFilterSelected) {
        queryParam = omit(queryParam, ['unassigned'])
      } else {
        queryParam['unassigned'] = true
      }

      if (!this.isShowOnHoldEventsSelected) {
        queryParam = omit(queryParam, ['show_on_hold_events'])
      } else {
        queryParam['show_on_hold_events'] = true
      }

      this.setLocalStorageSelectedFilters(queryParam)

      this.$router.replace({ query: queryParam }).catch(() => {
        // do nothing
      })
    },
  },
}
</script>
