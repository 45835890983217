<template>
  <div class="vd-margin-bottom-medium">
    <div
      id="toggleFilterListDiv"
      class="vms-video__filters--list-label vd-h4 vd-margin-bottom-small vd-cursor-pointer"
      @click="toggleFilterListExpand"
    >
      <div class="row no-gutters">
        <div class="col-10">
          <div class="vd-h4">
            <strong>{{ filterLabel }}</strong>
          </div>
        </div>

        <div class="col-2 vd-text-align-right">
          <i
            :class="[
              'vd-icon vd-hover-darker-grey vd-text-small',
              isListExpanded ? 'icon-minus' : 'icon-plus',
            ]"
          ></i>
        </div>
      </div>
    </div>

    <div v-show="isListExpanded">
      <div
        v-for="(savedFilter, index) in filtersList"
        :key="`${index}-${savedFilter.id}`"
        class="vd-margin-bottom-medium"
      >
        <div>
          <div class="vd-display-flex vd-align-center vd-margin-bottom-small">
            <div class="row no-gutters">
              <div class="col-11">
                <div class="vd-display-flex vd-align-center">
                  <input
                    :id="`${index}-${savedFilter.id}`"
                    v-model="selectedFilterData"
                    type="radio"
                    class="vd-margin-right-small"
                    name="savedFilterInput"
                    :value="savedFilter.value"
                    @change="handleSavedFilterSelected(savedFilter)"
                  />
                  <label
                    :for="`${index}-${savedFilter.id}`"
                    class="vd-margin-0 vd-display-flex"
                  >
                    <span class="vd-margin-right-small">
                      {{ savedFilter.label | truncateString(30) }}
                    </span>
                  </label>
                </div>
              </div>

              <div
                class="col-1 vd-text-align-right"
                @click="handleDeleteSavedFilter(savedFilter)"
              >
                <text-popover
                  class="vd-display-inline-block vd-align-middle"
                  tooltip-icon="vd-icon icon-xmark vd-hover-red vd-darker-grey"
                  custom-tooltip-class="vd-text-small vd-cursor-pointer"
                  :tooltip-id="`removeSavedFilterTooltip${index}-${savedFilter.id}`"
                  tooltip-text="Delete saved filter"
                ></text-popover>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FiltersMixin from '@mixins/filters-mixin'
import {
  filter,
  includes,
  forEach,
  map,
  flatten,
  isEqual,
  sortBy,
  size,
} from 'lodash'
import TextPopover from '@components/tooltips/text-popover'
import SweetAlert from '@plugins/sweet-alert'

export default {
  components: {
    TextPopover,
  },

  mixins: [FiltersMixin],

  props: {
    filterLabel: {
      type: String,
      required: true,
    },

    filtersList: {
      type: Array,
      required: true,
    },

    expandList: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isListExpanded: true,
      hasFilterChanges: false,
      defaultSelectedSavedFilter: null,
      selectedFilterData: null,
    }
  },

  computed: {
    ...mapGetters({
      selectedSavedFilter: 'calendar/selectedSavedFilter',
      eventFilters: 'calendar/eventFilters',
      crewFilters: 'calendar/crewFilters',
      eventsListPayload: 'calendar/eventsListPayload',
      selectedFilters: 'calendar/selectedFilters',
      isShowEventsAssignedToMeFilterSelected:
        'calendar/isShowEventsAssignedToMeFilterSelected',
      isShowEventsIOwnFilterSelected: 'calendar/isShowEventsIOwnFilterSelected',
      isShowUnassignedFilterSelected: 'calendar/isShowUnassignedFilterSelected',
      isShowFreelancerFilterSelected: 'calendar/isShowFreelancerFilterSelected',
      isShowGoogleBambooEventsSelected:
        'calendar/isShowGoogleBambooEventsSelected',
      isShowOnHoldEventsSelected: 'calendar/isShowOnHoldEventsSelected',
      selectedAssignedToIdFilters: 'calendar/selectedAssignedToIdFilters',
      selectedEventTypeIdFilters: 'calendar/selectedEventTypeIdFilters',
      selectedOwnedByIdFiltersArray: 'calendar/selectedOwnedByIdFiltersArray',
      projectOwners: 'user/projectOwners',
    }),

    currentSelectedFilters() {
      return {
        assigned_to_ids: sortBy(this.selectedAssignedToIdFilters),
        owned_by_ids: sortBy(this.selectedOwnedByIdFiltersArray),
        assigned_to_me: this.isShowEventsAssignedToMeFilterSelected ? 1 : 0,
        event_type_ids: sortBy(this.selectedEventTypeIdFilters),
        i_own: this.isShowEventsIOwnFilterSelected ? 1 : 0,
        include_bamboo_events: this.isShowGoogleBambooEventsSelected ? 1 : 0,
        include_google_events: this.isShowGoogleBambooEventsSelected ? 1 : 0,
        unassigned: this.isShowUnassignedFilterSelected ? 1 : 0,
        include_freelancer_events: this.isShowFreelancerFilterSelected ? 1 : 0,
        include_on_hold_events: this.isShowOnHoldEventsSelected ? 1 : 0,
      }
    },
  },

  watch: {
    expandList() {
      this.isListExpanded = this.expandList
    },

    hasFilterChanges() {
      this.setSelectedSavedFilter(null)
      this.defaultSelectedSavedFilter = null
    },

    selectedFilters() {
      if (this.selectedSavedFilter) {
        const defaultSelectedFilters = this.getDefaultSelectedFilters(
          this.selectedSavedFilter.data
        )

        const hasChanges = !isEqual(
          defaultSelectedFilters,
          this.currentSelectedFilters
        )

        this.hasFilterChanges = hasChanges

        this.setSelectedSavedFilter(null)
        this.checkAndSelectSavedFilters()
      } else {
        if (size(this.selectedFilters) > 0) {
          this.checkAndSelectSavedFilters()
        } else {
          this.defaultSelectedSavedFilter = null
        }
      }

      this.updateCheckedSavedFilter()
    },

    currentSelectedFilters() {
      this.checkAndSelectSavedFilters()
    },

    defaultSelectedSavedFilter() {
      this.updateCheckedSavedFilter()
    },
  },

  methods: {
    ...mapActions({
      addSelectedFilter: 'calendar/addSelectedFilter',
      getEventsList: 'calendar/getEventsList',
      setSelectedSavedFilter: 'calendar/setSelectedSavedFilter',
      clearAllFilters: 'calendar/clearAllFilters',
      addOrRemoveSelectedFilters: 'calendar/addOrRemoveSelectedFilters',
      deleteSavedFilter: 'calendar/deleteSavedFilter',
      addSelectedOwnedByIdFilters: 'calendar/addSelectedOwnedByIdFilters',
    }),

    toggleFilterListExpand() {
      this.isListExpanded = !this.isListExpanded
    },

    updateCheckedSavedFilter() {
      const selectedSavedFilter = filter(this.filtersList, (filterList) => {
        return this.defaultSelectedSavedFilter?.id === filterList.id
      })

      const selectedFilterData =
        size(selectedSavedFilter) > 0 ? selectedSavedFilter[0] : null

      this.selectedFilterData = selectedFilterData
        ? selectedFilterData.value
        : null

      this.setSelectedSavedFilter(selectedFilterData)
    },

    handleGetEventsList() {
      this.getEventsList({
        payload: this.eventsListPayload,
        showLoader: true,
      })
    },

    updateSavedFilter(savedFilterData, filterResource) {
      const selectedFilters = filter(filterResource, (resource) => {
        return (
          includes(savedFilterData, resource.id) ||
          savedFilterData === resource.id
        )
      })

      this.addOrRemoveSelectedFilters(selectedFilters)
    },

    updateOwnerFilter(saveFilterData) {
      const matchedData = filter(this.projectOwners, (owner) =>
        includes(saveFilterData, owner.id)
      )

      forEach(matchedData, (data) => {
        const ownedByIdObject = {
          label: data.full_name,
          id: data.id,
          value: data.id,
          is_project_owner: true,
        }

        this.addSelectedOwnedByIdFilters(ownedByIdObject)
      })
    },

    // eslint-disable-next-line complexity
    handleSavedFilterSelected(savedFilter) {
      this.clearAllFilters()
      this.setSelectedSavedFilter(savedFilter)

      if (savedFilter.data.assigned_to_ids) {
        this.updateSavedFilter(
          savedFilter.data.assigned_to_ids,
          this.crewFilters
        )
      }

      if (savedFilter.data.owned_by_ids) {
        this.updateOwnerFilter(savedFilter.data.owned_by_ids)
      }

      if (savedFilter.data.event_type_ids) {
        forEach(savedFilter.data.event_type_ids, (eventTypeId) => {
          this.updateSavedFilter(
            eventTypeId,
            flatten(map(this.eventFilters, 'subFilters'), true)
          )
        })
      }

      if (savedFilter.data.unassigned) {
        this.addSelectedFilter({
          id: 'show-unassigned-events',
          label: 'Show unassigned events',
          value: 'show-unassigned-events',
        })
      }

      if (savedFilter.data.include_freelancer_events) {
        this.addSelectedFilter({
          id: 'show-freelancer-events',
          label: 'Show freelancers',
          value: 'show-freelancer-events',
        })
      }

      if (savedFilter.data.assigned_to_me) {
        this.addSelectedFilter({
          id: 'show-events-assigned-to-me',
          label: 'Show events assigned to me',
          value: 'show-events-assigned-to-me',
        })
      }

      if (savedFilter.data.i_own) {
        this.addSelectedFilter({
          id: 'show-events-i-own',
          label: 'Show events I own',
          value: 'show-events-i-own',
        })
      }

      if (
        savedFilter.data.include_google_events ||
        savedFilter.data.include_bamboo_events
      ) {
        this.addSelectedFilter({
          id: 'show-google-bamboo-events',
          label: 'Show Google/Bamboo events',
          value: 'show-google-bamboo-events',
        })
      }

      if (savedFilter.data.include_on_hold_events) {
        this.addSelectedFilter({
          id: 'show-on-hold-events',
          label: 'Show on hold events',
          value: 'show-on-hold-events',
        })
      }

      this.handleGetEventsList()
    },

    getDefaultSelectedFilters(savedFilterData) {
      return {
        assigned_to_ids: sortBy(savedFilterData.assigned_to_ids),
        owned_by_ids: sortBy(savedFilterData.owned_by_ids),
        assigned_to_me: savedFilterData.assigned_to_me,
        event_type_ids: sortBy(savedFilterData.event_type_ids),
        i_own: savedFilterData.i_own,
        include_bamboo_events: savedFilterData.include_bamboo_events ? 1 : 0,
        include_google_events: savedFilterData.include_google_events ? 1 : 0,
        unassigned: savedFilterData.unassigned,
        include_freelancer_events: savedFilterData.include_freelancer_events
          ? 1
          : 0,
        include_on_hold_events: savedFilterData.include_on_hold_events ? 1 : 0,
      }
    },

    checkAndSelectSavedFilters() {
      const selectedSavedFilter = filter(this.filtersList, (savedFilter) => {
        const isDefaultFiltersSelected = isEqual(
          this.getDefaultSelectedFilters(savedFilter.data),
          this.currentSelectedFilters
        )

        return isDefaultFiltersSelected
      })

      this.defaultSelectedSavedFilter =
        size(selectedSavedFilter) > 0 ? selectedSavedFilter[0] : null
    },

    handleDeleteSavedFilter(savedFilter) {
      const alertContent = `
        <div class="vd-margin-top-small">Are you sure you want to delete </div>
        <div class="vd-margin-top-small vd-margin-bottom-small"><strong>${savedFilter.label}</strong>?</div>
      `

      SweetAlert.fire({
        title: 'Delete Saved Filter',
        html: alertContent,
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Delete saved filter',
        confirmButtonColor: '#ff3333',
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteSavedFilter(savedFilter.id)
        }
      })
    },
  },
}
</script>
