<template>
  <table class="vms-event-modal__table table table-sm">
    <thead>
      <tr>
        <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey">
          Project ID
        </th>
        <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey">
          Event name
        </th>
        <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey">
          Event type
        </th>
        <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey">
          Event date
        </th>
        <th scope="col" class="vd-h4 vd-darker-grey vd-border-light-grey">
          Start time
        </th>
        <th
          scope="col"
          class="vd-h4 vd-darker-grey vd-border-light-grey text-center"
        >
          Duration
        </th>
        <th
          scope="col"
          class="vd-h4 vd-darker-grey vd-border-light-grey text-center"
        ></th>
      </tr>
    </thead>

    <tbody>
      <events-table-row
        v-for="(calendarEvent, index) in calendarEvents"
        :key="index"
        :event="calendarEvent"
      >
        <template slot="action">
          <slot name="action" :event="calendarEvent"></slot>
        </template>
      </events-table-row>
    </tbody>
  </table>
</template>

<script>
import EventsTableRow from '@components/modal/events/components/events-table-content/events-table-row'

export default {
  components: {
    EventsTableRow,
  },

  props: {
    calendarEvents: {
      type: Array,
      required: true,
    },
  },
}
</script>
