<template>
  <div>
    <ejs-schedule
      id="VmsCalendar"
      ref="vmsScheduler"
      height="calc(100vh - 220px)"
      :current-view="selectedViewType"
      :time-scale="timeScale"
      :selected-date="selectedCalendarDate"
      :event-settings="eventSettings"
      :work-hours="workHours"
      :event-rendered="onEventRendered"
      :allow-multi-drag="true"
      :drag-stop="handleDragStop"
      :timezone="defaultCalendarTimezone"
      :enable-persistence="true"
      :allow-multi-row-selection="true"
      :quick-info-templates="quickInfoTemplates"
      :date-header-template="dateHeaderTemplate"
      :resource-header-template="timelineResourceHeaderTemplate"
      :agenda-days-count="365"
      @resizeStop="handleResizeStop"
      @navigating="handleSchedulerNavigation"
      @select="handleSelect"
      @eventDoubleClick="handleEventDoubleClick"
      @cellDoubleClick="cancelEvent"
      @cellClick="cancelEvent"
      @click.native.right.prevent="handleEventRightClicked"
      @popupOpen="handlePopupOpen"
    >
      <template v-slot:popupHeaderTemplate="{ data }">
        <events-popover-header
          :style="getPopoverBodyStyle(data)"
          :event-data="data"
          :selected-event="selectedEvent"
          :is-on-hold="isEventOnHold(data.event_status_id)"
          :event-name="eventName(data)"
          @click:edit="openEditModal"
          @click:delete="handleDeleteEvent"
        >
        </events-popover-header>
      </template>
      <template v-slot:popupContentTemplate="{ data }">
        <events-popover-content
          :event-data="data"
          :selected-event="selectedEvent"
          :is-event-details-loading="isEventDetailsLoading"
          :get-event-details="getEventDetails"
          :is-placeholder-event-type="isPlaceholderEventType(data)"
          :is-external-event="isExternalEvent(data.event_type_id)"
        ></events-popover-content>
      </template>

      <template v-slot:dateHeaderAgendaTemplate="{ data }">
        <calendar-agenda-header-template
          :event-data="data"
        ></calendar-agenda-header-template>
      </template>

      <template v-slot:dateHeaderTimelineTemplate="{ data }">
        <events-timeline-header
          :event-data="data"
          :holidays="getDateHolidays(data)"
        ></events-timeline-header>
      </template>

      <template v-slot:timelineResourceHeaderTemplate="{ data }">
        <events-timeline-resource-header
          :event-data="data"
        ></events-timeline-resource-header>
      </template>

      <e-resources v-if="isTimelineView">
        <e-resource
          :data-source="teamsResourceGroup"
          field="TeamGroupId"
          title="Teams"
          name="teams"
          text-field="teamName"
          id-field="teamId"
        ></e-resource>
        <e-resource
          :data-source="usersResourceGroup"
          field="UserGroupId"
          title="Users"
          name="users"
          text-field="userName"
          id-field="userId"
          group-i-d-field="teamId"
        >
        </e-resource>
      </e-resources>

      <e-views>
        <e-view option="Day" :event-template="'dayViewTemplate'">
          <template v-slot:dayViewTemplate="{ data }">
            <calendar-scheduler-day-view
              :event-data="data"
              :is-google-calendar-event-type="
                isGoogleCalendarEventType(data.event_type_id)
              "
              :is-bamboo-hr-event-type="isBambooHrEventType(data.event_type_id)"
              :is-external-event="isExternalEvent(data.event_type_id)"
              :is-placeholder-event-type="isPlaceholderEventType(data)"
              :event-name="eventName(data)"
              :is-event-on-hold="isEventOnHold(data.event_status_id)"
            ></calendar-scheduler-day-view>
          </template>
        </e-view>

        <e-view option="Week" :event-template="'weekViewTemplate'">
          <template v-slot:weekViewTemplate="{ data }">
            <calendar-scheduler-week-view
              :event-data="data"
              :is-google-calendar-event-type="
                isGoogleCalendarEventType(data.event_type_id)
              "
              :is-bamboo-hr-event-type="isBambooHrEventType(data.event_type_id)"
              :is-external-event="isExternalEvent(data.event_type_id)"
              :is-placeholder-event-type="isPlaceholderEventType(data)"
              :event-name="eventName(data)"
              :is-event-on-hold="isEventOnHold(data.event_status_id)"
            ></calendar-scheduler-week-view>
          </template>
        </e-view>

        <e-view option="Month" :event-template="'monthViewTemplate'">
          <template v-slot:monthViewTemplate="{ data }">
            <calendar-scheduler-month-view
              :event-data="data"
              :is-google-calendar-event-type="
                isGoogleCalendarEventType(data.event_type_id)
              "
              :is-bamboo-hr-event-type="isBambooHrEventType(data.event_type_id)"
              :is-external-event="isExternalEvent(data.event_type_id)"
              :is-placeholder-event-type="isPlaceholderEventType(data)"
              :event-name="eventName(data)"
              :is-event-on-hold="isEventOnHold(data.event_status_id)"
            ></calendar-scheduler-month-view>
          </template>
        </e-view>

        <e-view
          option="TimelineMonth"
          display-name="Timeline"
          :group="resourceGroup"
          :event-template="'timelineMonthViewTemplate'"
        >
          <template v-slot:timelineMonthViewTemplate="{ data }">
            <events-timeline-view
              :event-data="data"
              :is-google-calendar-event-type="
                isGoogleCalendarEventType(data.event_type_id)
              "
              :is-bamboo-hr-event-type="isBambooHrEventType(data.event_type_id)"
              :is-external-event="isExternalEvent(data.event_type_id)"
              :is-placeholder-event-type="isPlaceholderEventType(data)"
              :event-name="eventName(data)"
              :is-event-on-hold="isEventOnHold(data.event_status_id)"
            ></events-timeline-view>
          </template>
        </e-view>

        <e-view
          v-if="hasEventsSearchKeyWord"
          option="Agenda"
          :allow-virtual-scrolling="true"
          display-name="All Search Results"
          :event-template="'agendaViewTemplate'"
        >
          <template v-slot:agendaViewTemplate="{ data }">
            <calendar-scheduler-agenda-view
              :event-data="data"
              :is-google-calendar-event-type="
                isGoogleCalendarEventType(data.event_type_id)
              "
              :is-bamboo-hr-event-type="isBambooHrEventType(data.event_type_id)"
              :is-external-event="isExternalEvent(data.event_type_id)"
              :is-placeholder-event-type="isPlaceholderEventType(data)"
              :is-mobile-screen="isMobileScreen"
              :is-event-on-hold="isEventOnHold(data.event_status_id)"
            ></calendar-scheduler-agenda-view>
          </template>
        </e-view>
      </e-views>
    </ejs-schedule>

    <events-context-menu
      ref="calendarContextMenu"
      @handle:context-menu-close="clearSelectedCells"
    ></events-context-menu>

    <events-context-menu
      ref="calendarBulkActionContextMenu"
      :menu-items="calendarBulkMenuItems"
      :before-open="onBulkContextMenuBeforeOpen"
      @handle:context-menu-close="clearSelectedEvents"
      @handle:content-menu-select="handleBulkActionContextMenuSelect"
    ></events-context-menu>

    <events-context-menu
      ref="calendarActionContextMenu"
      :menu-items="calendarMenuItems"
      :before-open="onContextMenuBeforeOpen"
      @handle:context-menu-close="clearSelectedEvents"
      @handle:content-menu-select="handleActionContextMenuSelect"
    ></events-context-menu>
  </div>
</template>

<script>
import '@plugins/syncfusion'
import { mapGetters, mapActions } from 'vuex'
import {
  Day,
  Week,
  Month,
  TimelineMonth,
  Resize,
  DragAndDrop,
  ViewsDirective,
  ViewDirective,
  Agenda,
  ResourceDirective,
  ResourcesDirective,
} from '@syncfusion/ej2-vue-schedule'
import { forEach, size, find, orderBy, uniqWith, some } from 'lodash'
import momentTz from 'moment-timezone'
import CalendarSchedulerDayView from '@views/calendar/calendar-main/calendar-scheduler/calendar-scheduler-day-view'
import CalendarSchedulerWeekView from '@views/calendar/calendar-main/calendar-scheduler/calendar-scheduler-week-view'
import CalendarSchedulerMonthView from '@views/calendar/calendar-main/calendar-scheduler/calendar-scheduler-month-view'
import EventsPopoverHeader from '@components/events/events-popover/events-popover-header'
import EventsPopoverContent from '@components/events/events-popover/events-popover-content'
import EventsTimelineView from '@components/events/events-timeline/events-timeline-view'
import EventsTimelineHeader from '@components/events/events-timeline/events-timeline-header'
import EventsTimelineResourceHeader from '@components/events/events-timeline/events-timeline-resource-header'
import FiltersMixin from '@mixins/filters-mixin'
import EventsContextMenu from '@components/events/events-context-menu'
import CalendarSchedulerAgendaView from '@views/calendar/calendar-main/calendar-scheduler/calendar-scheduler-agenda-view'
import CalendarAgendaHeaderTemplate from '@views/calendar/calendar-main/calendar-scheduler/calendar-agenda-header-template'
import EventUserGroups from '@configs/event-user-groups'
import EventSchedulerMixin from '@mixins/event-scheduler-mixin'

export default {
  components: {
    'e-views': ViewsDirective,
    'e-view': ViewDirective,
    'e-resource': ResourceDirective,
    'e-resources': ResourcesDirective,
    CalendarSchedulerDayView,
    CalendarSchedulerWeekView,
    CalendarSchedulerMonthView,
    EventsPopoverHeader,
    EventsPopoverContent,
    EventsContextMenu,
    CalendarSchedulerAgendaView,
    CalendarAgendaHeaderTemplate,
    EventsTimelineView,
    EventsTimelineHeader,
    EventsTimelineResourceHeader,
  },

  // Placing EventSchedulerMixin first so we can use mixins[0] to spy on it's method
  mixins: [EventSchedulerMixin, FiltersMixin],

  provide: {
    schedule: [Day, Week, Month, TimelineMonth, Resize, DragAndDrop, Agenda],
  },

  data() {
    return {
      quickInfoTemplates: {
        header: 'popupHeaderTemplate',
        content: 'popupContentTemplate',
      },
      workHours: {
        start: '09:00',
        end: '17:30',
      },
      calendarBulkMenuItems: [
        {
          text: 'Bulk edit',
          id: 'bulk-edit',
        },
        {
          text: 'Bulk duplicate',
          id: 'bulk-duplicate',
        },
        {
          text: 'Bulk delete',
          id: 'bulk-delete',
        },
      ],

      calendarMenuItems: [
        {
          text: 'Delete',
          id: 'delete',
        },
        {
          text: 'Duplicate',
          id: 'duplicate',
        },
      ],
      selectedEventsArray: [],
    }
  },

  computed: {
    ...mapGetters({
      eventsList: 'calendar/eventsList',
      selectedViewType: 'calendar/selectedViewType',
      schedulerSavedState: 'calendar/schedulerSavedState',
      isEventDetailsLoading: 'calendar/isEventDetailsLoading',
      selectedEvent: 'calendar/selectedEvent',
      eventsListPayload: 'calendar/eventsListPayload',
      selectedCalendarDate: 'calendar/selectedCalendarDate',
      hasEventsSearchKeyWord: 'calendar/hasEventsSearchKeyWord',
      eventsDataDateRange: 'calendar/eventsDataDateRange',
      canShowCalendarProgressBar: 'calendar/canShowCalendarProgressBar',
      internalUsersList: 'user/internalUsersList',
      selectedAssignedToIdFilters: 'calendar/selectedAssignedToIdFilters',
      isShowUnassignedFilterSelected: 'calendar/isShowUnassignedFilterSelected',
      unassignedTeamGroup: 'calendar/unassignedTeamGroup',
      defaultCalendarTimezone: 'common/defaultCalendarTimezone',
      showEventsBulkEditModal: 'calendar/showEventsBulkEditModal',
      isShowFreelancerFilterSelected: 'calendar/isShowFreelancerFilterSelected',
      isMobileScreen: 'common/isMobileScreen',
      searchEventsFilterPayload: 'calendar/searchEventsFilterPayload',
    }),

    timeScale() {
      const selectedViewType = this.selectedViewType

      return {
        enable: true,
        interval: 60,
        slotCount:
          selectedViewType === 'Day' || selectedViewType === 'Week' ? 4 : 2,
      }
    },

    eventSettings() {
      return {
        dataSource: this.getEventSettingsDataSource(),
      }
    },

    isAgendaView() {
      return this.selectedViewType === 'Agenda'
    },

    dateHeaderTemplate() {
      let dateHeaderTemplate = ''

      if (this.isTimelineView) {
        dateHeaderTemplate = 'dateHeaderTimelineTemplate'
      }

      if (this.isAgendaView) {
        dateHeaderTemplate = 'dateHeaderAgendaTemplate'
      }

      return dateHeaderTemplate
    },

    timelineResourceHeaderTemplate() {
      return this.isTimelineView ? 'timelineResourceHeaderTemplate' : ''
    },

    usersResourceGroup() {
      if (!this.isTimelineView) {
        return []
      }

      let userGroup = []

      if (this.isShowUnassignedFilterSelected) {
        userGroup.push(this.unassignedTeamGroup)
      }

      if (size(this.selectedAssignedToIdFilters) > 0) {
        forEach(this.selectedAssignedToIdFilters, (userId) => {
          const userData = find(this.internalUsersList, { id: userId })

          if (userData) {
            if (size(userData.teams) === 0) {
              userGroup.push(this.getUserTeamGroup(userData, null))
            } else {
              forEach(userData.teams, (team) => {
                userGroup.push(this.getUserTeamGroup(userData, team))
              })
            }
          }
        })
      } else {
        userGroup = this.getEventsUserGroup()
      }

      if (this.isShowFreelancerFilterSelected) {
        const freelanceGroup = this.getEventsFreelancerGroup()
        if (size(freelanceGroup) > 0) {
          forEach(freelanceGroup, (item) => {
            userGroup.push(item)
          })
        }
      }

      return userGroup
    },

    teamsResourceGroup() {
      if (!this.isTimelineView) {
        return []
      }

      let teams = []

      forEach(this.usersResourceGroup, (userGroupItem) => {
        if (!find(teams, { teamId: userGroupItem.teamId })) {
          teams.push({
            teamId: userGroupItem.teamId,
            teamName: userGroupItem.teamName,
          })
        }
      })
      return orderBy(teams, ['teamName'])
    },

    resourceGroup() {
      const hasNoneHolidayEvent = some(this.eventsList, (event) => {
        return !this.isPublicHolidayEvent(event.event_type_id)
      })

      if (this.isTimelineView && hasNoneHolidayEvent) {
        return {
          resources: ['teams', 'users'],
        }
      }

      return []
    },
  },

  watch: {
    // update scheduler source to refresh it when data source changed
    eventSettings() {
      this.updateEventSettingsDataSource()

      // remove custom background for holidays when there's a search keyword
      if (this.hasEventsSearchKeyWord) {
        document
          .querySelectorAll('.e-date--holiday')
          .forEach((element) => element.classList.remove('e-date--holiday'))
      }
    },

    hasEventsSearchKeyWord() {
      if (!this.hasEventsSearchKeyWord && this.selectedViewType === 'Agenda') {
        // Syncfusion sets the current view to Day by default when the last view selected does not exist
        this.setViewType('Day')
      }
    },

    showEventsBulkEditModal() {
      if (!this.showEventsBulkEditModal) {
        this.selectedEventsArray = []
      }
    },
  },

  beforeMount() {
    // Sync selectedView state with scheduler's saved view because schedulerSavedState state is not reactive.
    if (this.selectedViewType === 'Agenda') {
      // Previously selected type is Agenda and page was reloaded. Set the view type to Day since Syncfusion defaults to Day when last view selected does not exist.
      if (!this.hasEventsSearchKeyWord) {
        this.setViewType('Day')
      }
    } else {
      if (this.schedulerSavedState) {
        // Only set the scheduler view type if the previous saved state is Agenda. Since schedulerSavedState is not reactive, there may be cases where the old localStorage data is being used.
        if (this.schedulerSavedState.currentView === 'Agenda') {
          this.setViewType('Day')
        } else {
          this.setViewType(this.schedulerSavedState.currentView)
        }
      }
    }
  },

  methods: {
    ...mapActions({
      getEventDetails: 'calendar/getEventDetails',
      getEventsList: 'calendar/getEventsList',
      setViewType: 'calendar/setViewType',
      setSelectedCalendarDate: 'calendar/setSelectedCalendarDate',
    }),

    updateEventSettingsDataSource() {
      // update the scheduler component dataSource
      this.$refs.vmsScheduler.ej2Instances.eventSettings.dataSource = this.getEventSettingsDataSource()
    },

    handleSchedulerNavigation(args) {
      if (args.action === 'view') {
        // Store the selected current view
        this.setViewType(args.currentView)
      }

      if (args.action === 'date') {
        this.setSelectedCalendarDate(args.currentDate)
      }

      const { fromDate, toDate } = this.eventsDataDateRange
      const { start, end } = this.eventsListPayload

      let eventListPayload = this.eventsListPayload

      // On all search result, by default it will override existing filters that is applied.
      // This is on purpose. It is to show all events related to the search keyword.
      if (this.isAgendaView) {
        eventListPayload.filters = this.searchEventsFilterPayload
      }

      // Check if we need to fetch new events list by comparing selected date to the stored date range
      if (
        !momentTz(start).isBetween(fromDate, toDate, undefined, '[]') ||
        !momentTz(end).isBetween(fromDate, toDate, undefined, '[]') ||
        this.canShowCalendarProgressBar ||
        (this.hasEventsSearchKeyWord && !this.isAgendaView)
      ) {
        this.getEventsList({
          payload: eventListPayload,
          showLoader: true,
        })
      }
    },

    getUserTeamGroup(eventUser, teamData) {
      return {
        userId: eventUser.id,
        userName: eventUser.full_name,
        data: {
          full_name: eventUser.full_name,
          calendar_color: eventUser.calendar_color,
          photo_url: eventUser.photo_url,
        },
        teamId: teamData ? teamData.id : EventUserGroups.NO_TEAM,
        teamName: teamData ? teamData.name : 'No assigned team',
      }
    },

    getEventsUserGroup() {
      // Build a group of user with teamId based on events list.
      const userTeamGroup = []

      forEach(this.eventSettings.dataSource, (eventData) => {
        // add a user group if event is not a public holiday event
        if (!eventData.IsHoliday) {
          const eventUser = eventData.user
          if (eventUser) {
            if (size(eventUser.teams) === 0) {
              userTeamGroup.push(this.getUserTeamGroup(eventUser, null))
            } else {
              forEach(eventUser.teams, (team) => {
                userTeamGroup.push(this.getUserTeamGroup(eventUser, team))
              })
            }
          } else {
            userTeamGroup.push(this.unassignedTeamGroup)
          }
        }
      })

      // return array of unique group of id and teamId
      return orderBy(
        uniqWith(
          userTeamGroup,
          (firstUserResourceGroup, secondUserResourceGroup) => {
            return (
              firstUserResourceGroup.userId ===
                secondUserResourceGroup.userId &&
              firstUserResourceGroup.teamId === secondUserResourceGroup.teamId
            )
          }
        ),
        'userName'
      )
    },

    getEventsFreelancerGroup() {
      // Build a group of freelancers with teamId based on events list.
      const freelancerTeamGroup = []

      forEach(this.eventsList, (eventData) => {
        const freelancer = eventData.meta_data?.freelancer

        if (freelancer) {
          freelancerTeamGroup.push(this.getFreelancerGroup(freelancer))
        }
      })

      // return array of unique group of id and teamId
      return orderBy(
        uniqWith(
          freelancerTeamGroup,
          (firstUserResourceGroup, secondUserResourceGroup) => {
            return (
              firstUserResourceGroup.userId ===
                secondUserResourceGroup.userId &&
              firstUserResourceGroup.teamId === secondUserResourceGroup.teamId
            )
          }
        ),
        'userName'
      )
    },

    getUserTeamGroupIds(eventData, dataSourceItem) {
      // return array for different teams the user have
      if (eventData.user && size(eventData.user.teams)) {
        let dataSource = []
        forEach(eventData.user.teams, (team) => {
          dataSource.push({
            ...dataSourceItem,
            UserGroupId: eventData.user.id,
            TeamGroupId: team.id,
          })
        })
        return dataSource
      }

      // return user with no team
      if (eventData.user) {
        return [
          {
            ...dataSourceItem,
            UserGroupId: eventData.user.id,
            TeamGroupId: EventUserGroups.NO_TEAM,
          },
        ]
      }

      // return freelancer when available
      if (eventData.meta_data?.freelancer) {
        return [
          {
            ...dataSourceItem,
            UserGroupId: eventData.meta_data.freelancer,
            TeamGroupId: EventUserGroups.FREELANCER,
          },
        ]
      }

      // return unassigned
      return [
        {
          ...dataSourceItem,
          UserGroupId: EventUserGroups.UNASSIGNED,
          TeamGroupId: EventUserGroups.UNASSIGNED,
        },
      ]
    },
  },
}
</script>

<style>
@import '~@syncfusion/ej2-base/styles/material.css';
@import '~@syncfusion/ej2-buttons/styles/material.css';
@import '~@syncfusion/ej2-calendars/styles/material.css';

@import '~@syncfusion/ej2-vue-dropdowns/styles/material.css';
@import '~@syncfusion/ej2-vue-inputs/styles/material.css';
@import '~@syncfusion/ej2-vue-navigations/styles/material.css';
@import '~@syncfusion/ej2-vue-popups/styles/material.css';
@import '~@syncfusion/ej2-vue-schedule/styles/material.css';
</style>
